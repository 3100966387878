import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppTypography, AppButton, StyledLink, TextTimer } from '@horse-auction/common/components';
import { AppTheme } from '@horse-auction/common/themes/customTheme';
import { device } from '@horse-auction/common/themes/device';
import PurchaseDto from '@horse-auction/common/types/purchase.dto';
import { DialogContent, DialogTitle, Dialog, IconButton, List, ListItem } from '@mui/material';
import { MdClear, MdPayment } from 'react-icons/md';
import styled from 'styled-components/macro';

import { PurchasesContext } from '../../context/PurchasesContext';

interface CustomProps {
  purchaseObligations: PurchaseDto[];
  open: boolean;
  isLoading: boolean;
  onCancel: () => void;
}
interface ThemeProps {
  theme: AppTheme;
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
  .MuiDialogContent-root {
    margin: 0;
    padding: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-transform: uppercase;

  &.MuiDialogTitle-root {
    display: flex;
    align-items: center;
    padding: 0 3rem 0 0;

    .MuiTypography-root {
      overflow-wrap: anywhere;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }: ThemeProps) => theme.colors.body};

    &:last-child {
      border-bottom-width: 0;
    }

    @media ${device.tablet} {
      flex-direction: column;
    }

    .MuiTypography-root {
      font-weight: 600;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const PayButtonWrapper = styled.div`
  padding-top: 0;
  display: flex;

  @media ${device.tablet} {
    padding-top: 1rem;
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const PurchasesWonDialog = ({
  purchaseObligations = [],
  open,
  isLoading,
  onCancel,
}: CustomProps) => {
  const { t } = useTranslation(['bid']);

  const { openPaymentDialog } = useContext(PurchasesContext);

  const openLotNewTabHandler = (purchase: PurchaseDto) => {
    window.open(`/auctions/${purchase?.auction?._id}/lots/${purchase?.lot?._id}`);
  };

  const goToPaymentHandler = (purchase: PurchaseDto) => {
    openPaymentDialog(purchase);
    onCancel();
  };

  return (
    <StyledDialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <StyledDialogTitle id='alert-dialog-title'>
        <AppTypography variant='h5' lineHeight='2rem'>
          {t<string>('components:lotOutcome.title')}
        </AppTypography>
      </StyledDialogTitle>
      {onCancel ? (
        <StyledIconButton aria-label='close' onClick={onCancel}>
          <MdClear />
        </StyledIconButton>
      ) : null}
      <DialogContent>
        <AppTypography margin='0.5rem 0'>
          {t<string>('components:lotOutcome.congratulationsYouWonLots')}
        </AppTypography>

        <List aria-label='lots'>
          {purchaseObligations?.map((purchase) => (
            <StyledListItem key={purchase?._id}>
              <StyledLink onClick={() => openLotNewTabHandler(purchase)}>
                <AppTypography variant='body1'>{`${purchase?.lot?.auctionOrder + 1}. ${
                  purchase?.lot?.product?.name
                }`}</AppTypography>
              </StyledLink>
              {purchase?.status === 'CONFIRMATION_REQUIRED' && (
                <PayButtonWrapper>
                  {purchase?.confirmationDeadline && (
                    <TextTimer date={purchase?.confirmationDeadline} />
                  )}
                  <AppButton
                    onClick={() => goToPaymentHandler(purchase)}
                    size='large'
                    color='primary'
                    variant='outlined'
                    startIcon={<MdPayment />}
                  >
                    {t<string>('purchases:common.goToPaymentButton')}
                  </AppButton>
                </PayButtonWrapper>
              )}
            </StyledListItem>
          ))}
        </List>
        <AppTypography margin='0.5rem 0'>
          {t<string>('components:lotOutcome.weWillContact')}
        </AppTypography>
        <AppTypography margin='0.5rem 0'>
          {t<string>('components:lotOutcome.regards')}
        </AppTypography>
        <AppTypography margin='0.5rem 0'>
          {t<string>('components:lotOutcome.equibidTeam')}
        </AppTypography>
        <ButtonRow>
          <AppButton color='primary' fullWidth onClick={onCancel} isLoading={isLoading}>
            {t<string>('components:lotOutcome.okButton')}
          </AppButton>
        </ButtonRow>
      </DialogContent>
    </StyledDialog>
  );
};

export default PurchasesWonDialog;
