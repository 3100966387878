import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import { MdLiveTv } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  auctionId: string;
  lotId: string;
}

const AlertWrapper = styled.div`
  margin: 0 0 1rem 0;
`;

const GoToLiveAlert = ({ auctionId, lotId }: CustomProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const pathname = history?.location?.pathname;
    const search = history?.location?.search;
    setVisible(pathname !== `/auctions/${auctionId}/lots/${lotId}` || search !== `?mode=stream`);
  }, [auctionId, lotId, history?.location?.pathname, history?.location?.search]);

  if (!visible) {
    return null;
  }

  return (
    <AlertWrapper>
      <AppButton
        to={`/auctions/${auctionId}/lots/${lotId}?mode=stream`}
        size='small'
        color='error'
        variant='outlined'
        fullWidth
        startIcon={<MdLiveTv />}
      >
        {t<string>('auction:auctionAlerts.goToLiveLabel')}
      </AppButton>
    </AlertWrapper>
  );
};

export default GoToLiveAlert;
