import { useEffect, useState } from 'react';

import { AppTypography, LotChip } from '@horse-auction/common/components';
import LotDto from '@horse-auction/common/types/lot.dto';
import styled from 'styled-components/macro';

interface CustomProps {
  lot: LotDto;
  lotsQuantity: number;
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const LotHeader: React.FC<CustomProps> = ({ lot, lotsQuantity }: CustomProps) => {
  const [lotOrder, setLotOrder] = useState('0/0');

  useEffect(() => {
    const lotOrderString = `${(lot?.auctionOrder || 0) + 1}/${lotsQuantity}`;
    setLotOrder(lotOrderString);
  }, [lot, lotsQuantity]);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TitleWrapper>
          <LotChip status={lot?.status} size='small' />
          <AppTypography variant='h6' margin='0 0.5rem' lineHeight='32px'>
            {lotOrder}
          </AppTypography>
          <AppTypography variant='h5' lineHeight='32px'>
            {lot?.product?.name}
          </AppTypography>
        </TitleWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default LotHeader;
