import React, { useCallback } from 'react';

import { Link, useLocation, useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import Logo from '@horse-auction/common/components/Logo/Logo';
import { variants } from '@horse-auction/common/themes/customTheme';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

import NewsletterSignupLink from './NewsletterSignupLink';

const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.body};
  padding: 0 24px;
`;

const Wrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: auto;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const LinksContainer = styled.div`
  margin-left: 3rem;
`;

const LinkItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  width: 100%;
  font-weight: 500;
  flex-wrap: wrap;
`;

export const LinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 14px;
  margin: 0 1rem;
  min-width: 120px;

  &:hover {
    color: ${variants.primary.main};
    transition: 0.3s ease-out;
  }

  @media ${device.tablet} {
    margin: 1rem 0 0 0;
  }
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const WebsiteRights = styled.small`
  color: ${({ theme }) => theme.colors.text};
  @media ${device.mobile} {
    margin-top: 1rem;
    text-align: center;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2rem;
`;

const FooterLarge = () => {
  const location = useLocation();
  const history = useHistory();

  const toggleHomeHandler = useCallback(() => {
    if (location?.pathname === '/') {
      return scroll.scrollToTop();
    }
    return history.push('/');
  }, [history, location]);

  return (
    <Container>
      <Wrapper>
        <SocialMediaWrap>
          <Logo positioning='horizontal' onClick={toggleHomeHandler} />
          <WebsiteRights>Equibid © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
        </SocialMediaWrap>
        <LinksContainer>
          <LinkItems>
            <FooterLink to='/terms'>Terms of Service</FooterLink>
            <FooterLink to='/contact'>Contact</FooterLink>
            <NewsletterSignupLink />
          </LinkItems>
        </LinksContainer>
      </Wrapper>
    </Container>
  );
};

export default FooterLarge;
