import { useTranslation } from 'react-i18next';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import EventTimeRow from '@horse-auction/common/components/EventTimeRow/EventTimeRow';
import LotChip from '@horse-auction/common/components/LotChip/LotChip';
import { variants } from '@horse-auction/common/themes/customTheme';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import { Paper } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  auction?: AuctionDto;
}

const HeaderWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-top: 1rem;
  padding: 0.5rem;
`;

const AuctionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LotChipWrapper = styled.div`
  margin-right: 1rem;
`;

const TypographyStyled = styled(AppTypography)`
  text-align: center;
  color: ${variants.primary.main};
`;

const AuctionHeader = ({ auction }: CustomProps) => {
  const { t } = useTranslation(['components']);
  return (
    <HeaderWrapper>
      <AuctionTitle>
        <LotChipWrapper>
          <LotChip status={auction?.status} size='small' />
        </LotChipWrapper>
        <TypographyStyled variant='h5'>{auction?.title}</TypographyStyled>
      </AuctionTitle>

      <EventTimeRow
        eventDate={auction?.startDate}
        label={t<string>('components:auctionHeader.startsAt')}
      />
      {auction?.endDate && (
        <EventTimeRow
          eventDate={auction?.endDate}
          label={t<string>('components:auctionHeader.endsAt')}
        />
      )}
    </HeaderWrapper>
  );
};

export default AuctionHeader;
