import { useContext, useCallback } from 'react';

import { Box, IconButton } from '@mui/material';
import { FiMoon, FiSun } from 'react-icons/fi';
import styled from 'styled-components/macro';

import { ThemeContext } from '../../../../context/ThemeContext';

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0rem 0.25rem;
    color: black;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {
    margin-top: 5px;
    padding: 0.5rem;
  }
`;

const ThemeToggle = () => {
  const { theme, updateTheme } = useContext(ThemeContext);

  const toggleThemeHandler = useCallback(() => {
    let newMode = 'light';
    if (theme.name === 'light') {
      newMode = 'dark';
    }
    updateTheme(newMode);
  }, [updateTheme, theme]);

  return (
    <StyledIconButton size='small' onClick={() => toggleThemeHandler()}>
      <StyledBox>
        {theme.name === 'light' && <FiMoon />}
        {theme.name === 'dark' && <FiSun />}
      </StyledBox>
    </StyledIconButton>
  );
};

export default ThemeToggle;
