import { Backdrop, BackdropProps, CircularProgress } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  isLoading: boolean;
  children?: React.ReactNode;
  open?: boolean;
}

interface WrapperProps {
  noBackground?: boolean;
  height?: string;
}

interface Props extends CustomProps, WrapperProps, Omit<BackdropProps, 'open'> {}

const Wrapper = styled.div<WrapperProps>`
  .MuiBackdrop-root {
    z-index: 10;
    color: ${({ theme }) => theme?.palette?.primary?.main};
    position: absolute;
    height: ${({ height }) => height || 'unset'};
    background-color: ${({ noBackground }) => (noBackground ? 'unset' : 'rgba(0,0,0, 0.5)')};
  }
`;

const AppBackdrop: React.FC<Props> = ({
  isLoading,
  height = 'unset',
  noBackground = false,
  children,
  open = false,
}: Props) => (
  <Wrapper noBackground={noBackground} height={height}>
    {!isLoading && children}
    {isLoading === true && (
      <Backdrop open={open || isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    )}
  </Wrapper>
);

export default AppBackdrop;
