import { useCallback, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AlertContext } from '@horse-auction/common/context/AlertContext';
import useHttp from '@horse-auction/common/hooks/useHttp';

import NewsletterSubscriberAPI from '../../api/NewsletterSubscriberAPI';

const useSubscribeNewsletter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { createAlert } = useContext(AlertContext);
  const { t } = useTranslation(['start', 'alerts']);

  const [createNewsletterHttpState, createNewsletterRequest] = useHttp(null);

  const newsletterSubscribe = useCallback(
    (email, recaptchaToken) => {
      createNewsletterRequest(() =>
        NewsletterSubscriberAPI.createNewsletterSubscriber(email, recaptchaToken)
      )
        .then(() => {
          setIsOpen(false);

          createAlert(t('start:alertSignedUp'));
        })
        .catch(() => {
          createAlert(t('alerts:somethingWentWrong'), 'error');
        });
    },
    [createNewsletterRequest, createAlert, t]
  );

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDialog = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    closeDialog,
    openDialog,
    newsletterSubscribe,
  };
};

export default useSubscribeNewsletter;
