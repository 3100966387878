import { useEffect } from 'react';

import NumberFormat from 'react-number-format';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { InputBaseComponentProps, TextFieldProps } from '@mui/material';

import AppTextField from '../AppTextField/AppTextField';

type CustomProps = {
  isLoading?: boolean;
  fieldObj?: Omit<FieldObject, 'ref'> & { ref: (param?: HTMLBodyElement) => void };
};

type NumberFormatCustomProps = {
  name: string;
  thousandSeparator?: string;
  prefix?: string;
  valueProp?: string;
  format: string;
  mask?: string;
  inputRef?: (instance: NumberFormat<any> | null) => void;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  onValueUpdate?: () => void;
};

type FormatProps = InputBaseComponentProps & NumberFormatCustomProps;

export type Props = CustomProps & TextFieldProps & NumberFormatCustomProps;

const NumberFormatCustom: React.FC<FormatProps> = ({
  inputRef,
  name,
  thousandSeparator,
  prefix,
  valueProp,
  onValueUpdate,
  onChange,
  ...other
}: NumberFormatCustomProps) => {
  useEffect(() => {
    if (valueProp === undefined || valueProp === null) {
      onChange?.({
        target: {
          name,
          value: '',
        },
      });
    }
  }, [valueProp, name, onChange]);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange?.({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      isNumericString
      prefix={prefix || ''}
    />
  );
};

const AppNumberField: React.FC<Props> = ({
  name,
  fullWidth,
  label,
  prefix,
  defaultValue,
  format,
  placeholder,
  mask,
  thousandSeparator,
  error,
  helperText,
  size,
  required,
  disabled,
  onKeyPress,
  fieldObj,
  ...rest
}: Props) => {
  const valueChangeHandler = (event) => {
    fieldObj?.onChange(event.target.value);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <AppTextField
      name={name}
      label={label}
      onChange={valueChangeHandler}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      size={size}
      required={required}
      disabled={disabled}
      value={fieldObj?.value}
      onBlur={fieldObj?.onBlur}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: {
          thousandSeparator: thousandSeparator ?? ' ',
          format,
          placeholder,
          mask,
          prefix,
          valueProp: fieldObj?.value,
        },
      }}
      InputLabelProps={{
        shrink: !!fieldObj?.value || fieldObj?.value === 0,
      }}
    />
  );
};

export default AppNumberField;
