import { Breadcrumb } from '@horse-auction/common/types/Breadcrumb';
import { Breadcrumbs } from '@mui/material';

import StyledLink from '../StyledLink/StyledLink';

interface Props {
  breadcrumbs: Breadcrumb[];
}

const defaultBreadcrumbs = [{ key: 'home', label: 'Home', link: '/' }];

const AppBreadcrumbs: React.FC<Props> = ({ breadcrumbs = defaultBreadcrumbs }: Props) => {
  if (!breadcrumbs || breadcrumbs.length < 1) {
    return null;
  }

  return (
    <Breadcrumbs separator='›' aria-label='breadcrumb'>
      {breadcrumbs.map((breadcrumb) => (
        <StyledLink
          key={breadcrumb?.key}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(!breadcrumb?.active && { to: breadcrumb?.link })}
          active={breadcrumb?.active || false}
        >
          {breadcrumb?.label}
        </StyledLink>
      ))}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
