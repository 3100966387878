import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import NotFoundRoute from '@horse-auction/common/components/NotFoundRoute/NotFoundRoute';
import { AlertProvider } from '@horse-auction/common/context/AlertContext';
import { AuthProvider } from '@horse-auction/common/context/AuthContext';
import { SocketProvider } from '@horse-auction/common/context/SocketContext';
import { ThemeProvider } from '@horse-auction/common/context/ThemeContext';
import useTvView from '@horse-auction/common/hooks/useTvView';
import { setBaseUrl } from '@horse-auction/common/services/RequestService';
import GlobalFonts from '@horse-auction/common/themes/GlobalFonts';
import GlobalStyles from '@horse-auction/common/themes/GlobalStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Navbar from 'src/components/Navbar/Navbar';
import { NotificationsProvider } from 'src/context/NotificationsContext';
import { PurchasesProvider } from 'src/context/PurchasesContext';
import AuthLayout from 'src/layouts/AuthLayout';
import EmptyLayout from 'src/layouts/EmptyLayout';
import UserLayout from 'src/layouts/UserLayout';
import initializeI18next from 'src/locales/i18n';
import styled from 'styled-components/macro';

import FooterLarge from './components/FooterLarge/FooterLarge';

const pages = {
  user: [
    '',
    'auction',
    'auction-terms',
    'auctions',
    'account',
    'purchases',
    'moderate',
    'auctioneer',
  ],
  auth: ['login', 'signup', 'activation', 'forgot-password', 'reset-password'],
  empty: ['contact', 'how-to-bid', 'terms'],
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

const queryClient = new QueryClient();

const App = () => {
  setBaseUrl();
  initializeI18next();

  const { tvView } = useTvView();

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Equibid</title>
        <link rel='canonical' href='https://equibid.pl' />
      </Helmet>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SocketProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <AlertProvider>
                  <NotificationsProvider>
                    <PurchasesProvider>
                      {/* <GlobalFonts /> */}
                      <GlobalStyles />
                      <Container>
                        {!tvView && <Navbar />}
                        <Switch>
                          <Route path={`/(${pages.empty.join('|')})`} component={EmptyLayout} />
                          <Route path={`/(${pages.user.join('|')})`} component={UserLayout} />
                          <Route path={`/(${pages.auth.join('|')})`} component={AuthLayout} />
                          <Route component={NotFoundRoute} />
                        </Switch>
                        {!tvView && <FooterLarge />}
                      </Container>
                    </PurchasesProvider>
                  </NotificationsProvider>
                </AlertProvider>
              </AuthProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </SocketProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
