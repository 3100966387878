import { httpPut } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const cancelBid = (lotId: string, bidId: string) => {
  const payload = {
    lotId,
    bidId,
  };

  return CancellablePromise(httpPut('/bids/cancel', payload));
};

const restoreBid = (lotId: string, bidId: string) => {
  const payload = {
    lotId,
    bidId,
  };

  return CancellablePromise(httpPut('/bids/restore', payload));
};

const BidAPI = {
  cancelBid,
  restoreBid,
};

export default BidAPI;
