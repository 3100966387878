import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { AppBackdrop, AppTypography, AuctionCardLong } from '@horse-auction/common/components';
import { device } from '@horse-auction/common/themes/device';
import { RiEmotionSadLine } from 'react-icons/ri';
import useUpcomingAuctions from 'src/hooks/useUpcomingAuctions';
import styled from 'styled-components/macro';

import NewsletterSignupButton from './NewsletterSignupButton';

import 'src/locales/i18n';

type ContainerProps = PropsWithChildren & { id: string };

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const Content = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${device.mobile} {
    padding: 0;
  }
`;

const CardWrapper = styled.div<PropsWithChildren>``;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  width: 100%;
`;

const NoAuctionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 0 auto;
  max-width: 500px;

  .MuiTypography-root {
    font-size: 1.5rem;
  }
`;

const UpcomingAuctions = () => {
  const { t } = useTranslation(['start', 'alerts']);

  const { auctionsHttpState, auctions } = useUpcomingAuctions();

  return (
    <Container id='home'>
      <AppBackdrop isLoading={auctionsHttpState?.isLoading || !auctionsHttpState.initialized}>
        <Content>
          {(!auctions || auctions?.length === 0) && (
            <NoAuctionsWrapper>
              <RiEmotionSadLine size='5rem' />
              <AppTypography variant='body1' margin='1rem 0 2rem 0' textAlign='center'>
                {t<string>('start:noPlannedAuctions')}
              </AppTypography>
              <NewsletterSignupButton />
            </NoAuctionsWrapper>
          )}
          {auctions && auctions?.length > 0 && (
            <CardsWrapper>
              {auctions?.map((auction) => (
                <CardWrapper key={auction?._id}>
                  <AuctionCardLong
                    auction={auction}
                    logoKey=''
                    organizerLogoKey=''
                    isLoading={auctionsHttpState?.isLoading || false}
                  />
                </CardWrapper>
              ))}
            </CardsWrapper>
          )}
        </Content>
      </AppBackdrop>
    </Container>
  );
};

export default UpcomingAuctions;
