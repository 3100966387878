import React from 'react';

// import { func, string, node } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import UserDto from '@horse-auction/common/types/user.dto';
import { List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { FaQuestion } from 'react-icons/fa';
import { MdAccountCircle, MdEmail, MdHome, MdPayment, MdPersonAdd, MdShield } from 'react-icons/md';
import { RiAuctionFill } from 'react-icons/ri';
import styled from 'styled-components/macro';

interface DrawerLinkProps {
  children: React.ReactNode;
  to: string;
  label: string;
  color?: string;
  onClick?: () => void;
}

interface DrawerContentProps {
  user: UserDto | null;
  onLogout: () => void;
  onSelection: () => void;
}

const StyledNavLink = styled(NavLink)`
  color: ${({ theme, color }) => color || theme.colors.text};
  text-decoration: none;
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 1rem 0;
  }
`;

const DrawerLink = ({ children, to, label, color, onClick }: DrawerLinkProps) => (
  <StyledNavLink to={to} onClick={onClick} color={color}>
    <ListItem button>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  </StyledNavLink>
);

const DrawerContent = ({ user, onLogout, onSelection }: DrawerContentProps) => {
  const { t } = useTranslation(['navbar']);

  const getCompanyName = (companyName: string) => {
    if (companyName?.length > 20) {
      return `${companyName.substring(0, 20)}...`;
    }
    return companyName;
  };

  return (
    <List onClick={onSelection}>
      <ListItem>
        <AppTypography variant='h6'>
          {user?.companyName ? getCompanyName(user?.companyName) : user?.firstName}
        </AppTypography>
      </ListItem>
      <>
        <DrawerLink to='/' label={t<string>('navbar:home')}>
          <MdHome />
        </DrawerLink>
        <DrawerLink to='/auctions' label={t<string>('navbar:auctions')}>
          <RiAuctionFill />
        </DrawerLink>
        <DrawerLink to='/how-to-bid' label={t<string>('navbar:howToBid')}>
          <FaQuestion />
        </DrawerLink>
        <DrawerLink to='/contact' label={t<string>('navbar:contact')}>
          <MdEmail />
        </DrawerLink>
        {user?.role === 'MODERATOR' && (
          <DrawerLink to='/moderate' label={t<string>('navbar:moderate')} color='green'>
            <MdShield color='green' />
          </DrawerLink>
        )}
        {user?.role === 'AUCTIONEER' && (
          <DrawerLink to='/auctioneer' label={t<string>('navbar:auctioneer')} color='green'>
            <MdShield color='green' />
          </DrawerLink>
        )}
        <StyledDivider />

        {!user && (
          <>
            <DrawerLink to='/login' label={t<string>('navbar:login')}>
              <BiLogIn />
            </DrawerLink>
            <DrawerLink to='/signup' label={t<string>('navbar:register')}>
              <MdPersonAdd />
            </DrawerLink>
          </>
        )}
        {user && (
          <>
            <DrawerLink to='/account' label={t<string>('navbar:accountPage')}>
              <MdAccountCircle />
            </DrawerLink>
            {user?.role === 'CUSTOMER' && (
              <DrawerLink to='/purchases' label={t<string>('navbar:purchasesPage')}>
                <MdPayment />
              </DrawerLink>
            )}
            <StyledDivider />
            <ListItem button onClick={onLogout}>
              <ListItemIcon>
                <BiLogOut />
              </ListItemIcon>
              <ListItemText primary={t<string>('navbar:logout')} />
            </ListItem>
          </>
        )}
      </>
    </List>
  );
};

export default DrawerContent;
