import { useEffect, useState } from 'react';

import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import styled from 'styled-components/macro';

interface CustomProps {
  text: string;
}
const TextWrapper = styled.div`
  .rdw-editor-toolbar {
    display: none;
  }

  .rdw-editor-main {
    font-size: 14px;
    font-family: 'WorkSans';
  }
`;

const EditorDisplay: React.FC<CustomProps> = ({ text }: CustomProps) => {
  const [textConverted, setTextConverted] = useState<EditorState | null>(null);

  useEffect(() => {
    if (text) {
      const descriptionAfterConvertion = EditorState.createWithContent(
        convertFromRaw(JSON.parse(text))
      );

      setTextConverted(descriptionAfterConvertion);
    }
  }, [text]);

  if (!textConverted) {
    return null;
  }

  return (
    <TextWrapper>
      <Editor editorState={textConverted} readOnly />
    </TextWrapper>
  );
};

export default EditorDisplay;
