import React, { useContext, Suspense } from 'react';

import { Redirect, Route } from 'react-router-dom';

import { ConnectionLostAlert } from '@horse-auction/common/components/';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import LoginPage from 'src/pages/login/LoginPage';
import SignupPage from 'src/pages/signup/SignupPage';
import styled from 'styled-components/macro';

const ActivationPage = React.lazy(() => import('src/pages/activation/ActivationPage'));
const ForgotPasswordPage = React.lazy(() => import('src/pages/forgot-password/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('src/pages/reset-password/ResetPasswordPage'));

const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 4rem;
  justify-content: flex-start;
`;

const AuthLayout = () => {
  const authContext = useContext(AuthContext);

  if (authContext.user) {
    return <Redirect to='/' />;
  }

  return (
    <PageContent>
      {
        // TODO: swap suspense fallback
      }
      <ConnectionLostAlert />
      <Suspense fallback={<p>loading</p>}>
        <Route path='/login' component={LoginPage} />
        <Route path='/signup' component={SignupPage} />
        <Route path='/activation' component={ActivationPage} />
        <Route path='/forgot-password' component={ForgotPasswordPage} />
        <Route path='/reset-password' component={ResetPasswordPage} />
      </Suspense>
    </PageContent>
  );
};

export default AuthLayout;
