import { Controller, useFormContext } from 'react-hook-form';

import AppAutocomplete, {
  Props as AppAutocompleteProps,
} from '../../AppAutocomplete/AppAutocomplete';

type CustomProps = {
  name: string;
  fullWidth?: boolean;
};

export type Props = CustomProps & Omit<AppAutocompleteProps, 'helperText'>;

const RHFAutocomplete = ({ name, fullWidth = true, ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const helperText = errors?.[name]?.message as string;
  const componentProps = {
    name,
    fullWidth,
    helperText,
    ...rest,
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { disabled, ...fieldObj }, fieldState: { error } }) => {
        return <AppAutocomplete {...componentProps} error={!!error} fieldObj={fieldObj} />;
      }}
    />
  );
};

export default RHFAutocomplete;
