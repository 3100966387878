import { useCallback, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import FormButton from '@horse-auction/common/components/Form/components/FormButton';
import FormTextField from '@horse-auction/common/components/Form/components/FormTextField';
import Form from '@horse-auction/common/components/Form/Form';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

interface Props {
  isLoading: boolean;
  onSubmit: (email: string, recaptchaToken: string) => void;
  onCancel: () => void;
}

const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ReCAPTCHAContainer = styled.div`
  margin: 1rem 0;
  position: relative;
  height: 76px;

  @media ${device.mobile} {
    transform: scale(0.8);
    transform-origin: 0 0;
  }

  > div {
    position: absolute;
  }
`;

const ButtonsWrapper = styled.div`
  margin: 1rem 0;

  button {
    margin-top: 1rem;
  }
`;

const NewsletterForm = ({ isLoading, onSubmit, onCancel }: Props) => {
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  const recaptchaRef = useRef<any>();

  const { t } = useTranslation(['start', 'validation']);

  const schema = yup.object().shape({
    email: yup.string().required(t('validation:required')).email(t('validation:email')),
  });

  const submitFormHandler = useCallback(
    (data) => {
      if (recaptchaToken) {
        recaptchaRef.current?.reset();
        setRecaptchaToken('');
        onSubmit(data.email, recaptchaToken);
      }
    },
    [onSubmit, recaptchaRef, recaptchaToken]
  );

  const recaptchaChangeHandler = useCallback((value) => {
    setRecaptchaToken(value);
  }, []);

  if (!schema) {
    return null;
  }

  return (
    <StyledForm schema={schema}>
      <FormTextField
        name='email'
        label={t<string>('start:newsletterDialog.form.email')}
        defaultValue=''
        type='text'
      />
      {recaptchaPublicKey && (
        <ReCAPTCHAContainer>
          <ReCAPTCHA
            sitekey={recaptchaPublicKey}
            ref={recaptchaRef}
            onChange={recaptchaChangeHandler}
          />
        </ReCAPTCHAContainer>
      )}
      <ButtonsWrapper>
        <FormButton
          onClick={submitFormHandler}
          disabled={!recaptchaToken}
          isLoading={isLoading}
          size='large'
        >
          {t<string>('start:notifyButton')}
        </FormButton>
        <AppButton fullWidth size='large' onClick={onCancel} variant='outlined' color='primary'>
          {t<string>('start:cancel')}
        </AppButton>
      </ButtonsWrapper>
    </StyledForm>
  );
};

export default NewsletterForm;
