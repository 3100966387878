import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { css } from 'styled-components';

import { device } from '@horse-auction/common/themes/device';
import { Chip } from '@mui/material';
import { MdClose, MdCheck } from 'react-icons/md';
import styled from 'styled-components/macro';

import AppTypography from '../AppTypography/AppTypography';

interface ContainerProps {
  labelWidth?: string;
  size?: string;
}

interface CustomProps {
  children?: React.ReactNode | null;
  label?: string;
  value?: string | number | boolean;
  currency?: string;
}

interface Props extends CustomProps, ContainerProps {}

const mediumSize = css<ContainerProps>`
  .MuiTypography-root {
    font-size: 24px;
    font-weight: normal;
  }
  grid-template-columns: ${({ labelWidth }) => `${labelWidth || '300px'} 1fr`};
`;

const LabeledDataContainer = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${({ labelWidth }) => `${labelWidth || '150px'} 1fr`};
  align-items: center;
  ${({ size }) => {
    if (size === 'medium') {
      return mediumSize;
    }
    return '';
  }};

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    padding-bottom: 1rem;
  }
`;

const Label = styled(AppTypography)`
  text-transform: uppercase;
  font-weight: 600;
`;

const Value = styled(AppTypography)``;

const LabeledData: React.FC<Props> = ({
  children,
  label,
  value,
  currency,
  labelWidth,
  size,
}: Props) => {
  const { t } = useTranslation();

  const getParsedValue = () => {
    if (value === true) {
      return (
        <Chip
          variant='outlined'
          label={t<string>('components:labeledData.yes')}
          color='success'
          icon={<MdCheck />}
          size='small'
        />
      );
    }
    if (value === false) {
      return (
        <Chip
          variant='outlined'
          color='error'
          label={t<string>('components:labeledData.no')}
          icon={<MdClose />}
          size='small'
        />
      );
    }
    if (value && !isNaN(+value) && !isNaN(parseFloat(`${value}`))) {
      return (
        <NumberFormat
          thousandSeparator=' '
          displayType='text'
          prefix={currency ? `${currency} ` : ''}
          value={value}
        />
      );
    }
    if (!value && children) {
      return '';
    }
    return value || '-';
  };

  return (
    <LabeledDataContainer labelWidth={labelWidth} size={size}>
      <Label variant='subtitle2'>{label}</Label>
      <Value variant='body2'>
        {getParsedValue()}
        {children}
      </Value>
    </LabeledDataContainer>
  );
};

export default LabeledData;
