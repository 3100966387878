import { useEffect, useCallback, useState } from 'react';

import { v4 as uuid } from 'uuid';

const useBrowserSession = () => {
  const [browserSessionId, setBrowserSessionId] = useState<string | null>(null);

  const getBrowserSession = useCallback(() => {
    const sessionKey = process.env.REACT_APP_NAME === 'ctp' ? 'eb-ctp-sid' : 'eb-web-sid';
    let sessionId = localStorage.getItem(sessionKey);
    if (!sessionId) {
      const sid = uuid();
      sessionId = `${sessionId}`;
      localStorage.setItem(sessionKey, sid);
    }
    return sessionId;
  }, []);

  useEffect(() => {
    const sessionId = getBrowserSession();
    setBrowserSessionId(sessionId);
  }, [getBrowserSession]);

  return { browserSessionId };
};

export default useBrowserSession;
