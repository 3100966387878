import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import genericHorse from '@horse-auction/common/assets/images/equibidLogo.png';
import {
  AppTypography,
  EventTimeRow,
  LotChip,
  LotOutcomeRow,
  StyledLink,
} from '@horse-auction/common/components/index';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Card, Divider } from '@mui/material';
import styled from 'styled-components/macro';

const { REACT_APP_SERVER_URL } = process.env;

type StyledImageProps = { src: string; alt: string };

interface Props {
  lot: LotDto;
  startDate?: Date;
  endDate?: Date;
  auctionType: string;
  auctionStatus: string;
  previewToken?: string;
}

const LinkWrap = styled(StyledLink)`
  display: flex;
  height: 100%;
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 1rem 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  align-self: flex-start;
  padding: 1rem;
`;

const StyledImage = styled.img<StyledImageProps>`
  height: 150px;
  object-fit: contain;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionArea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 130px 1fr 2rem auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const LotOutcomeRowWrapper = styled.div`
  padding: 0 0.25rem;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  position: relative;

  &.MuiCard-root {
    width: 100%;
    transition: transform 0.4s;

    &:hover {
      transform: scale(1.03);
    }
  }
`;

const LotCard = ({ lot, startDate, endDate, auctionType, auctionStatus, previewToken }: Props) => {
  const { t } = useTranslation(['auctions']);

  const getImagePath = useCallback(
    (imageKey) =>
      imageKey ? `${REACT_APP_SERVER_URL}/api/v1/files/image-thumb/${imageKey}` : genericHorse,
    []
  );

  return (
    <LinkWrap
      to={
        previewToken
          ? `/auctions/${lot?.auctionId}/lots/${lot?._id}?preview=${previewToken}`
          : `/auctions/${lot?.auctionId}/lots/${lot?._id}`
      }
      noUnderline
    >
      <StyledCard>
        <ActionArea>
          <ImageWrapper>
            <StyledImage
              alt={lot?.product?.name || 'horse'}
              src={getImagePath(lot.product?.images?.[0]?.key)}
            />
          </ImageWrapper>
          <ContentWrapper>
            <AppTypography gutterBottom variant='h6' component='h2'>
              {`${lot?.auctionOrder + 1}. ${lot?.product?.name}`}
            </AppTypography>
            <InfoWrapper>
              {lot?.status === 'SCHEDULED' && startDate && (
                <EventTimeRow eventDate={startDate} label={t<string>('start:startsAt')} />
              )}
              {lot?.status === 'IN_PROGRESS' && endDate && (
                <EventTimeRow eventDate={endDate} label={t<string>('start:endsAt')} />
              )}
              <LotChip status={lot?.status} size='small' />
            </InfoWrapper>
          </ContentWrapper>
          <StyledDivider />
          <LotOutcomeRowWrapper>
            <LotOutcomeRow lot={lot} auctionType={auctionType} auctionStatus={auctionStatus} />
          </LotOutcomeRowWrapper>
        </ActionArea>
      </StyledCard>
    </LinkWrap>
  );
};

export default LotCard;
