/* eslint react/jsx-props-no-spreading: 0 */

import { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { AuthContext } from '@horse-auction/common/context/AuthContext';
import AcceptAuctionTerms from 'src/components/AcceptAuctionTerms/AcceptAuctionTerms';
import EntranceDepositAlert from 'src/components/EntranceDepositAlert/EntranceDepositAlert';
import GoToLiveAlert from 'src/components/GoToLiveAlert/GoToLiveAlert';
import PaymentDialog from 'src/components/PaymentDialog/PaymentDialog';
import PaymentOutcomeDialog from 'src/components/PaymentOutcomeDialog/PaymentOutcomeDialog';
import UserBlockedAlert from 'src/components/UserBlockedAlert/UserBlockedAlert';
import { AuctionContext } from 'src/context/AuctionContext';

const AuctionAlerts = () => {
  const { user } = useContext(AuthContext);
  const {
    auction,
    acceptAuctionTerms,
    userOrganization,
    userAuction,
    userAuctionHttpState,
    currentLot,
    customerEntranceDepositRequired,
    stripePaymentIntentClientSecret,
  } = useContext(AuctionContext);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const paymentStatus = urlParams.get('redirect_status');

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [outcomeDialogOpen, setOutcomeDialogOpen] = useState(false);

  const acceptTermsHandler = useCallback(() => {
    acceptAuctionTerms?.();
  }, [acceptAuctionTerms]);

  const termsRendered = useCallback(() => {
    const rendered =
      userAuctionHttpState?.fetched === true &&
      auction?.status &&
      ['IN_PROGRESS', 'SCHEDULED'].includes(auction?.status) &&
      user?.role === 'CUSTOMER' &&
      (!userAuction || userAuction?.auctionTermsAccepted !== true) &&
      !userAuction?.blocked;
    return rendered;
  }, [user, auction?.status, userAuction, userAuctionHttpState?.fetched]);

  const entranceDepositAlertVisible = useCallback(() => {
    const rendered =
      userAuctionHttpState?.fetched === true &&
      auction?.status &&
      ['IN_PROGRESS', 'SCHEDULED'].includes(auction?.status) &&
      userAuction?.auctionTermsAccepted === true &&
      customerEntranceDepositRequired &&
      !userAuction?.blocked;

    return rendered;
  }, [
    auction?.status,
    userAuctionHttpState?.fetched,
    userAuction?.auctionTermsAccepted,
    userAuction?.blocked,
    customerEntranceDepositRequired,
  ]);

  const getEntranceDepositSuccessDialogVisible = useCallback(
    () => paymentStatus === 'succeeded',
    [paymentStatus]
  );

  const getEntranceDepositFailureDialogVisible = useCallback(
    () => paymentStatus === 'failed',
    [paymentStatus]
  );

  const userBlocked = useCallback(
    () => userAuction?.blocked || userOrganization?.blocked,
    [userAuction?.blocked, userOrganization?.blocked]
  );

  const closePaymentDialogHandler = useCallback(() => {
    setPaymentDialogOpen(false);
  }, []);

  const openPaymentDialogHandler = useCallback(() => {
    setPaymentDialogOpen(true);
  }, []);

  const closeOutcomeDialogHandler = useCallback(() => {
    history?.push(history?.location?.pathname);
    setOutcomeDialogOpen(false);
  }, [history]);

  const payNowHandler = useCallback(() => {
    closeOutcomeDialogHandler();
    openPaymentDialogHandler();
  }, [closeOutcomeDialogHandler, openPaymentDialogHandler]);

  useEffect(() => {
    if (getEntranceDepositSuccessDialogVisible()) {
      setOutcomeDialogOpen(true);
    }
    if (getEntranceDepositFailureDialogVisible()) {
      setOutcomeDialogOpen(true);
    }
  }, [
    paymentStatus,
    getEntranceDepositSuccessDialogVisible,
    getEntranceDepositFailureDialogVisible,
  ]);

  return (
    <>
      {auction?.status && ['IN_PROGRESS'].includes(auction?.status) && currentLot && (
        <GoToLiveAlert auctionId={auction?._id} lotId={currentLot?._id} />
      )}
      {termsRendered() && auction?._id && (
        <AcceptAuctionTerms
          auctionId={auction?._id}
          onAccept={acceptTermsHandler}
          isLoading={userAuctionHttpState?.isLoading}
        />
      )}
      {entranceDepositAlertVisible() && (
        <EntranceDepositAlert onPaymentClick={openPaymentDialogHandler} />
      )}
      {userBlocked() && (
        <UserBlockedAlert blockadeType={userOrganization?.blocked ? 'ORGANIZATION' : 'AUCTION'} />
      )}
      <PaymentDialog
        open={paymentDialogOpen}
        auction={auction}
        stripeSecret={stripePaymentIntentClientSecret}
        purchaseType='ENTRANCE_DEPOSIT'
        onCancel={closePaymentDialogHandler}
      />
      {paymentStatus && (
        <PaymentOutcomeDialog
          open={outcomeDialogOpen}
          paymentStatus={paymentStatus}
          onCancel={closeOutcomeDialogHandler}
          onPayNow={payNowHandler}
        />
      )}
    </>
  );
};

export default AuctionAlerts;
