import { useState } from 'react';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { TimePicker, TimePickerProps } from '@mui/lab';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  isLoading: boolean;
  fieldObj: FieldObject;
}

export type Props = CustomProps & TimePickerProps & Omit<TextFieldProps, 'onChange'>;

const Wrapper = styled.div`
  .MuiTextField-root {
    margin-top: 1rem;
  }
`;

const AppTimePicker: React.FC<Props> = ({
  name,
  label,
  required,
  variant,
  color,
  error,
  size,
  openTo,
  views,
  helperText,
  disabled,
  fullWidth,
  isLoading,
  ampm,
  autoComplete,
  fieldObj,
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = useState(fieldObj.value);

  const { ref, ...fieldObjRest } = fieldObj;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Wrapper>
      <TimePicker
        label={label}
        openTo={openTo}
        views={views}
        inputFormat='HH:mm:ss'
        ampm={ampm}
        {...fieldObjRest}
        inputRef={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={fullWidth}
            helperText={helperText}
            disabled={isLoading || disabled}
            required={required}
            error={error}
          />
        )}
      />
    </Wrapper>
  );
};

export default AppTimePicker;
