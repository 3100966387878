import { useState, createContext, useEffect, useCallback, useMemo } from 'react';

import { io, Socket } from 'socket.io-client';

import useBrowserSession from '@horse-auction/common/hooks/useBrowserSession';

interface SocketContextProps {
  children: React.ReactNode;
}

type ISocketContext = {
  socket: Socket | null;
  socketId: string;
  browserSessionId: string | null;
  subscribeEvent: (eventObject: any, callback: any) => Socket | null | undefined;
  unsubscribeEvent: (eventObject: any, callback: any) => Socket | null | undefined;
  connected: boolean;
};

const initialAuthContext = {
  connected: false,
  socket: null,
  socketId: '',
  browserSessionId: null,
  subscribeEvent: (evObj, cb) => undefined,
  unsubscribeEvent: (evObj, cb) => undefined,
};

const { REACT_APP_SERVER_URL } = process.env;

const SocketContext = createContext<ISocketContext>(initialAuthContext);

const SocketProvider = ({ children }: SocketContextProps) => {
  // TODO: ts check if ok
  const [socket, setSocket] = useState<Socket | null>(null);
  const [subscriptions, setSubscriptions] = useState({});
  const [connected, setConnected] = useState(true);
  const [socketId, setSocketId] = useState<string>('');

  const { browserSessionId } = useBrowserSession();

  useEffect(() => {
    if (!socket && REACT_APP_SERVER_URL) {
      const newSocket = io(REACT_APP_SERVER_URL);
      newSocket.on('connect', () => undefined);

      setSocket(newSocket);
    }

    return () => {
      socket?.removeAllListeners();
      socket?.close();
    };
  }, [socket]);

  useEffect(() => {
    socket?.on('connect', () => {
      setConnected(true);
      setSocketId(socket.id);
      socket?.emit('subscribeMultipleEvents', subscriptions);
      socket?.emit('browserSession', browserSessionId);
    });

    socket?.on('disconnect', () => {
      setConnected(false);
    });
  }, [socket, subscriptions, browserSessionId]);

  const subscribeEvent = useCallback(
    (eventObject, callback) => {
      if (!eventObject || !eventObject.type || !eventObject.name) {
        return null;
      }
      socket?.on(eventObject?.name, callback);
      setSubscriptions((prevState) => ({ ...prevState, [eventObject.key]: eventObject }));
      return socket?.emit('subscribeEvent', eventObject);
    },
    [socket]
  );

  const unsubscribeEvent = useCallback(
    (eventObject, callback) => {
      socket?.off(eventObject?.name, callback);
      if (!eventObject || !eventObject.type) {
        return null;
      }
      setSubscriptions((prevState) => {
        const newSubscriptionsObj = { ...prevState };
        delete newSubscriptionsObj[eventObject.key];
        return newSubscriptionsObj;
      });

      return socket?.emit('unsubscribeEvent', eventObject);
    },
    [socket]
  );

  const contextValueObj = useMemo(
    () => ({ socket, socketId, subscribeEvent, unsubscribeEvent, connected, browserSessionId }),
    [browserSessionId, connected, socket, socketId, subscribeEvent, unsubscribeEvent]
  );

  return (
    <SocketContext.Provider value={contextValueObj}>{socketId && children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
