import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FieldObject from '@horse-auction/common/types/FieldObject';
import SelectOption from '@horse-auction/common/types/SelectOption';
import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  isLoading?: boolean;
  fieldObj?: FieldObject;
  helperText?: string;
  options: SelectOption[];
}

export type Props = CustomProps & SelectProps & FormHelperTextProps;

const StyledSelect = styled(Select)`
  input:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.colors.card} inset !important`};
    -webkit-box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.colors.card} inset !important`};
    -webkit-text-fill-color: ${({ theme }) => `${theme.colors.text} !important`};
    font-size: 1rem;
  }
  &.MuiInputBase-root {
    .MuiInputBase-input {
      box-shadow: none;
    }
    input:-internal-autofill-selected {
      background-color: none !important;
    }
    input:-webkit-autofill {
      background-color: none !important;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    margin-top: 1rem;
  }
`;

const AppSelect: React.FC<Props> = ({
  name = '',
  value,
  helperText,
  fullWidth,
  color = 'primary',
  variant = 'outlined',
  required,
  type = 'text',
  error,
  isLoading = false,
  label,
  disabled,
  options,
  fieldObj,
  onChange,
}: Props) => {
  const [selectValue, setSelectValue] = useState(value || fieldObj?.value);

  const { t } = useTranslation(['components']);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <StyledFormControl variant={variant} fullWidth={fullWidth} error={error} required={required}>
      <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
      <StyledSelect
        labelId={name}
        label={label}
        type={type}
        color={color}
        disabled={isLoading || disabled}
        {...fieldObj}
        value={selectValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSelectValue(event.target.value);
          fieldObj?.onChange?.(event);
          onChange?.(event);
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value as string}>
            {option.value ? option.label : <i>{option.label}</i>}
          </MenuItem>
        ))}
        {(!options || options.length === 0) && (
          <MenuItem>
            <i>{t<string>('components:appSelect.noItems')}</i>
          </MenuItem>
        )}
      </StyledSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </StyledFormControl>
  );
};

export default AppSelect;
