import { useEffect, useState } from 'react';

import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import LotDto from '@horse-auction/common/types/lot.dto';
import styled from 'styled-components/macro';

import 'src/locales/i18n';

interface Props {
  lot: LotDto;
  lotsQuantity?: number;
  onClick?: () => void;
}

const DescriptionWrapper = styled.div`
  margin-top: 2rem;
  align-self: flex-start;
  .rdw-editor-toolbar {
    display: none;
  }
`;

const LotDescription = ({ lot, lotsQuantity = 0, onClick }: Props) => {
  const { t } = useTranslation(['auction']);

  const [descriptionConverted, setDescriptionConverted] = useState<EditorState>();

  useEffect(() => {
    if (lot?.product?.description) {
      const descriptionAfterConvertion = EditorState.createWithContent(
        convertFromRaw(JSON.parse(lot?.product?.description))
      );

      setDescriptionConverted(descriptionAfterConvertion);
    }
  }, [lot]);

  return (
    <>
      {descriptionConverted && (
        <DescriptionWrapper>
          <Editor editorState={descriptionConverted} readOnly />
        </DescriptionWrapper>
      )}
    </>
  );
};

export default LotDescription;
