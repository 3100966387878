import { useEffect, useState, useCallback, createContext, useContext, useMemo } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

import UserDto from '@horse-auction/common/types/user.dto';
import { getFullName } from '@horse-auction/common/utils/user.util';
import { useQueryClient } from '@tanstack/react-query';

import { SocketContext } from './SocketContext';
import { AuthAPI } from '../api';
import useAuthTokens from '../hooks/useAuthTokens';
import useHttp from '../hooks/useHttp';

interface AuthContextProps {
  children: React.ReactNode;
}

type IAuthContext = {
  accessToken: string | null;
  user: UserDto | null;
  logout: () => Promise<any>;
  updateUser: (user: UserDto | null) => void;
  updateAccessToken: (token: string) => void;
};

const initialAuthContext = {
  accessToken: null,
  user: null,
  logout: () => Promise.resolve(),
  updateUser: () => undefined,
  updateAccessToken: () => undefined,
};

const AuthContext = createContext<IAuthContext>(initialAuthContext);

const AuthProvider = ({ children }: AuthContextProps) => {
  const [logoutState, logoutRequest] = useHttp();
  const [user, setUser] = useState<UserDto | null>(null);
  const history = useHistory();

  const queryClient = useQueryClient();

  const { socket, socketId } = useContext(SocketContext);

  const updateUser = useCallback((currentUser: UserDto | null) => {
    let userExtended: UserDto | null = null;
    if (currentUser) {
      userExtended = {
        ...currentUser,
        fullName: getFullName(currentUser),
      } as UserDto;
    }
    setUser(userExtended);
  }, []);

  const { accessToken, setAccessToken, accessTokenLoaded } = useAuthTokens(updateUser);

  const updateAccessToken = useCallback(
    (tokenParam) => {
      setAccessToken(tokenParam);
    },
    [setAccessToken]
  );

  const clear = useCallback(() => {
    axios.defaults.headers.common.Authorization = '';
    updateUser(null);
    setAccessToken(null);
  }, [updateUser, setAccessToken]);

  const clearAndRedirect = useCallback(() => {
    const userLoggedIn = !!user;
    clear();
    if (userLoggedIn) {
      history.push({
        pathname: '/',
      });
    }
  }, [clear, history, user]);

  const logout = useCallback(() => {
    queryClient.removeQueries();
    return logoutRequest(() => AuthAPI.logout());
  }, [logoutRequest, queryClient]);

  useEffect(() => {
    socket?.on('logout', () => {
      clearAndRedirect();
    });
  }, [clearAndRedirect, socket]);

  useEffect(() => {
    if (socketId) {
      socket?.emit('auth', { token: accessToken });
    }
  }, [socket, socketId, accessToken]);

  const authContextValue = useMemo(
    () => ({
      accessToken,
      user,
      logout,
      updateUser,
      updateAccessToken,
    }),
    [accessToken, logout, updateAccessToken, updateUser, user]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {accessTokenLoaded && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
