import { useEffect, useState, useCallback, createContext, useMemo } from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { themes, variants, AppTheme } from '@horse-auction/common/themes/customTheme';
import { breakpointsInPcs } from '@horse-auction/common/themes/device';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { setToLocalStorage, getFromLocalStorage } from '../utils/localStorage';

interface ThemeContextProps {
  children: React.ReactNode;
}

type IThemeContext = {
  theme: AppTheme;
  themeLoaded: boolean;
  updateTheme: (string) => void;
};

const initialThemeContext = {
  theme: themes.light,
  themeLoaded: false,
  updateTheme: (mode) => undefined,
};

const ThemeContext = createContext<IThemeContext>(initialThemeContext);

const ThemeObjectProvider = ({ children }: ThemeContextProps) => {
  const [theme, setTheme] = useState(themes.light);
  const [themeLoaded, setThemeLoaded] = useState(false);

  useEffect(() => {
    const localTheme = getFromLocalStorage('theme');
    localTheme
      ? setTheme({ ...localTheme, ...getMuiTheme(localTheme?.name) })
      : setTheme({ ...themes.light, ...getMuiTheme('light') });
    setThemeLoaded(true);
  }, []);

  const getMuiTheme = (themeType) =>
    createTheme({
      palette: {
        mode: themeType,
        ...variants,
      },
      breakpoints: {
        values: breakpointsInPcs,
      },
      typography: {
        fontFamily: [
          'WorkSans',
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
        ].join(','),
      },
    });

  const updateTheme = useCallback((mode) => {
    setToLocalStorage('theme', themes[mode]);
    setTheme({ ...themes[mode], ...getMuiTheme(mode) });
  }, []);

  const contextValueObj = useMemo(
    () => ({
      theme,
      themeLoaded,
      updateTheme,
    }),
    [theme, themeLoaded, updateTheme]
  );

  return (
    <ThemeProvider theme={getMuiTheme(theme.name)}>
      <StyledThemeProvider theme={theme}>
        <ThemeContext.Provider value={contextValueObj}>
          {themeLoaded && children}
        </ThemeContext.Provider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export { ThemeContext, ThemeObjectProvider as ThemeProvider };
