import { useState, useCallback, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import image from '@horse-auction/common/assets/images/signup.jpeg';
import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import useBreakpoint from '@horse-auction/common/hooks/useBreakpoint';
import { device } from '@horse-auction/common/themes/device';
import { Box, Paper, Tabs, Tab, Alert, Stepper, Step, StepLabel } from '@mui/material';
import styled from 'styled-components/macro';

import SignupForm from './components/SignupForm';

type StyledImageProps = PropsWithChildren & { src: string; alt: string };

const PageWrapper = styled.div`
  width: 1000px;
  align-self: center;

  @media ${device.tablet} {
    width: unset;
  }
`;

const SignupConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 5fr 4fr;
  }

  @media ${device.mobile} {
    &.MuiPaper-root {
      grid-template-columns: 1fr;
    }
  }
`;

const StyledLeftWrapper = styled.div`
  padding: 1rem 1rem 2rem 1rem;
`;

const StyledRightWrapper = styled.div`
  &.MuiBox-root {
    padding: 0;
    margin: 0;
  }
  width: 450px;

  @media ${device.mobile} {
    display: none;
  }
`;

const StyledStepper = styled(Stepper)`
  &.MuiStepper-root {
    margin-bottom: 1rem;
  }
`;

const StyledImage = styled.img<StyledImageProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SignupPage = () => {
  const history = useHistory();
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const breakpoint = useBreakpoint();

  const { t } = useTranslation(['auth']);

  const loginButtonClickHandler = () => {
    history.push('/login');
  };

  const signupSuccessHandler = useCallback(() => {
    setSignupSuccess(true);
  }, []);

  const tabChangeHandler = useCallback((event, tabIndex) => {
    setCurrentTab(tabIndex);
  }, []);

  return (
    <PageWrapper>
      <AppTypography variant='h4' align='center' gutterBottom>
        {t<string>('auth:signup.title')}
      </AppTypography>
      <StyledPaper>
        <StyledLeftWrapper>
          <StyledStepper activeStep={signupSuccess === true ? 1 : 0}>
            <Step key='0' completed={signupSuccess === true}>
              <StepLabel>{t<string>('auth:signup.steps.signup')}</StepLabel>
            </Step>
            <Step key='1'>
              <StepLabel>{t<string>('auth:signup.steps.activate')}</StepLabel>
            </Step>
          </StyledStepper>
          {signupSuccess === true && (
            <SignupConfirmation>
              <Box my='1rem'>
                <Alert severity='info' variant='outlined'>
                  {t<string>('auth:signup.signupSuccess')}
                </Alert>
              </Box>
              <AppTypography variant='body1' paragraph>
                {t<string>('auth:signup.signupSuccessDescription')}
              </AppTypography>
              <AppButton
                variant='outlined'
                size='large'
                fullWidth
                onClick={loginButtonClickHandler}
              >
                {t<string>('auth:signup.backButton')}
              </AppButton>
            </SignupConfirmation>
          )}
          {signupSuccess !== true && (
            <>
              <Tabs
                value={currentTab}
                onChange={tabChangeHandler}
                indicatorColor='primary'
                textColor='primary'
                centered
                variant={breakpoint === 'mobile' ? 'scrollable' : 'standard'}
              >
                <Tab label={t<string>('auth:signup.natural')} />
                <Tab label={t<string>('auth:signup.legal')} />
              </Tabs>
              <Box display={currentTab === 0 ? 'block' : 'none'}>
                <SignupForm onSuccess={signupSuccessHandler} customerType='NATURAL' />
              </Box>
              <Box display={currentTab === 1 ? 'block' : 'none'}>
                <SignupForm onSuccess={signupSuccessHandler} customerType='LEGAL' />
              </Box>
            </>
          )}
        </StyledLeftWrapper>
        <StyledRightWrapper>
          <StyledImage src={image} alt='horse-register' />
        </StyledRightWrapper>
      </StyledPaper>
    </PageWrapper>
  );
};

export default SignupPage;
