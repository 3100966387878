import { httpGet } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getCustomerPurchases = () => {
  const payload = {};

  return CancellablePromise(httpGet('/purchases/customer', payload));
};

const getPurchase = (lotId: string) => CancellablePromise(httpGet(`/purchases/lot/${lotId}`));

const PurchaseAPI = {
  getCustomerPurchases,
  getPurchase,
};

export default PurchaseAPI;
