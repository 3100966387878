import { useContext, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AppBackdrop,
  AppTypography,
  ResponsivePage,
  StyledLink,
} from '@horse-auction/common/components';
import { device } from '@horse-auction/common/themes/device';
import { Breadcrumbs } from '@mui/material';
import { ImHourGlass } from 'react-icons/im';
import AuctionAlerts from 'src/components/AuctionAlerts/AuctionAlerts';
import AuctionHeader from 'src/components/AuctionHeader/AuctionHeader';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

import LotCard from './components/LotCard';
import AuctionFooter from '../../components/AuctionFooter/AuctionFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AuctionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiBreadcrumbs-root {
    margin-bottom: 1rem;
  }
`;

const CardWrapper = styled.div<PropsWithChildren>``;

const NoLotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem auto 2rem auto;
  max-width: 500px;

  .MuiTypography-root {
    font-size: 1.5rem;
  }
`;

const LotsGrid = styled.div`
  margin-block: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const AuctionPage = () => {
  const { t } = useTranslation(['auction']);

  const { auction, auctionHttpState, previewToken } = useContext(AuctionContext);

  return (
    <ResponsivePage>
      <AppBackdrop isLoading={auctionHttpState?.isLoading} noBackground />

      {!auctionHttpState?.isLoading && (
        <Wrapper>
          <AuctionContent>
            <StyledBreadcrumbs separator='›' aria-label='breadcrumb'>
              <StyledLink to='/'>Home</StyledLink>
              <StyledLink to='/auctions'>Auctions</StyledLink>
              <StyledLink active>{auction?.title || 'Auction'}</StyledLink>
            </StyledBreadcrumbs>
            <AuctionAlerts />
            <AuctionHeader auction={auction} />
            {auction?.lots && auction.lots?.length > 0 ? (
              <LotsGrid>
                {auction?.lots?.map(
                  (lot) =>
                    lot && (
                      <CardWrapper key={lot?._id}>
                        <LotCard
                          lot={lot}
                          auctionType={auction?.type}
                          auctionStatus={auction?.status}
                          previewToken={previewToken}
                        />
                      </CardWrapper>
                    )
                )}
              </LotsGrid>
            ) : (
              <NoLotsWrapper>
                <ImHourGlass size='3rem' />
                <AppTypography variant='body1' margin='1rem 0 2rem 0' textAlign='center'>
                  {t<string>('auction:auctionPage.noLots')}
                </AppTypography>
              </NoLotsWrapper>
            )}
          </AuctionContent>
          {auction?._id && <AuctionFooter auctionId={auction._id} />}
        </Wrapper>
      )}
    </ResponsivePage>
  );
};

export default AuctionPage;
