import { LinkProps } from 'react-router-dom';

import { StyledLink } from './Link.style';

interface CustomProps {
  size?: string;
  variant?: string;
  label?: string;
  disabled?: string;
  onHover?: () => void;
}

interface Props extends CustomProps, LinkProps {}

const Link = ({
  to = '/',
  target,
  children,
  size,
  variant = 'primary',
  label,
  disabled,
  onClick,
  onHover,
}: Props) => {
  const getLabel = () => {
    if (label) {
      return label;
    }

    if (children) {
      return children;
    }

    return 'Link';
  };
  const clickHandler = (event) => {
    if (disabled) return;
    onClick?.(event);
  };

  return (
    <StyledLink
      to={to}
      target={target}
      variant={variant}
      onClick={clickHandler}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
    >
      {getLabel()}
    </StyledLink>
  );
};

export default Link;
