import { Paper, PaperProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

type CustomProps = PropsWithChildren & {
  padding?: string;
  fullWidth?: boolean;
  width?: string;
};

type Props = CustomProps & PaperProps;

const StyledAppPaper = styled(Paper).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    console.log('StyledAppPaper ~ prop:', prop, !['fullWidth', 'width'].includes(prop));
    return !['fullWidth', 'width'].includes(prop) && defaultValidatorFn(prop);
  },
})<Props>`
  &.MuiPaper-root {
    width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width || '300px')};
  }
`;

const PaperContentWrapper = styled.div<CustomProps>`
  padding: ${({ padding }) => padding};
`;

const AppPaper: React.FC<Props> = ({
  children,
  square,
  elevation = 3,
  variant = 'elevation',
  padding = '2rem',
  width = '300px',
  fullWidth = true,
}: Props) => (
  <StyledAppPaper fullWidth elevation={elevation} variant={variant} square={square}>
    <PaperContentWrapper padding={padding}>{children}</PaperContentWrapper>
  </StyledAppPaper>
);

export default AppPaper;
