import { useEffect, useState } from 'react';

import { IconButton, IconButtonProps, Paper, TextField } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  DataGridProps,
} from '@mui/x-data-grid';
import { MdClear, MdSearch } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  isLoading?: boolean;
  toolbar?: boolean;
}

interface CustomQuickSearchProps {
  clearSearch: () => void;
  onChange: () => void;
}

interface QuickSearchProps extends CustomQuickSearchProps, Omit<IconButtonProps, 'onChange'> {}

interface Props extends CustomProps, DataGridProps {}

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    flex: 1;
    margin-bottom: 1rem;
  }

  .MuiTablePagination-selectIcon {
  }
  .MuiDataGrid-main {
    .MuiDataGrid-overlay {
      top: 60px !important;
    }
  }
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  &.MuiDataGrid-toolbarContainer {
    justify-content: space-between;
  }
`;

const ToolbarWrapper = styled.div``;

const QuickSearchToolbar = ({ value, clearSearch, onChange }: QuickSearchProps) => (
  <div>
    <TextField
      variant='outlined'
      value={value}
      onChange={onChange}
      placeholder='Search…'
      size='small'
      InputProps={{
        startAdornment: <MdSearch fontSize='1.5rem' />,
        endAdornment: (
          <IconButton
            title='Clear'
            aria-label='Clear'
            size='small'
            style={{ visibility: value ? 'visible' : 'hidden' }}
            onClick={clearSearch}
          >
            <MdClear fontSize='1.5rem' />
          </IconButton>
        ),
      }}
    />
  </div>
);

const CustomToolbar = (props) => (
  <StyledGridToolbarContainer>
    <ToolbarWrapper>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          delimiter: ';',
          utf8WithBom: true,
        }}
        printOptions={{
          disableToolbarButton: true,
        }}
      />
    </ToolbarWrapper>
    <QuickSearchToolbar {...props} />
  </StyledGridToolbarContainer>
);

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const AppDataGrid: React.FC<Props> = ({
  rows: rowsData,
  columns: columnsData,
  toolbar = true,
  isLoading,
}: Props) => {
  const [pageSize, setPageSize] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(rowsData);

  useEffect(() => {
    setRows(rowsData);
  }, [rowsData]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rowsData?.filter((user) =>
      Object.keys(user)?.some((field) => searchRegex?.test(user?.[field]?.toString()))
    );
    setRows(filteredRows);
  };

  return (
    <StyledPaper elevation={0}>
      <DataGrid
        rows={rows || []}
        columns={columnsData}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        disableSelectionOnClick
        density='compact'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(toolbar && {
          components: { Toolbar: CustomToolbar },
          componentsProps: {
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          },
        })}
        loading={isLoading}
        autoHeight
      />
    </StyledPaper>
  );
};

export default AppDataGrid;
