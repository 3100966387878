import { useContext } from 'react';

import { AppBackdrop } from '@horse-auction/common/components';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import useBids from '@horse-auction/common/hooks/useBids';
import BidAPI from 'src/api/BidAPI';
import BidsListLarge from 'src/components/BiddingConsole/BidsListLarge';
import TvAuctionImage from 'src/components/TvAuctionImage/TvAuctionImage';
import TvHeader from 'src/components/TvHeader/TvHeader';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
`;

const BidsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const AuctionTvBidsPage = () => {
  document.body.style.overflow = 'hidden';
  const { user } = useContext(AuthContext);
  const { auction, auctionHttpState, currentLot } = useContext(AuctionContext);

  const { bids, bidsWithCanceled, highestBidStatus, numberOfBids, numberOfBidders, bidsHttpState } =
    useBids(BidAPI, currentLot?._id, 'CUSTOMER');

  if (auction?.status !== 'IN_PROGRESS') {
    return <TvAuctionImage imageKey={auction?.logo?.key} />;
  }

  if (!currentLot || !auction?.type) {
    return null;
  }

  return (
    <Container>
      <AppBackdrop isLoading={auctionHttpState?.isLoading}>
        <TvHeader />
        <BidsContainer>
          <BidsListLarge
            userId={user?._id || ''}
            bids={bids}
            bidsWithCanceled={bidsWithCanceled}
            auctionType={auction?.type}
            numberOfBids={numberOfBids}
            numberOfBidders={numberOfBidders}
            highestBidStatus={highestBidStatus}
            lot={currentLot}
            isLoading={bidsHttpState?.isLoading}
          />
        </BidsContainer>
      </AppBackdrop>
    </Container>
  );
};

export default AuctionTvBidsPage;
