import { useState, useCallback } from 'react';

import { DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from '@horse-auction/common/components/ConfirmationDialog/ConfirmationDialog';
import FileDto from '@horse-auction/common/types/file.dto';
import { IconButton, Modal, Paper } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components/macro';

import AppTypography from '../AppTypography/AppTypography';
import { CustomProps as ConfirmationDialogCustomProps } from '../ConfirmationDialog/ConfirmationDialog';

interface CustomProps {
  src: string;
  provided?: DraggableProvided;
  image: FileDto;
  innerRef?: (element: HTMLElement | null) => any;
  dialogOpen?: boolean;
  onDelete?: () => void;
}

interface Props
  extends CustomProps,
    Omit<ConfirmationDialogCustomProps, 'description' | 'onConfirm'> {}

const Container = styled.div<any>`
  width: 150px;
  min-height: 150px;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

const StyledImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
`;

const PreviewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewImage = styled.img`
  object-fit: contain;
  max-width: 70vw;
  max-height: 70vh;
`;

const StyledAppPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 0.5rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    display: flex;
  }
`;

const ImageMiniature: React.FC<Props> = ({ src, provided, image, innerRef, onDelete }: Props) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { t } = useTranslation(['components']);

  const openPreviewHandler = useCallback(() => {
    setPreviewOpen(true);
  }, []);

  const closePreviewHandler = useCallback(() => {
    setPreviewOpen(false);
  }, []);

  const deleteIconClickHandler = () => {
    setDeleteDialogOpen(true);
  };

  const cancelDeleteHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteImageHandler = () => {
    onDelete?.();
    setDeleteDialogOpen(false);
  };

  if (!image) {
    return null;
  }

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {/* eslint-disable-next-line react/prop-types */}
      <Container ref={innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
        <StyledAppPaper elevation={0} variant='outlined'>
          <StyledImage src={src} alt={image?.description || ''} onClick={openPreviewHandler} />
          <TitleWrapper>
            <AppTypography variant='body1'>{image?.name}</AppTypography>
            {onDelete && (
              <StyledIconButton
                edge='end'
                aria-label='account of current user'
                aria-haspopup='true'
                onClick={deleteIconClickHandler}
                color='inherit'
              >
                <MdDelete />
              </StyledIconButton>
            )}
          </TitleWrapper>
          <ConfirmationDialog
            open={deleteDialogOpen}
            title={t<string>('components:imageMiniature.deleteDialogTitle')}
            description={t<string>('components:imageMiniature.deleteDialogDescription')}
            onConfirm={deleteImageHandler}
            onCancel={cancelDeleteHandler}
          />
        </StyledAppPaper>
      </Container>
      <Modal
        open={previewOpen}
        onClose={closePreviewHandler}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <PreviewContainer onClick={closePreviewHandler}>
          <PreviewImage src={src} alt={image?.description || ''} />
        </PreviewContainer>
      </Modal>
    </>
  );
};

export default ImageMiniature;
