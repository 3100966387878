import { useEffect, useReducer, useCallback, useRef } from 'react';

import { TCancellablePromise } from '@horse-auction/common/utils/CancelablePromise';

interface HttpState {
  data: any;
  error: any;
  initialized: boolean;
  isLoading: boolean;
  isError: boolean;
  fetched: boolean;
}

const httpReducer = (state: HttpState, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        initialized: true,
        isLoading: true,
        isError: false,
      } as HttpState;
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        fetched: true,
        data: action.payload,
      } as HttpState;
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        fetched: true,
        error: action.error,
      } as HttpState;
    case 'FETCH_CLEAR':
      return {
        isLoading: false,
        isError: false,
        initialized: false,
        fetched: false, // has been fetched at least once no matter the result
        data: null,
        error: null,
      } as HttpState;
    default:
      throw new Error();
  }
};

// TODO: ts check if ok
const useHttp = (initialData?: any) => {
  const promises = useRef<TCancellablePromise[]>();
  const isMounted = useRef<boolean>();

  const [state, dispatch] = useReducer(httpReducer, {
    isLoading: false,
    isError: false,
    initialized: false,
    fetched: false, // has been fetched at least once no matter the result
    data: initialData,
    error: null,
  });

  useEffect(() => {
    isMounted.current = true;
    promises.current = promises.current || [];
    return () => {
      isMounted.current = false;
      promises.current?.forEach((p) => p?.cancel());
      promises.current = [];
    };
  }, []);

  const fetchData = useCallback((requestCall) => {
    const didCancel = false;
    if (isMounted.current) {
      dispatch({ type: 'FETCH_INIT' });
      const cancellablePromise = requestCall();
      promises.current?.push(cancellablePromise);
      return cancellablePromise.promise
        .then((result) => {
          if (isMounted.current && !didCancel) {
            const payload = result?.data ? result.data : result;
            dispatch({ type: 'FETCH_SUCCESS', payload });
            return payload;
          }
          return null;
        })
        .catch((error) => {
          if (isMounted.current && !didCancel) {
            dispatch({ type: 'FETCH_FAILURE', error });
          }
          return Promise.reject(error);
        });
    }
    return null;
  }, []);

  const clearState = useCallback(() => {
    dispatch({ type: 'FETCH_CLEAR' });
  }, []);

  return [state, fetchData, clearState] as [HttpState, (requestCall: any) => any, () => undefined];
};

export default useHttp;
