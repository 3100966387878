import { useEffect, useCallback, useContext, useState } from 'react';

import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import IEventObject from '@horse-auction/common/types/IEventObject';
import UserDto from '@horse-auction/common/types/user.dto';
import UserAuctionAPI from 'src/api/UserAuctionAPI';

const useModeratorUsers = (auctionId: string) => {
  const [eventObject, setEventObject] = useState<IEventObject>();

  const [customers, setCustomers] = useState([]);
  const [customersState, customersRequest] = useHttp([]);

  const [blockUserHttpState, blockUserRequest] = useHttp(null);
  const [unblockUserHttpState, unblockUserRequest] = useHttp(null);

  const { socket } = useContext(SocketContext);
  const { subscribeEvent, unsubscribeEvent } = useContext(SocketContext);

  const updateCustomers = useCallback((usersArray) => {
    const usersWithId = usersArray?.map((user: UserDto) => ({ ...user, id: user?._id }));
    setCustomers(usersWithId);
  }, []);

  const getCustomers = useCallback(() => {
    customersRequest(() => UserAuctionAPI.getAuctionCustomers(auctionId)).then(
      (usersArray: UserDto[]) => {
        updateCustomers(usersArray);
      }
    );
  }, [customersRequest, auctionId, updateCustomers]);

  useEffect(() => {
    getCustomers();

    return () => {
      socket?.off('users', getCustomers);
    };
  }, [getCustomers, socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket?.on('users', getCustomers);
  }, [socket, getCustomers]);

  const blockUser = useCallback(
    (userId) => {
      blockUserRequest(() => UserAuctionAPI.blockAuctionCustomer(userId, auctionId));
    },
    [blockUserRequest, auctionId]
  );

  const unblockUser = useCallback(
    (userId) => {
      unblockUserRequest(() => UserAuctionAPI.unblockAuctionCustomer(userId, auctionId));
    },
    [unblockUserRequest, auctionId]
  );

  useEffect(() => {
    if (eventObject?.auctionId !== auctionId) {
      unsubscribeEvent(eventObject, updateCustomers);
      const eventName = 'auction-users-update';
      const eventKey = `auction-users-update-${auctionId}`;
      const newEventObject = { name: eventName, type: 'AUCTION_USERS', auctionId, key: eventKey };
      setEventObject(newEventObject);
      subscribeEvent(newEventObject, updateCustomers);
    }
    return () => {
      unsubscribeEvent(eventObject, updateCustomers);
    };
  }, [unsubscribeEvent, subscribeEvent, updateCustomers, eventObject, auctionId]);

  return {
    customersState,
    customers,
    blockUser,
    unblockUser,
  };
};

export default useModeratorUsers;
