import { useEffect, useCallback, useState, useContext } from 'react';

import axios, { AxiosRequestConfig } from 'axios';

import { AuthAPI } from '@horse-auction/common/api';
import { SocketContext } from '@horse-auction/common/context/SocketContext';

import useHttp from './useHttp';
import useUrlQuery from './useUrlQuery';

interface CustomRefreshTokensParams {
  authRefresh: boolean;
}

type RefreshTokensParams = CustomRefreshTokensParams & AxiosRequestConfig;

const useAuthTokens = (updateUser) => {
  const token = useUrlQuery('token');
  const { socket, browserSessionId } = useContext(SocketContext);

  const [accessToken, setAccessToken] = useState(null);
  const [accessTokenLoaded, setAccessTokenLoaded] = useState(false);
  const [magicLinkLoginHttpState, magicLinkLoginRequest] = useHttp(null);

  const refreshTokens = useCallback(() => {
    const url = '/auth/refresh-tokens';

    return axios
      .get(url, {
        authRefresh: true,
        credentials: 'include',
        withCredentials: true,
      } as RefreshTokensParams)
      .then((res) => {
        if (res.status === 200 && res?.data?.access && res?.data?.user) {
          const JWT = res?.data?.access?.token;
          const user = res?.data?.user;
          axios.defaults.headers.common.Authorization = `Bearer ${JWT} }), `;
          return Promise.resolve({ user, JWT });
        }
        axios.defaults.headers.common.Authorization = false;
        return Promise.reject();
      });
  }, []);

  const interceptExpiredTokens = useCallback(
    () =>
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          const originalRequest = error?.config;
          if (
            error?.response?.status === 401 &&
            !originalRequest.retry &&
            !originalRequest.authRefresh
          ) {
            return refreshTokens()
              .then(({ user, JWT }) => {
                originalRequest.retry = true;
                originalRequest.headers.Authorization = `Bearer ${JWT} }), `;
                setAccessToken(JWT);
                updateUser(user);
                return axios(originalRequest);
              })
              .catch(() => Promise.reject(error));
          }
          return Promise.reject(error);
        }
      ),
    [refreshTokens, updateUser]
  );

  const performRefreshTokens = useCallback(
    () =>
      refreshTokens()
        .then((response) => {
          const { user, JWT } = response;
          setAccessToken(JWT);
          updateUser(user);
          return JWT;
        })
        .catch(() => {
          setAccessToken(null);
          updateUser(null);
          return null;
        })
        .finally(() => {
          setAccessTokenLoaded(true);
        }),
    [refreshTokens, updateUser]
  );

  const verifyMagicLink = useCallback(
    () =>
      magicLinkLoginRequest(() => AuthAPI.magicLinkLogin(token))
        .then((response) => {
          const user = response?.user;
          const JWT = response?.access?.token;
          updateUser(user);
          setAccessToken(JWT);
          axios.defaults.headers.common.Authorization = `Bearer ${JWT} }), `;
          socket?.emit('newLogin', browserSessionId);
          return response.access.token;
        })
        .finally(() => {
          setAccessTokenLoaded(true);
          return Promise.resolve();
        }),
    [magicLinkLoginRequest, token, updateUser, socket, browserSessionId]
  );

  useEffect(() => {
    if (!accessTokenLoaded) {
      if (token) {
        verifyMagicLink();
      } else {
        performRefreshTokens();
      }
    }
  }, [interceptExpiredTokens, performRefreshTokens, token, accessTokenLoaded, verifyMagicLink]);

  useEffect(() => {
    interceptExpiredTokens();
  }, [interceptExpiredTokens]);

  return { accessToken, setAccessToken, accessTokenLoaded, performRefreshTokens };
};

export default useAuthTokens;
