import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AppButton } from '@horse-auction/common/components';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import { Backdrop, CircularProgress } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styled from 'styled-components/macro';

const { REACT_APP_WEB_URL } = process.env;
interface Props {
  auction?: AuctionDto;
  onCancel?: () => void;
}

const FormWrapper = styled.form`
  position: relative;
`;

const IFrameLoadingContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
`;

const CancelButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const StripePaymentIntentIFrame = ({ auction, onCancel }: Props) => {
  const [iFrameLoading, setIFrameLoading] = useState(true);
  const [paymentInitialized, setPaymentInitialized] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();

  const handlePaymentConfirmation = useCallback(async () => {
    if (stripe && elements) {
      setPaymentInitialized(true);
      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${REACT_APP_WEB_URL}/auctions/${auction?._id}`,
        },
      });
      if (result?.error) {
        // Show error to your customer (for example, payment details incomplete)
        setPaymentInitialized(false);
      }
    }
  }, [auction?._id, elements, stripe]);

  return (
    <FormWrapper>
      {iFrameLoading && <IFrameLoadingContainer>Payment detals loading</IFrameLoadingContainer>}
      <PaymentElement onReady={() => setIFrameLoading(false)} />
      <Backdrop
        sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={iFrameLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <ButtonsWrapper>
        <AppButton fullWidth isLoading={paymentInitialized} onClick={handlePaymentConfirmation}>
          {t<string>('components:paymentContent.payNowButton')}
        </AppButton>
        <CancelButtonWrapper>
          <AppButton variant='outlined' fullWidth onClick={onCancel}>
            {t<string>('components:paymentContent.cancelButton')}
          </AppButton>
        </CancelButtonWrapper>
      </ButtonsWrapper>
    </FormWrapper>
  );
};

export default StripePaymentIntentIFrame;
