import { httpGet, httpPost } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const logout = () => CancellablePromise(httpGet('/auth/logout'));

const magicLinkLogin = (token) => {
  const payload = {
    token,
  };

  return CancellablePromise(httpPost(`/auth/login/magic`, payload));
};

const AuthAPI = {
  logout,
  magicLinkLogin,
};

export default AuthAPI;
