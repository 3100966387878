import { useEffect, useCallback, useContext, useState } from 'react';

import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import PurchaseDto from '@horse-auction/common/types/purchase.dto';
import PurchaseAPI from 'src/api/PurchaseAPI';

const useCustomerPurchases = () => {
  const [purchases, setPurchases] = useState<PurchaseDto[] | null>(null);
  const [purchasesHttpState, purchasesRequest, clearState] = useHttp([]);
  const { socket, socketId } = useContext(SocketContext);
  const { user, accessToken } = useContext(AuthContext);

  const clearCustomerPurchases = useCallback(() => {
    setPurchases(null);
    clearState();
  }, [clearState]);

  const getPurchases = useCallback(() => {
    if (accessToken) {
      return purchasesRequest(() => PurchaseAPI.getCustomerPurchases());
    }
    return Promise.resolve([]);
  }, [purchasesRequest, accessToken]);

  const updatePurchases = useCallback((purchasesArray: PurchaseDto[]) => {
    const purchasesWithId = purchasesArray.map((purchase: PurchaseDto) => ({
      ...purchase,
      id: purchase?._id,
    }));
    setPurchases(purchasesWithId);
  }, []);

  useEffect(() => {
    if (user?.role === 'CUSTOMER') {
      getPurchases().then(updatePurchases);
    }
  }, [getPurchases, updatePurchases, user?.role]);

  useEffect(() => {
    if (socket && user?.role === 'CUSTOMER') {
      socket?.on('purchases-change', updatePurchases);
    }

    return () => {
      socket?.off('purchases-change', updatePurchases);
    };
  }, [socket, updatePurchases, user?.role, socketId]);

  return {
    purchasesHttpState,
    purchases,
    clearCustomerPurchases,
  };
};

export default useCustomerPurchases;
