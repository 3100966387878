import FieldObject from '@horse-auction/common/types/FieldObject';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import styled from 'styled-components/macro';

type CustomProps = {
  helperText?: string;
  label?: string | React.ReactElement;
  fieldObj?: FieldObject;
  error?: any;
  onChange?: (checked: boolean) => void;
};

export interface Props extends CustomProps, Omit<CheckboxProps, 'onChange'> {}

const Container = styled.div`
  .Mui-error .MuiSvgIcon-root {
    color: #e57373;
  }

  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 14px;
    margin-right: 14px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;

    .MuiButtonBase-root {
      align-self: flex-start;
    }
  }
`;

const AppCheckbox = ({
  name = '',
  helperText = '',
  color = 'primary',
  error = false,
  label = '',
  fieldObj,
  onChange,
}: Props) => {
  const changeHandler = (event, value) => {
    onChange?.(value);
    fieldObj?.onChange?.(value);
  };

  return (
    <Container>
      <FormControl error={error} component='label'>
        <FormLabel component='legend'>
          <StyledFormControlLabel
            control={<Checkbox onChange={changeHandler} name={name} color={color} />}
            label={label}
          />
        </FormLabel>
      </FormControl>
      {error && <StyledFormHelperText error>{helperText}</StyledFormHelperText>}
    </Container>
  );
};

export default AppCheckbox;
