import { useCallback } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import LabeledData from '@horse-auction/common/components/LabeledData/LabeledData';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Alert } from '@mui/material';
import styled from 'styled-components/macro';

import 'src/locales/i18n';

interface LotDescriptionProps {
  lot: LotDto;
  lotsQuantity: number;
  onClick?: () => void;
  noOrderRow: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const AlertWrapper = styled.div`
  margin-bottom: 1rem;
`;

const LotDescription = ({
  lot,
  lotsQuantity = 0,
  noOrderRow = false,
  onClick,
}: LotDescriptionProps) => {
  const { t } = useTranslation(['auction']);

  const getStartingPrice = useCallback(() => {
    const startingPrice = lot?.startingPrice || lot?.auctionStartingPrice;
    if (!startingPrice) {
      return '-';
    }
    return `${lot?.auctionCurrency} ${startingPrice}`;
  }, [lot]);

  return (
    <Container>
      {lot?.currentBidSession?.cancelReason && (
        <AlertWrapper>
          <Alert severity='info' variant='outlined'>
            {`${t<string>('auction:lotDescription.cancelReason')}: ${
              lot.currentBidSession.cancelReason
            }`}
          </Alert>
        </AlertWrapper>
      )}
      <LabeledData
        label={t<string>('auction:lotDescription.startingPrice')}
        value={getStartingPrice()}
      />
      <LabeledData
        label={t<string>('auction:lotDescription.gender')}
        value={lot?.product?.gender}
      />
      <LabeledData label={t<string>('auction:lotDescription.breed')} value={lot?.product?.breed} />
      <LabeledData label={t<string>('auction:lotDescription.color')} value={lot?.product?.color} />
      <LabeledData
        label={t<string>('auction:lotDescription.born')}
        value={lot?.product?.birthDate && format(new Date(lot?.product?.birthDate), 'dd MMMM yyyy')}
      />
      {lot?.product?.lifeId && (
        <LabeledData
          label={t<string>('auction:lotDescription.lifeId')}
          value={lot.product.lifeId}
        />
      )}
    </Container>
  );
};

export default LotDescription;
