import { useState, useCallback, createContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Alert, AlertColor, Snackbar } from '@mui/material';
import styled from 'styled-components/macro';

interface AlertContextProps {
  children: React.ReactNode;
}

type IAlertContext = {
  createAlert: (message: string, severity?: AlertColor) => void;
  createAPIErrorAlert: (error: any) => void;
};

const initialAlertContext = {
  createAlert: (message, severity) => undefined,
  createAPIErrorAlert: (error) => undefined,
};

const AlertContext = createContext<IAlertContext>(initialAlertContext);

const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-anchorOriginTopCenter {
    top: 70px;
  }
`;

const AlertObjectProvider = ({ children }: AlertContextProps) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success');
  const { t } = useTranslation(['notificaton']);

  const snackbarCloseHandler = useCallback(() => {
    setAlertMessage('');
  }, []);

  const createAlert = useCallback((message, severity = 'success') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
  }, []);

  const createAPIErrorAlert = useCallback(
    (error) => {
      if (error?.response?.data?.name) {
        const message = t(`alert:apiError.${error.response.data.name}`);
        createAlert(message, 'error');
      }
    },
    [createAlert, t]
  );

  const alertContextValue = useMemo(
    () => ({
      createAlert,
      createAPIErrorAlert,
    }),
    [createAPIErrorAlert, createAlert]
  );

  return (
    <>
      <AlertContext.Provider value={alertContextValue}>{children}</AlertContext.Provider>
      <StyledSnackbar
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={snackbarCloseHandler}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={alertSeverity} onClose={snackbarCloseHandler}>
          {alertMessage}
        </Alert>
      </StyledSnackbar>
    </>
  );
};

export { AlertContext, AlertObjectProvider as AlertProvider };
