import styled from 'styled-components/macro';
import { PropsWithChildren } from 'react';

type CustomProps = PropsWithChildren & {
  itemPadding?: string;
};

const LabeledDataContainer = styled.div<CustomProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  & > div {
    width: 100%;
    padding-left: 0.5rem;

    ${({ itemPadding }) => {
      if (itemPadding) {
        return `padding: ${itemPadding};`;
      }
      return '';
    }}
  }

  & > div:nth-child(odd) {
    background-color: ${({ theme }) => {
      if (theme.name === 'dark') {
        return 'rgba(0, 0, 0, 0.4)';
      }
      return 'rgba(0, 0, 0, 0.1)';
    }};
  }
`;

export default LabeledDataContainer;
