import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useUrlQuery from '@horse-auction/common/hooks/useUrlQuery';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import LotDto from '@horse-auction/common/types/lot.dto';
import NotificationDto from '@horse-auction/common/types/notification.dto';
import UserAuctionDto from '@horse-auction/common/types/userAuction.dto';
import UserOrganizationDto from '@horse-auction/common/types/userOrganization.dto';
import LotNotificationDialog from 'src/components/LotNotificationDialog/LotNotificationDialog';

import { NotificationsContext } from './NotificationsContext';
import { PurchasesContext } from './PurchasesContext';
import useAuction from '../hooks/useAuction';
import useEntranceDepositPayment from '../hooks/useEntranceDepositPayment';
import useUserAuction from '../hooks/useUserAuction';
import useUserOrganization from '../hooks/useUserOrganization';

interface ContextProps {
  children: React.ReactNode;
}

interface UrlParams {
  auctionId: string;
}

type IAuctionContext = {
  auction?: AuctionDto;
  auctionHttpState?: any;
  previousLot?: LotDto;
  currentLot?: LotDto;
  nextLot?: LotDto;
  acceptAuctionTerms?: () => void;
  acceptAuctionTermsHttpState?: any;
  userAuction?: UserAuctionDto;
  userAuctionHttpState?: any;
  userOrganization?: UserOrganizationDto;
  userOrganizationHttpState?: any;
  customerEntranceDepositRequired?: boolean;
  unreadLotWinNotifications?: NotificationDto[];
  previewToken?: string;
  stripePaymentIntentClientSecret?: string;
  createDepositPaymentState?: any;
};

const AuctionContext = createContext<IAuctionContext>({});

const AuctionProvider = ({ children }: ContextProps) => {
  const { auctionId } = useParams<UrlParams>();
  const previewToken = useUrlQuery('preview');
  const [unreadLotWinNotifications, setUnreadLotWinNotifications] = useState<NotificationDto[]>([]);

  const { purchases, getEntranceDepositPaid } = useContext(PurchasesContext);

  const { notifications, markNotificationsAsRead, markNotificationAsReadHttpState } =
    useContext(NotificationsContext);

  const { auction, auctionHttpState, previousLot, currentLot, nextLot } = useAuction(
    auctionId,
    'CUSTOMER',
    previewToken || ''
  );

  const { createDepositPaymentState, stripePaymentIntentClientSecret } = useEntranceDepositPayment(
    auctionId,
    !!auction?.entranceDepositRequired,
    auction?.status
  );

  const { acceptAuctionTerms, acceptAuctionTermsHttpState, userAuction, userAuctionHttpState } =
    useUserAuction();

  const { userOrganization, userOrganizationHttpState } = useUserOrganization();

  const [customerEntranceDepositRequired, setCustomerEntranceDepositRequired] = useState(
    auction?.entranceAmountRequired && auction?.entranceAmount > 0
  );

  useEffect(() => {
    const lotWinNotifications = notifications?.filter(
      (notification: NotificationDto) =>
        notification?.type === 'LOT_WIN' &&
        notification?.read === false &&
        notification?.auctionId === auctionId
    );
    if (lotWinNotifications) {
      setUnreadLotWinNotifications(lotWinNotifications);
    }
  }, [notifications, auctionId]);

  useEffect(() => {
    let isCustomerEntranceDepositRequired =
      auction?.entranceAmountRequired && auction?.entranceAmount > 0;
    if (isCustomerEntranceDepositRequired && auction?._id) {
      const isCustomerEntranceDepositPaid = getEntranceDepositPaid(auction?._id);
      isCustomerEntranceDepositRequired = !isCustomerEntranceDepositPaid;
    }
    setCustomerEntranceDepositRequired(!!isCustomerEntranceDepositRequired);
  }, [purchases, getEntranceDepositPaid, auction]);

  const dialogCloseHandler = useCallback(() => {
    const notificationIds = unreadLotWinNotifications?.map((notification) => notification?._id);
    if (markNotificationsAsRead) {
      markNotificationsAsRead(notificationIds);
    }
  }, [markNotificationsAsRead, unreadLotWinNotifications]);

  return (
    <>
      <AuctionContext.Provider
        value={{
          auction,
          auctionHttpState,
          previousLot,
          currentLot,
          nextLot,
          acceptAuctionTerms,
          acceptAuctionTermsHttpState,
          userAuction,
          userAuctionHttpState,
          userOrganization,
          userOrganizationHttpState,
          customerEntranceDepositRequired,
          unreadLotWinNotifications,
          previewToken,
          stripePaymentIntentClientSecret,
          createDepositPaymentState,
        }}
      >
        {children}
      </AuctionContext.Provider>
      <LotNotificationDialog
        open={unreadLotWinNotifications?.length > 0}
        notifications={unreadLotWinNotifications}
        isLoading={markNotificationAsReadHttpState?.isLoading}
        onCancel={dialogCloseHandler}
      />
    </>
  );
};

export { AuctionContext, AuctionProvider };
