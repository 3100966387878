import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppBackdrop } from '@horse-auction/common/components';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import useBids from '@horse-auction/common/hooks/useBids';
import BidAPI from 'src/api/BidAPI';
import BidsListLarge from 'src/components/BiddingConsole/BidsListLarge';
import TvAuctionImage from 'src/components/TvAuctionImage/TvAuctionImage';
import TvHeader from 'src/components/TvHeader/TvHeader';
import { AuctionContext } from 'src/context/AuctionContext';
import LotMedia from 'src/pages/lot/components/LotMedia';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
`;

const StreamContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'video video bids';
  align-items: center;
`;

const VideoWrapper = styled.div`
  grid-area: video;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const BidsWrapper = styled.div`
  grid-area: bids;
  align-self: center;
`;

const AuctionTvStreamPage = () => {
  document.body.style.overflow = 'hidden';
  const { user } = useContext(AuthContext);
  const { auction, auctionHttpState, currentLot, nextLot } = useContext(AuctionContext);

  const { bids, bidsWithCanceled, highestBidStatus, numberOfBids, numberOfBidders, bidsHttpState } =
    useBids(BidAPI, currentLot?._id, 'CUSTOMER');

  const { t } = useTranslation(['bid']);

  if (!auction) {
    return null;
  }

  if (auction?.status !== 'IN_PROGRESS') {
    return <TvAuctionImage imageKey={auction?.logo?.key} />;
  }

  return (
    <Container>
      <AppBackdrop isLoading={auctionHttpState?.isLoading}>
        <TvHeader />
        <StreamContainer>
          <VideoWrapper>
            <LotMedia
              url={auction?.streamUrl}
              available={(auction?.streamUrl && auction?.status === 'IN_PROGRESS') || false}
            />
          </VideoWrapper>
          {currentLot && bids && (
            <BidsWrapper>
              <BidsListLarge
                userId={user?._id || ''}
                bids={bids}
                bidsWithCanceled={bidsWithCanceled}
                auctionType={auction?.type}
                numberOfBids={numberOfBids}
                numberOfBidders={numberOfBidders}
                highestBidStatus={highestBidStatus}
                lot={currentLot}
                isLoading={bidsHttpState?.isLoading}
                size='medium'
              />
            </BidsWrapper>
          )}
        </StreamContainer>
      </AppBackdrop>
    </Container>
  );
};

export default AuctionTvStreamPage;
