import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import TextDivider from '@horse-auction/common/components/TextDivider/TextDivider.style';
import { device } from '@horse-auction/common/themes/device';
import { Paper } from '@mui/material';
import styled from 'styled-components/macro';

import LoginForm from './components/LoginForm';

interface LocationState {
  sourcePath: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;

  @media ${device.tablet} {
    padding-top: 2rem;
  }

  @media ${device.mobile} {
    padding-top: 1rem;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiButton-root {
    margin-top: 1rem;
  }

  @media ${device.mobile} {
    padding: 1rem;
    width: unset;
    margin: 1rem;
  }
`;

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { t } = useTranslation('auth');

  const signUpButtonClickHandler = () => {
    history.push('/signup');
  };

  const forgotPasswordButtonClickHandler = () => {
    history.push('/forgot-password');
  };

  return (
    <Container>
      <AppTypography variant='h4' align='center' gutterBottom>
        {t<string>('auth:login.title')}
      </AppTypography>
      <StyledPaper>
        <>
          <LoginForm redirectPath={location.state?.sourcePath} />
          <AppButton size='large' variant='text' onClick={forgotPasswordButtonClickHandler}>
            {t<string>('auth:login.forgotPassword')}
          </AppButton>
          <TextDivider>{t<string>('auth:login.textDivider')}</TextDivider>
          <AppButton size='large' variant='outlined' onClick={signUpButtonClickHandler}>
            {t<string>('auth:login.createAccount')}
          </AppButton>
        </>
      </StyledPaper>
    </Container>
  );
};

export default LoginPage;
