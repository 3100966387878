import styled from 'styled-components/macro';

interface CustomProps {
  index: number;
  selectedTab: number;
  children: React.ReactNode;
}

interface TabWrapperProps {
  visible: boolean;
}

const TabWrapper = styled.div<TabWrapperProps>`
  display: ${({ visible }) => (visible ? 'inherit' : 'none')};
`;

const TabContainer = ({ index, selectedTab, children }: CustomProps) => (
  <TabWrapper visible={index === selectedTab}>{children}</TabWrapper>
);

export default TabContainer;
