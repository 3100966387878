import { useCallback, useEffect, useState } from 'react';

import { differenceInSeconds } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

import genericHorse from '@horse-auction/common/assets/images/equibidLogo.png';
import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import LotChip from '@horse-auction/common/components/LotChip/LotChip';
import StyledLink from '@horse-auction/common/components/StyledLink/StyledLink';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { orange } from '@mui/material/colors';
import styled from 'styled-components/macro';

import 'src/locales/i18n';

const { REACT_APP_SERVER_URL } = process.env;

interface Props {
  lot: LotDto;
  lotIndex?: number;
  lotsQuantity?: number;
  size?: string;
  previewToken?: string;
  active?: boolean;
  onClick?: () => void;
}

interface CardStylesProps {
  highlighted: boolean;
}

const BACKGROUND_ANIMATION_IN_SECONDS = 10;

const cardStyles = css<CardStylesProps>`
  cursor: pointer;
  position: relative;

  &.MuiCard-root {
    margin: 0 0.25rem 0.25rem 0.25rem;
    display: grid;
    width: 100%;
    transition: transform 0.4s;

    ${({ highlighted }) => {
      if (highlighted) {
        return `
        animation: fade-card ${BACKGROUND_ANIMATION_IN_SECONDS}s forwards;
    `;
      }
      return '';
    }};

    &:hover {
      transform: scale(1.03);
    }

    @keyframes fade-card {
      from {
        background-color: ${orange[400]};
      }
      to {
        background-color: none;
      }
    }
  }
`;

const StyledLinkWrapper = styled(StyledLink)`
  width: 100%;
`;

const StyledCard = styled(Card).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['highlighted'].includes(prop) && defaultValidatorFn(prop),
})<CardStylesProps>`
  ${cardStyles}
  display: grid;
  grid-template-columns: 6rem 200px;
`;

const StyledCardSmall = styled(Card).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['highlighted'].includes(prop) && defaultValidatorFn(prop),
})<CardStylesProps>`
  ${cardStyles}

  &.MuiCard-root {
    display: flex;
    height: 4rem;
    margin: 0 0 0.25rem 0;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const HorseNameTypography = styled(AppTypography)`
  &.MuiTypography-root {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 0.5rem;
  }
`;

const StyledCardContentSmall = styled(CardContent)`
  &.MuiCardContent-root {
    width: 20%;
    padding: 0;
    display: flex;
    align-items: center;
    padding-left: 2px;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  &.MuiCardMedia-root {
    width: 6rem;
    height: 6rem;
  }
`;

const StyledCardMediaSmall = styled(CardMedia)`
  &.MuiCardMedia-root {
    height: 6rem;
    width: 80%;
    max-height: 4rem;
  }
`;

const InactiveLayer = styled.div`
  background-color: ${({ theme }) => theme.colors.body};
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LotsNavCard = ({
  lot,
  lotIndex = 0,
  lotsQuantity = 0,
  active = false,
  size = 'medium',
  previewToken,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const [highlighted, setHighlighted] = useState(false);

  const getImagePath = (imageKey: string) =>
    imageKey ? `${REACT_APP_SERVER_URL}/api/v1/files/image-thumb/${imageKey}` : genericHorse;

  const getHighestBid = useCallback(() => {
    let startingPrice = '-';
    if (lot?.currentBid?.price) {
      startingPrice = `${lot?.auctionCurrency} ${lot?.currentBid?.price}`;
    }
    return `${t<string>('auction:lotListCard.highestBid')}: ${startingPrice}`;
  }, [lot, t]);

  const animationEndHandler = useCallback(() => {
    setHighlighted(false);
  }, []);

  const isHighlighted = useCallback((bidTime) => {
    if (!bidTime) {
      return false;
    }
    const bidDate = new Date(bidTime);
    const now = new Date();
    const secondsSinceBid = differenceInSeconds(now, bidDate);

    return secondsSinceBid < BACKGROUND_ANIMATION_IN_SECONDS;
  }, []);

  useEffect(() => {
    setHighlighted(isHighlighted(lot?.currentBid?.createdAt));
  }, [lot?.currentBid?.createdAt, lot, isHighlighted]);
  const props = {
    component: 'img',
    alt: 'Horse',
    height: '140',
    image: getImagePath(lot?.product?.images?.[0]?.key),
    title: 'Horse',
  };

  return (
    <StyledLinkWrapper
      to={
        previewToken
          ? `/auctions/${lot?.auctionId}/lots/${lot?._id}?preview=${previewToken}`
          : `/auctions/${lot?.auctionId}/lots/${lot?._id}`
      }
      noUnderline
    >
      {size === 'medium' && (
        <StyledCard highlighted={highlighted} onAnimationEnd={animationEndHandler}>
          <StyledCardMedia {...props} />
          <StyledCardContent>
            <CardHeader>
              <Box>{lotIndex && lotsQuantity ? `${lotIndex}/${lotsQuantity}` : ''}</Box>
              <LotChip status={lot.status} size='small' />
            </CardHeader>
            <HorseNameTypography gutterBottom variant='body1' component='p'>
              {lot?.product?.name}
            </HorseNameTypography>
            <AppTypography variant='body2' color='textSecondary' component='p'>
              {getHighestBid()}
            </AppTypography>
            {active !== true && <InactiveLayer />}
          </StyledCardContent>
        </StyledCard>
      )}
      {size === 'small' && (
        <StyledCardSmall highlighted={highlighted} onAnimationEnd={animationEndHandler}>
          <StyledCardContentSmall>
            <CardHeader>
              <Box>{lotIndex || ''}</Box>
            </CardHeader>
            {active !== true && <InactiveLayer />}
          </StyledCardContentSmall>
          <StyledCardMediaSmall {...props} />
        </StyledCardSmall>
      )}
    </StyledLinkWrapper>
  );
};

export default LotsNavCard;
