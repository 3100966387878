import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { AppTypography, EventTimer, EventTimeRow } from '@horse-auction/common/components';
import useCountries from '@horse-auction/common/hooks/useCountries';
import LotDto from '@horse-auction/common/types/lot.dto';
import {
  MdCancel,
  MdInfoOutline,
  MdOutlineSell,
  MdMoneyOff,
  MdHourglassTop,
  MdLiveTv,
  MdOutlineCalendarToday,
} from 'react-icons/md';
import styled from 'styled-components/macro';
import { IconButton, Tooltip } from '@mui/material';

interface OnlineInProgressProps {
  lot: LotDto;
  highestBidLabel: string;
  bidsQuantityLabel: string;
  restartReason?: string;
}

interface OnlineScheduledProps {
  lot: LotDto;
}

interface LotOutcomeRowProps {
  lot: LotDto;
  auctionType?: string;
  auctionStatus?: string;
  showTimers?: boolean;
}

const OutcomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

const CommonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LotInProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EventTimerWrapper = styled.div`
  width: 210px;
  align-self: center;
`;

const LotRestartedTooltip = ({ reason }: { reason: string }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={`${t<string>('components:lotOutcomeRow.lotRestartedTooltip')}: ${reason}`}>
      <IconButton size='small' color='primary'>
        <MdInfoOutline />
      </IconButton>
    </Tooltip>
  );
};

const HybridScheduledWithAuctionScheduled = () => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <MdOutlineCalendarToday size='1.5rem' />
      <AppTypography variant='body2' component='p' margin='0 0 0 0.5rem'>
        Awaiting auction start
      </AppTypography>
    </CommonContainer>
  );
};

const HybridScheduledWithAuctionInProgress = ({ restartReason }: { restartReason?: string }) => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <MdHourglassTop size='1.5rem' />
      <AppTypography variant='body2' component='p' margin='0 0 0 0.5rem'>
        Should start soon
      </AppTypography>
      {restartReason && <LotRestartedTooltip reason={restartReason} />}
    </CommonContainer>
  );
};

const HybridInProgress = ({ restartReason }: { restartReason?: string }) => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <MdLiveTv size='1.5rem' />
      <AppTypography variant='body2' component='p' margin='0  0  0 0.5rem'>
        Now Live
      </AppTypography>
      {restartReason && <LotRestartedTooltip reason={restartReason} />}
    </CommonContainer>
  );
};

const EndedSold: React.FC<OnlineScheduledProps> = ({ lot }: OnlineScheduledProps) => {
  const { t } = useTranslation();
  const { codeToCountryLabel } = useCountries();

  return (
    <CommonContainer>
      <MdOutlineSell size='1.5rem' />
      <AppTypography variant='body1' margin='0 0 0 0.5rem'>
        {t<string>('components:lotOutcomeRow.soldForBy', {
          amount: lot?.finalPrice,
          currency: lot?.auctionCurrency,
          buyerLabel: lot?.buyerInhouse ? 'In-house' : codeToCountryLabel(lot?.buyerCountry),
        })}
      </AppTypography>
    </CommonContainer>
  );
};

const EndedNotSold = () => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <MdMoneyOff size='1.5rem' />
      <AppTypography variant='body1' margin='0 0 0 0.5rem'>
        {t<string>('components:lotOutcomeRow.biddingEndedNotSold')}
      </AppTypography>
    </CommonContainer>
  );
};

const Canceled = ({ reason }: { reason?: string }) => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <MdCancel size='1.5rem' />
      <AppTypography variant='body1' margin='0 0 0 0.5rem'>
        {t<string>('components:lotOutcomeRow.biddingCanceled')}
        {reason && (
          <Tooltip title={reason}>
            <IconButton size='small' color='primary'>
              <MdInfoOutline />
            </IconButton>
          </Tooltip>
        )}
      </AppTypography>
    </CommonContainer>
  );
};

const OnlineScheduledBeforeStartDate: React.FC<OnlineScheduledProps> = ({
  lot,
}: OnlineScheduledProps) => {
  const { t } = useTranslation();

  return (
    <EventTimerWrapper>
      <EventTimer
        deadlineDate={lot?.startDate}
        topLabel={t<string>('components:lotOutcomeRow.startsIn')}
        size='small'
      />
    </EventTimerWrapper>
  );
};

const OnlineScheduledPastStartDate: React.FC<OnlineScheduledProps> = ({
  lot,
}: OnlineScheduledProps) => {
  const { t } = useTranslation();

  return (
    <EventTimerWrapper>
      <EventTimeRow eventDate={lot?.startDate} />
    </EventTimerWrapper>
  );
};

const OnlineInProgress: React.FC<OnlineInProgressProps> = ({
  lot,
  highestBidLabel,
  bidsQuantityLabel,
  restartReason,
}: OnlineInProgressProps) => {
  const { t } = useTranslation();

  return (
    <LotInProgressWrapper>
      <EventTimerWrapper>
        <EventTimer
          deadlineDate={lot?.endDate}
          topLabel={t<string>('components:lotOutcomeRow.endsIn')}
          size='small'
        />
      </EventTimerWrapper>
      <AppTypography variant='body2' component='p'>
        {highestBidLabel}
      </AppTypography>
      <AppTypography variant='body2' component='p'>
        {bidsQuantityLabel}
      </AppTypography>
      {restartReason && (
        <AppTypography variant='body2' component='p'>
          {t<string>('components:lotOutcomeRow.lotRestartedLabel')}{' '}
          <LotRestartedTooltip reason={restartReason} />
        </AppTypography>
      )}
    </LotInProgressWrapper>
  );
};

const LotOutcomeRow: React.FC<LotOutcomeRowProps> = ({
  lot,
  auctionType,
  auctionStatus,
  showTimers = true,
}: LotOutcomeRowProps) => {
  const { t } = useTranslation(['auctions']);

  const getHighestBid = useCallback(() => {
    let startingPrice = '-';
    if (lot?.currentBid?.price) {
      startingPrice = `${lot?.auctionCurrency} ${lot?.currentBid?.price}`;
    }
    return `${t<string>('components:lotOutcomeRow.highestBid')}: ${startingPrice}`;
  }, [lot, t]);

  const getBidsQuantity = useCallback(() => {
    if (lot?.bidsQuantity === parseInt(`${lot?.bidsQuantity}`, 10)) {
      return `${t<string>('components:lotOutcomeRow.numberOfBids')}: ${lot?.bidsQuantity}`;
    }
    return '';
  }, [lot, t]);

  const pastDate = useCallback((date) => new Date() > new Date(date), []);

  const getHybridLotStatusDescription = useCallback(() => {
    switch (lot?.status) {
      case 'SCHEDULED':
        if (auctionStatus && ['SCHEDULED'].includes(auctionStatus)) {
          return <HybridScheduledWithAuctionScheduled />;
        }
        if (auctionStatus && ['IN_PROGRESS'].includes(auctionStatus)) {
          return (
            <HybridScheduledWithAuctionInProgress
              restartReason={lot.previousBidSession?.restartReason}
            />
          );
        }
        return null;
      case 'UPCOMING':
        return (
          <HybridScheduledWithAuctionInProgress
            restartReason={lot.previousBidSession?.restartReason}
          />
        );
      case 'IN_PROGRESS':
        return <HybridInProgress restartReason={lot.previousBidSession?.restartReason} />;
      case 'ENDED':
        if (lot?.sold) {
          return <EndedSold lot={lot} />;
        }
        if (!lot?.sold) {
          return <EndedNotSold />;
        }
        return null;
      case 'CANCELED':
        return <Canceled reason={lot.currentBidSession?.cancelReason} />;

      default:
        return null;
    }
  }, [lot, auctionStatus]);

  const getOnlineLotStatusDescription = useCallback(() => {
    switch (lot?.status) {
      case 'SCHEDULED':
        if (showTimers && !pastDate(lot?.startDate)) {
          return <OnlineScheduledBeforeStartDate lot={lot} />;
        }
        if (showTimers && pastDate(lot?.startDate)) {
          return <OnlineScheduledPastStartDate lot={lot} />;
        }
        break;
      case 'IN_PROGRESS':
        if (lot?.endDate) {
          return (
            <OnlineInProgress
              highestBidLabel={getHighestBid()}
              lot={lot}
              bidsQuantityLabel={getBidsQuantity()}
              restartReason={lot.previousBidSession?.restartReason}
            />
          );
        }
        break;
      case 'ENDED':
        if (lot?.sold) {
          return <EndedSold lot={lot} />;
        }
        if (!lot?.sold) {
          return <EndedNotSold />;
        }
        break;
      case 'CANCELED':
        return <Canceled reason={lot.currentBidSession?.cancelReason} />;

      default:
        break;
    }
    return '';
  }, [lot, pastDate, showTimers, getHighestBid, getBidsQuantity]);

  const getLotStatusDescription = useCallback(() => {
    if (auctionType && ['HYBRID'].includes(auctionType)) {
      return getHybridLotStatusDescription();
    }
    if (auctionType && ['ONLINE'].includes(auctionType)) {
      return getOnlineLotStatusDescription();
    }
    return '';
  }, [auctionType, getHybridLotStatusDescription, getOnlineLotStatusDescription]);

  return <OutcomeWrapper>{getLotStatusDescription()}</OutcomeWrapper>;
};

export default LotOutcomeRow;
