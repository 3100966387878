import { Controller, useFormContext } from 'react-hook-form';

import AppNumberField, { Props as NumberFieldProps } from '../../AppNumberField/AppNumberField';

type CustomProps = {
  name: string;
  fullWidth?: boolean;
};

export type Props = CustomProps & NumberFieldProps;

const RHFNumberField = ({ name, fullWidth = true, ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const helperText = errors?.[name]?.message as string;
  const componentProps = {
    name,
    fullWidth,
    helperText,
    ...rest,
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { disabled, ...fieldObj }, fieldState: { error } }) => {
        return (
          <AppNumberField
            {...componentProps}
            error={!!error}
            onChange={async (e) => {
              fieldObj.onChange(e);
            }}
            fieldObj={fieldObj}
          />
        );
      }}
    />
  );
};

export default RHFNumberField;
