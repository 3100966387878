import { useTranslation } from 'react-i18next';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import { DialogContent, DialogTitle, Dialog, IconButton, Collapse, Alert } from '@mui/material';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components/macro';

import NewsletterForm from './NewsletterForm';

interface Props {
  formErrorMessage: string;
  open: boolean;
  onSubmit: (email: string, recaptchaToken: string) => void;
  onCancel: () => void;
}

const FormAlert = styled(Alert)`
  margin: 1rem 0;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;

    width: 350px;
  }

  .MuiDialog-paper {
    margin: 1rem 0;
  }
`;

const Description = styled(AppTypography)`
  .MuiPaper-root {
    position: relative;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const NewsletterDialog = ({ open, formErrorMessage, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['start']);

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle id='alert-dialog-title'>
        {t<string>('start:newsletterDialog.title')}
      </StyledDialogTitle>
      {onCancel ? (
        <StyledIconButton aria-label='close' onClick={onCancel}>
          <MdClear />
        </StyledIconButton>
      ) : null}
      <DialogContent>
        <Collapse in={!!formErrorMessage}>
          <FormAlert severity='error'>{formErrorMessage}</FormAlert>
        </Collapse>
        <Description variant='body1'>{t<string>('start:newsletterDialog.description')}</Description>
        <NewsletterForm onSubmit={onSubmit} onCancel={onCancel} isLoading={false} />
      </DialogContent>
    </StyledDialog>
  );
};

export default NewsletterDialog;
