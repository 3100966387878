import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton, AppTypography, RHFNumberField } from '@horse-auction/common/components';
import { Alert, DialogContent, DialogTitle, Dialog, IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components/macro';

interface Props {
  open: boolean;
  isLoading: boolean;
  errorMessage?: string | null;
  onClose: () => void;
  onSubmit: (formData: any) => void;
}

const FormAlert = styled(Alert)`
  margin: 1rem 0;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
    padding-bottom: 1rem;
    max-width: 400px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const PhoneVerificationDialog = ({ isLoading, open, errorMessage, onSubmit, onClose }: Props) => {
  const { t } = useTranslation(['auth']);
  const schema = yup.object().shape({
    verificationCode: yup
      .string()
      .required(t('validation:required'))
      .test('len', 'Must be exactly 6 characters', (val) => val?.length === 6),
  });
  const methods = useForm<{ verificationCode: number }>({
    resolver: yupResolver(schema),
  });

  const { reset, handleSubmit, setFocus } = methods;

  useEffect(() => {
    if (open) {
      setFocus('verificationCode');
    } else {
      reset();
    }
  }, [setFocus, open, reset]);

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>{t<string>('auth:signup.verificationForm.title')}</StyledDialogTitle>
      <StyledIconButton aria-label='close' onClick={onClose}>
        <MdClear />
      </StyledIconButton>
      <StyledDialogContent>
        <Collapse in={!!errorMessage}>
          <FormAlert severity='error'>{errorMessage}</FormAlert>
        </Collapse>
        <AppTypography>{t<string>('auth:signup.verificationForm.description1')}</AppTypography>
        <AppTypography>{t<string>('auth:signup.verificationForm.description2')}</AppTypography>

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledWrapper>
              <RHFNumberField
                name='verificationCode'
                label={t<string>('auth:signup.verificationForm.code')}
                thousandSeparator=''
                format='######'
              />
              <AppButton isLoading={isLoading} size='large' type='submit' fullWidth>
                {t<string>('auth:signup.verificationForm.verifyButton')}
              </AppButton>
              <AppButton onClick={onClose} size='large' variant='text' fullWidth>
                {t<string>('auth:signup.verificationForm.cancelButton')}
              </AppButton>
            </StyledWrapper>
          </form>
        </FormProvider>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default PhoneVerificationDialog;
