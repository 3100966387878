import { useEffect, useContext, useState } from 'react';

import { SocketContext } from '../context/SocketContext';

// socketId is set on every connection/reconnection, so callback parameter can be called on every reconnection
const useOnSocketReconnect = (callback) => {
  const { socketId } = useContext(SocketContext);

  const [prevSocketId, setPrevSocketId] = useState(socketId);

  useEffect(() => {
    if (socketId !== prevSocketId) {
      setPrevSocketId(socketId);
      callback();
    }
  }, [socketId, callback, prevSocketId]);
};

export default useOnSocketReconnect;
