import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import { Alert } from '@mui/material';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

interface CustomProps {
  onPaymentClick: () => void;
}

const StyledAlert = styled(Alert)`
  margin: 1rem 0;

  &.MuiAlert-root {
    align-items: center;

    .MuiAlert-message {
      width: 100%;
    }
  }
`;

const AcceptTermsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiButton-root {
    max-width: 200px;
    min-width: 80px;
  }
`;

const TermsLabel = styled.div`
  margin-right: 1rem;
`;

const EntranceDepositAlert = ({ onPaymentClick }: CustomProps) => {
  const { t } = useTranslation(['bid']);

  const { auction, createDepositPaymentState } = useContext(AuctionContext);
  return (
    <>
      <StyledAlert severity='warning'>
        <AcceptTermsWrapper>
          <TermsLabel>
            {t<string>('auction:auctionAlerts.payEntranceDepositLabel', {
              currency: auction?.currency,
              amount: auction?.entranceAmount,
            })}
          </TermsLabel>
          <AppButton
            size='large'
            color='warning'
            onClick={onPaymentClick}
            disabled={createDepositPaymentState?.isLoading}
          >
            {t<string>('auction:auctionAlerts.payEntranceDepositButton')}
          </AppButton>
        </AcceptTermsWrapper>
      </StyledAlert>
    </>
  );
};

export default EntranceDepositAlert;
