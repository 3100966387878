import useTimer from '@horse-auction/common/hooks/useTimer';
import styled from 'styled-components/macro';

interface CustomProps {
  date: Date;
}

const TimerWrapper = styled.div`
  width: 100px;
  align-self: center;
  padding-left: 1rem;
  font-size: 20px;
`;

const TextTimer: React.FC<CustomProps> = ({ date }: CustomProps) => {
  const { days, hours, minutes, seconds } = useTimer(date, 1000);

  const formatDigits = (value) =>
    value.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

  return (
    <TimerWrapper>
      {`${days ? formatDigits(days) : ''}${days ? ':' : ''}${formatDigits(hours)}:${formatDigits(
        minutes
      )}:${formatDigits(seconds)}`}
    </TimerWrapper>
  );
};

export default TextTimer;
