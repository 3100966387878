import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { AppTypography } from '@horse-auction/common/components';
import { MdVideocamOff } from 'react-icons/md';
import styled from 'styled-components/macro';

interface Props {
  url?: string;
  available: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const NoVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const NoVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LotMedia = ({ url, available = false }: Props) => {
  const { t } = useTranslation(['auction']);

  return (
    <Container>
      <PlayerWrapper>
        {available && <StyledReactPlayer playing url={url} controls width='100%' height='100%' />}
        {!available && (
          <NoVideoContainer>
            <NoVideoWrapper>
              <MdVideocamOff size='3rem' />
              <AppTypography variant='body1'>
                {t<string>('auction:lotPage.noVideoUrl')}
              </AppTypography>
            </NoVideoWrapper>
          </NoVideoContainer>
        )}
      </PlayerWrapper>
    </Container>
  );
};

export default LotMedia;
