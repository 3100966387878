import FieldObject from '@horse-auction/common/types/FieldObject';
import { Autocomplete } from '@mui/lab';
import { AutocompleteProps, TextField } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  name: string;
  variant?: string;
  type?: string;
  helperText?: string;
  required?: boolean;
  fieldObj?: Omit<FieldObject, 'ref'> & { ref: (param?: HTMLBodyElement) => void };
  error?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  label?: string;
  register?: () => void;
}

export interface Props
  extends CustomProps,
    Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {}

const StyledAuctocomplete = styled(Autocomplete)`
  input:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.colors.card} inset !important`};
    -webkit-box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.colors.card} inset !important`};
    -webkit-text-fill-color: ${({ theme }) => `${theme.colors.text} !important`};
    font-size: 1rem;
  }

  &.MuiAutocomplete-root {
    margin-top: 1rem;
    ${({ fullWidth }) => {
      if (fullWidth) {
        return 'flex: 1';
      }
      return '';
    }};
  }

  .MuiAutocomplete-endAdornment {
    ${({ size }) => size === 'small' && `top: unset;`};
  }

  &.MuiInputBase-root {
    .MuiInputBase-input {
      box-shadow: none;
    }
    input:-internal-autofill-selected {
      background-color: none !important;
    }
    input:-webkit-autofill {
      background-color: none !important;
    }
  }
`;

const AppAutocomplete: React.FC<Props> = ({
  name = '',
  size = 'medium',
  multiple = false,
  helperText = '',
  fullWidth = false,
  required = false,
  error = false,
  label = '',
  options,
  getOptionLabel,
  renderOption,
  disabled,
  fieldObj,
}: Props) => (
  <StyledAuctocomplete
    multiple={multiple}
    id={`autocomplete-${name}`}
    options={options}
    onChange={(_event, val) => {
      fieldObj?.onChange?.(val);
    }}
    autoHighlight
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
    fullWidth={fullWidth}
    defaultValue={fieldObj?.value}
    filterSelectedOptions
    size={size}
    disabled={disabled}
    renderInput={(params) => (
      <TextField
        // eslint-disable react/jsx-props-no-spreading
        {...params}
        label={label}
        variant='outlined'
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
        defaultValue={fieldObj?.value}
        name={name}
        error={error}
        helperText={helperText}
        required={required}
        size={size}
      />
    )}
  />
);

export default AppAutocomplete;
