import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TSeverity } from '@horse-auction/common/types/TSeverity';
import { Alert, Collapse, CollapseProps } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  error?: any;
  message?: string;
  severity?: TSeverity;
  rendered?: boolean;
  fontSize?: string;
}
interface Props extends CustomProps, CollapseProps {}

const StyledCollapse = styled(Collapse)<Props>`
  margin: 1rem 0;
  margin: ${({ error }) => (error ? '1rem 0' : '0')};
`;

const StyledAlert = styled(Alert)<Props>`
  margin: 1rem 0;
  ${({ severity, theme, fontSize }) => {
    if (theme?.name === 'dark') {
      return 'background-color: rgb(24,6,5)';
    }
    if (severity === 'warning') {
      return 'background-color: rgb(255, 244, 229)';
    }
    if (severity === 'error') {
      return 'background-color: rgb(253, 237, 237)';
    }
    if (severity === 'info') {
      return 'background-color: rgb(229, 246, 253)';
    }
    if (severity === 'success') {
      return 'background-color: rgb(237, 247, 237)';
    }
    return '';
  }};
  .MuiAlert-message,
  .MuiAlert-icon svg {
    ${({ fontSize }) => {
      if (fontSize) {
        return `font-size: ${fontSize}`;
      }
      return '';
    }};
  }
`;

const AlertCollapse: React.FC<Props> = ({
  children,
  error = null,
  message = '',
  severity = 'error',
  rendered = false,
  fontSize = '',
}: Props) => {
  const { t } = useTranslation(['alert']);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  useEffect(() => {
    let errorMessage = null;
    if (error && error.response?.data?.name) {
      errorMessage = t(`alert:apiError.${error.response.data.name}`);
    }
    if (error && error.response?.data?.name) {
      errorMessage = t(`alert:apiError.${error.response.data.name}`);
    }
    if (error && !error?.response) {
      errorMessage = t(`alert:apiError.networkError`);
    }
    setFormErrorMessage(errorMessage);
  }, [error, t]);

  return (
    <StyledCollapse in={rendered}>
      <StyledAlert fontSize={fontSize} severity={severity}>
        {formErrorMessage || message || children}
      </StyledAlert>
    </StyledCollapse>
  );
};

export default AlertCollapse;
