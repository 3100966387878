import { colors } from '@mui/material';
import { ThemeOptions } from '@mui/system';

interface IThemeColor {
  card: string;
  body: string;
  text: string;
}

export type ThemeMode = 'light' | 'dark';

export type AppTheme = ICustomTheme & ThemeOptions;

export type ICustomTheme = {
  name: ThemeMode;
  colors: IThemeColor;
};

type CustomThemes = Record<ThemeMode, ICustomTheme>;

export const themes: CustomThemes = {
  light: {
    name: 'light',
    colors: {
      card: 'white',
      body: colors.blueGrey[50],
      text: 'black',
    },
  },
  dark: {
    name: 'dark',
    colors: {
      card: 'black',
      body: colors.grey[900],
      text: 'white',
    },
  },
};

export const customColors = {
  blue: '#2D81D1',
  lightGrey: '#E5E5E5',
  mediumGrey: '#abacba',
  darkGrey: '#1A1B24',
};

export const variants = {
  primary: {
    main: customColors.blue,
    light: colors.blue[200],
    dark: colors.blue[700],
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#66bb6a', // green 400
    light: '#a5d6a7', // green 200
    dark: '#388e3c', // green 700
  },
  error: {
    main: '#e57373',
    light: '#f44336',
    dark: '#d32f2f',
  },
  warning: {
    main: '#ffb74d',
    light: '#f44336',
    dark: '#f57c00',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
  },
  basic: {
    main: '#66bb6a', // green 400
    light: '#a5d6a7', // green 200
    dark: '#388e3c', // green 700
  },
};
