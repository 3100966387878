const getFullName = (user) => {
  if (user?.firstName || user?.lastName) {
    return `${user?.firstName} ${user?.lastName}`;
  }
  if (user?.companyName) {
    return user?.companyName;
  }
  return '';
};

export { getFullName };
