import { useState, useEffect, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { device } from '@horse-auction/common/themes/device';
import { secondsToDayTimeObject } from '@horse-auction/common/utils/time';
import styled from 'styled-components/macro';

interface DigitProps {
  size?: string;
}

interface CustomProps {
  deadlineDate: Date;
  // deadlineDate: Date;
  topLabel: string;
}

interface Props extends CustomProps, DigitProps {}

const TopLabel = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  align-self: center;
  justify-self: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const TimerWrapper = styled.div`
  display: grid;
  /* max-width: 400px; */
  align-items: center;
  grid-template-columns: repeat(4, minmax(50px, 100px));
  justify-content: center;
`;

const Digit = styled.div<DigitProps>`
  font-size: ${({ size }) => (size === 'small' ? '1.5rem' : '2rem')};
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;
`;

const DigitLabel = styled.div<DigitProps>`
  padding-left: 4px;
  font-size: ${({ size }) => (size === 'small' ? '0.625rem' : '0.875rem')};
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  @media ${device.mobile} {
    font-size: 0.625rem;
  }
`;

const EventTimer: React.FC<Props> = ({ deadlineDate, size, topLabel }: Props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const prevDeadlineDateRef: { current: Date | null } = useRef(null);
  const timerIntervalRef: { current: number | null } = useRef(null);
  const { t } = useTranslation(['components']);

  const deadlineDateToHmsObject = useCallback(() => {
    const futureDate = new Date(deadlineDate);
    const now = new Date();
    const secondsTillDeadline = (futureDate.getTime() - now.getTime()) / 1000;
    const timerObj = secondsToDayTimeObject(secondsTillDeadline);

    return timerObj;
  }, [deadlineDate]);

  const startTimerInterval = useCallback(
    () =>
      window.setInterval(() => {
        if (new Date(deadlineDate) <= new Date()) {
          if (seconds === 0 && minutes === 0 && hours === 0 && days === 0) {
            if (timerIntervalRef.current) {
              clearInterval(timerIntervalRef.current);
            }
            setHours(0);
            setMinutes(0);
            setSeconds(0);
            setDays(0);
          }
        }
        if (new Date(deadlineDate) > new Date()) {
          if (seconds > 0) {
            setSeconds((sec) => sec - 1);
          }
          if (seconds === 0 && minutes > 0) {
            setMinutes((min) => min - 1);
            setSeconds(59);
          }
          if (seconds === 0 && minutes === 0 && hours > 0) {
            setHours((h) => h - 1);
            setMinutes(59);
            setSeconds(59);
          }
          if (seconds === 0 && minutes === 0 && hours === 0 && days > 0) {
            setDays((d) => d - 1);
            setHours(23);
            setMinutes(59);
            setSeconds(59);
          }
        }
      }, 1000),
    [hours, minutes, seconds, days, deadlineDate]
  );

  useEffect(() => {
    if (deadlineDate && new Date(deadlineDate) > new Date()) {
      const {
        seconds: initialSeconds,
        minutes: initialMinutes,
        hours: initialHours,
        days: initialDays,
      } = deadlineDateToHmsObject();
      setHours(initialHours);
      setMinutes(initialMinutes);
      setSeconds(initialSeconds);
      setDays(initialDays);
    }
  }, [deadlineDate, deadlineDateToHmsObject, startTimerInterval]);

  useEffect(() => {
    if (
      (hours || minutes || seconds || days) &&
      (!timerIntervalRef.current || prevDeadlineDateRef.current !== deadlineDate)
    ) {
      prevDeadlineDateRef.current = deadlineDate;
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      timerIntervalRef.current = startTimerInterval();
    }
  }, [days, hours, minutes, seconds, startTimerInterval, deadlineDate]);

  useEffect(
    () => () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    },
    []
  );

  const formatDigits = (value) =>
    value.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

  if (!deadlineDate) {
    return null;
  }

  return (
    <TimerWrapper>
      <TopLabel>{topLabel}</TopLabel>
      <DigitLabel size={size}>{t<string>('components:eventTimer.days')}</DigitLabel>
      <DigitLabel size={size}>{t<string>('components:eventTimer.hours')}</DigitLabel>
      <DigitLabel size={size}>{t<string>('components:eventTimer.minutes')}</DigitLabel>
      <DigitLabel size={size}>{t<string>('components:eventTimer.seconds')}</DigitLabel>
      <Digit size={size}>{formatDigits(days)}</Digit>
      <Digit size={size}>{formatDigits(hours)}</Digit>
      <Digit size={size}>{formatDigits(minutes)}</Digit>
      <Digit size={size}>{formatDigits(seconds)}</Digit>
    </TimerWrapper>
  );
};

export default EventTimer;
