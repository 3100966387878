import { useCallback, useContext, useEffect, useState, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';

import {
  AppBackdrop,
  AppButton,
  AppTypography,
  FormButton,
  FormNumberField,
  Form,
  LabeledData,
} from '@horse-auction/common/components';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { device } from '@horse-auction/common/themes/device';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import BidDto from '@horse-auction/common/types/bid.dto';
import LotDto from '@horse-auction/common/types/lot.dto';
import UserAuctionDto from '@horse-auction/common/types/userAuction.dto';
import { MdLock, MdLockOpen, MdOutlineAdd, MdOutlineHorizontalRule } from 'react-icons/md';
import {
  calculateLotRequiredMinPrice,
  calculateTwoLotMinPrices,
  calculatePreviousAvailablePrice,
  calculateNextAvailablePrice,
} from 'src/services/bid.service';
import styled from 'styled-components/macro';

interface CustomProps {
  auction: AuctionDto;
  lot: LotDto;
  userAuction?: UserAuctionDto;
  customerEntranceDepositRequired?: boolean;
  createBid: (price: number) => Promise<any>;
  createBidHttpState: any;
  bids: BidDto[];
  isHighestBidder: boolean;
  onError: (error: any) => void;
}

type FormWrapperProps = PropsWithChildren & {
  height: string;
};

const formWrapperHeight = '88px';

const FormWrapper = styled.div<FormWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: ${({ height }) => height};
`;

const NextBidContainer = styled.div`
  margin-bottom: 1rem;
`;

const BidLockRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
`;

const BidButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 0.5rem;
  }
`;

const IncreseAmountContainer = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: '. . increase';
  grid-column-gap: 1rem;
`;

const ChangeAmountButtons = styled.div`
  grid-area: increase;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  .MuiButton-startIcon {
    margin: 0;
  }

  button {
    min-width: unset;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.5rem;

  .MuiTextField-root {
    margin: 0;
  }

  .MuiButton-root {
    height: 40px;
  }
`;

const BidForm = ({
  auction,
  lot,
  createBid,
  createBidHttpState,
  bids,
  isHighestBidder,
  userAuction,
  customerEntranceDepositRequired,
  onError,
}: CustomProps) => {
  const { t } = useTranslation(['bid', 'validation']);
  const { user } = useContext(AuthContext);

  const [bidLocked, setBidLocked] = useState(user?.role !== 'MODERATOR');
  const [minAvailablePrice, setMinAvailablePrice] = useState(0);
  const [threeMinAvailablePrices, setThreeMinAvailablePrices] = useState<number[]>([]);

  const updateBidLocked = useCallback(
    (isLocked) => {
      if (user?.role !== 'MODERATOR') {
        setBidLocked(isLocked);
      }
    },
    [user?.role]
  );

  const reverseBidLocked = useCallback(() => {
    if (user?.role !== 'MODERATOR') {
      setBidLocked((locked) => !locked);
    }
  }, [user?.role]);

  const getMinAvailablePrice = useCallback(
    () =>
      calculateLotRequiredMinPrice(lot, bids, auction?.startingPrice, auction?.adjustedIncrements),
    [lot, bids, auction?.startingPrice, auction?.adjustedIncrements]
  );

  const getTwoMinAvailablePrices = useCallback(
    () => calculateTwoLotMinPrices(lot, bids, auction?.startingPrice, auction?.adjustedIncrements),
    [lot, bids, auction?.startingPrice, auction?.adjustedIncrements]
  );

  useEffect(() => {
    updateBidLocked(true);
  }, [updateBidLocked]);

  useEffect(() => {
    const newMinAvailablePrice = getMinAvailablePrice();
    const [first, second] = getTwoMinAvailablePrices();
    const third = calculateNextAvailablePrice(
      lot,
      second,
      auction?.startingPrice,
      auction?.adjustedIncrements
    );

    setMinAvailablePrice(newMinAvailablePrice);
    setThreeMinAvailablePrices([first, second, third]);
    onError(null);
  }, [
    lot,
    bids,
    auction?.startingPrice,
    auction?.adjustedIncrements,
    getMinAvailablePrice,
    getTwoMinAvailablePrices,
    onError,
  ]);

  useEffect(() => {
    updateBidLocked(true);
    onError(null);
  }, [lot?._id, bids?.length, onError, updateBidLocked]);

  const schema = yup.object().shape({
    price: yup
      .number()
      .nullable(true)
      .transform((v) => (isNaN(v) || v === '' ? null : v))
      .required(t('validation:required'))
      .min(minAvailablePrice),
  });

  const increaseAmount = useCallback(() => {
    const [first, second, third] = threeMinAvailablePrices;
    const newThird = calculateNextAvailablePrice(
      lot,
      third,
      auction?.startingPrice,
      auction?.adjustedIncrements
    );
    if (newThird > second) {
      setThreeMinAvailablePrices([first, second, newThird]);
    }
  }, [threeMinAvailablePrices, lot, auction?.startingPrice, auction?.adjustedIncrements]);

  const decreaseAmount = useCallback(() => {
    const [first, second, third] = threeMinAvailablePrices;
    const newThird = calculatePreviousAvailablePrice(
      lot,
      third,
      auction?.startingPrice,
      auction?.adjustedIncrements
    );
    if (newThird > second) {
      setThreeMinAvailablePrices([first, second, newThird]);
    }
  }, [threeMinAvailablePrices, lot, auction?.startingPrice, auction?.adjustedIncrements]);

  const bidLockHandler = useCallback(() => {
    reverseBidLocked();
  }, [reverseBidLocked]);

  const canBid = useCallback(() => {
    if (user?.role === 'MODERATOR') {
      return true;
    }
    if (user?.role === 'CUSTOMER') {
      return !customerEntranceDepositRequired && userAuction?.auctionTermsAccepted === true;
    }

    return false;
  }, [userAuction?.auctionTermsAccepted, user, customerEntranceDepositRequired]);

  const biddingButtonsVisible = useCallback(
    () => canBid() && (lot?.status === 'IN_PROGRESS' || lot?.status === 'UPCOMING'),
    [lot?.status, canBid]
  );

  const biddingFormVisible = useCallback(
    () => user?.role === 'MODERATOR' && lot?.status === 'IN_PROGRESS',
    [lot, user?.role]
  );

  const buttonBidHandler = useCallback(
    (price) => {
      createBid(price)
        .then(() => {
          onError(null);
        })
        .catch((err) => {
          onError(err);
        });
    },
    [createBid, onError]
  );

  const onSubmit = useCallback(
    (formData) => {
      createBid(formData.price)
        .then(() => {
          onError(null);
        })
        .catch((err) => {
          onError(err);
        });
    },
    [createBid, onError]
  );

  if (!lot) {
    return (
      <AppTypography variant='body1'>
        {t<string>('bid:biddingConsole.noLotForBiddingAvailable')}
      </AppTypography>
    );
  }

  return (
    <>
      {/* INPUT FORM ONLY FOR MODERATOR */}
      {biddingFormVisible() && (
        <FormWrapper height={formWrapperHeight}>
          <NextBidContainer>
            <LabeledData label={t<string>('bid:biddingConsole.nextAvailableBid')}>
              <NumberFormat
                thousandSeparator=' '
                displayType='text'
                prefix={`${auction?.currency} `}
                value={minAvailablePrice}
              />
            </LabeledData>
          </NextBidContainer>
          <AppBackdrop
            isLoading={createBidHttpState?.isLoading}
            noBackground
            height={formWrapperHeight}
          >
            <Form schema={schema}>
              <FormContainer>
                <FormNumberField
                  label={t<string>('bid:biddingConsole.bidPriceInput')}
                  name='price'
                  disabled={bidLocked}
                  onSubmit={onSubmit}
                  size='small'
                  fullWidth
                  dynamicDefaultValue={user?.role === 'CUSTOMER' ? minAvailablePrice : null}
                  prefix={`${lot.auctionCurrency} `}
                />
                <FormButton size='small' variant='outlined' onClick={onSubmit}>
                  {t<string>('bid:biddingConsole.bidButton')}
                </FormButton>
              </FormContainer>
            </Form>
          </AppBackdrop>
        </FormWrapper>
      )}
      {/* UNLOCK BUTTON ONLY FOR CUSTOMER */}
      {user?.role === 'CUSTOMER' && biddingButtonsVisible() && (
        <BidLockRow>
          <AppButton
            variant='outlined'
            color='primary'
            disabled={!canBid() || lot.status !== 'IN_PROGRESS'}
            fullWidth
            startIcon={bidLocked ? <MdLock /> : <MdLockOpen color='primary' />}
            onClick={bidLockHandler}
          >
            {bidLocked
              ? t<string>('bid:biddingConsole.unlockButton')
              : t<string>('bid:biddingConsole.lockButton')}
          </AppButton>
        </BidLockRow>
      )}

      {/* <Tooltip title={bidLocked ? t('bid:biddingConsole.unlockTooltip') : ''}> */}
      {biddingButtonsVisible() && (
        <>
          <BidButtonsContainer>
            {threeMinAvailablePrices?.map((price) => (
              <AppButton
                key={`${price}_${bids?.length}`}
                size='large'
                variant='outlined'
                fullWidth
                onClick={() => buttonBidHandler(price)}
                disabled={createBidHttpState.isLoading || lot.status !== 'IN_PROGRESS' || bidLocked}
              >
                <NumberFormat
                  thousandSeparator=' '
                  displayType='text'
                  prefix={`${auction?.currency} `}
                  value={price}
                />
              </AppButton>
            ))}
          </BidButtonsContainer>
          <IncreseAmountContainer>
            <ChangeAmountButtons>
              <AppButton
                size='small'
                variant='outlined'
                fullWidth
                startIcon={<MdOutlineHorizontalRule />}
                onClick={decreaseAmount}
                disabled={createBidHttpState.isLoading || lot.status !== 'IN_PROGRESS' || bidLocked}
              />
              <AppButton
                size='small'
                variant='outlined'
                fullWidth
                startIcon={<MdOutlineAdd />}
                onClick={increaseAmount}
                disabled={createBidHttpState.isLoading || lot.status !== 'IN_PROGRESS' || bidLocked}
              />
            </ChangeAmountButtons>
          </IncreseAmountContainer>
        </>
      )}
    </>
  );
};

export default BidForm;
