import { useEffect, useCallback, useContext, useState, useRef } from 'react';

import bellSound from '@horse-auction/common/assets/bell.wav';
import BidDto from '@horse-auction/common/types/bid.dto';

import { AuthContext } from '../context/AuthContext';
import { SocketContext } from '../context/SocketContext';
import useAudio from './useAudio';
import useHttp from './useHttp';

interface IEventObject {
  name: string;
  type: string;
  lotId: string;
  key: string;
}

const useBids = (api, lotId, userRole, readOnly = false) => {
  const { socketId, subscribeEvent, unsubscribeEvent } = useContext(SocketContext);
  const authContext = useContext(AuthContext);

  const [prevSocketId, setPrevSocketId] = useState(socketId);
  const [bids, setBids] = useState<BidDto[]>([]);
  const [bidsWithCanceled, setBidsWithCanceled] = useState<BidDto[]>([]);
  const [numberOfBidders, setNumberOfBidders] = useState(0);
  const [numberOfBids, setNumberOfBids] = useState(0);
  const [isHighestBidder, setIsHighestBidder] = useState(false);
  const [highestBidStatus, setHighestBidStatus] = useState('PLACED');
  const [eventObject, setEventObject] = useState<IEventObject>();
  const [createBidHttpState, createBidRequest] = useHttp(null);
  const [bidsHttpState, bidsRequest] = useHttp(null);

  const prevLotId = useRef();
  const { play } = useAudio(bellSound);

  const componentWillUnmount = useRef(false);

  useEffect(
    () => () => {
      componentWillUnmount.current = true;
    },
    []
  );

  const updateBids = useCallback(
    ({ action, bids: bidsArray }) => {
      const bidsNotCanceled = bidsArray?.filter((bid) => !bid?.canceled);
      setBids(bidsNotCanceled);

      // bids that contain canceled bids but valid bid should be at index 0
      const bidsCancelAdjusted = bidsArray ? [...bidsArray] : [];
      if (bidsArray?.[0]?.canceled) {
        const highestValidBidIndex = bidsArray?.findIndex((bid) => !bid?.canceled);
        if (highestValidBidIndex > 0) {
          const removedArray = bidsCancelAdjusted?.splice(highestValidBidIndex, 1);
          bidsCancelAdjusted?.unshift(removedArray[0]);
        }
      }
      setBidsWithCanceled(bidsCancelAdjusted);

      const isUserHighestBidder =
        bidsNotCanceled?.[0]?.userId && bidsNotCanceled?.[0]?.userId === authContext.user?._id;
      setIsHighestBidder(isUserHighestBidder);

      if (
        action === 'create' &&
        ['MODERATOR', 'AUCTIONEER'].includes(userRole) &&
        bidsNotCanceled?.[0]?.inhouse !== true
      ) {
        play();
      }

      const biddersArray = bidsNotCanceled?.map((bid) => bid.userId) || [];
      const numberOfUniqueBidders =
        bidsNotCanceled?.length > 0 ? [...new Set(biddersArray)].length : 0;
      setNumberOfBidders(numberOfUniqueBidders);
      setNumberOfBids(bidsNotCanceled?.length || 0);
      setHighestBidStatus(bidsNotCanceled?.[0]?.biddingStatus);
    },
    [authContext.user?._id, play, userRole]
  );

  const getBids = useCallback(
    (lotIdString) => {
      if (['MODERATOR', 'AUCTIONEER'].includes(userRole)) {
        return bidsRequest(() => api.getModeratorBids(lotIdString)).then((bidArray) => {
          const bidsObj = { bids: bidArray };
          updateBids(bidsObj);
        });
      }
      return bidsRequest(() => api.getCustomerBids(lotIdString)).then((bidArray) => {
        const bidsObj = { bids: bidArray };
        updateBids(bidsObj);
      });
    },
    [bidsRequest, updateBids, userRole, api]
  );

  useEffect(() => {
    if (prevLotId.current !== lotId && !readOnly) {
      unsubscribeEvent(eventObject, updateBids);
      const eventName = `bids-update-${lotId}`;
      const type = ['MODERATOR', 'AUCTIONEER'].includes(userRole) ? 'MODERATOR_BID' : 'BID';
      const newEventObject = { name: eventName, type, lotId, key: eventName };
      setEventObject(newEventObject);
      subscribeEvent(newEventObject, updateBids);
    }
  }, [unsubscribeEvent, lotId, userRole, eventObject, subscribeEvent, updateBids, readOnly]);

  useEffect(() => {
    if (prevLotId.current !== lotId || socketId !== prevSocketId) {
      prevLotId.current = lotId;
      setPrevSocketId(socketId);
      getBids(lotId);
    }
  }, [getBids, lotId, socketId, prevSocketId]);

  useEffect(
    () => () => {
      if (componentWillUnmount.current === true && !readOnly) {
        unsubscribeEvent(eventObject, updateBids);
      }
    },
    [unsubscribeEvent, eventObject, updateBids, readOnly]
  );

  const createBid = useCallback(
    (price) => createBidRequest(() => api.createBid(lotId, price)),
    [lotId, createBidRequest, api]
  );

  return {
    bidsHttpState,
    createBidHttpState,
    bids,
    bidsWithCanceled,
    highestBidStatus,
    isHighestBidder,
    numberOfBids,
    numberOfBidders,
    createBid,
  };
};

export default useBids;
