/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import useHttp from '@horse-auction/common/hooks/useHttp';
import FieldObject from '@horse-auction/common/types/FieldObject';
import { ButtonProps, InputProps } from '@mui/material';
import styled from 'styled-components/macro';

import { TCancellablePromise } from 'utils/CancelablePromise';

import AppButton from '../AppButton/AppButton';

interface CustomProps {
  isLoading?: boolean;
  fieldObj?: FieldObject;
  itemId: string;
  uploadRequest: (formData: any) => TCancellablePromise;
  register?: (name: string) => void;
  onSuccess: (file: any) => void;
}

type Props = CustomProps & ButtonProps & InputProps;

const Container = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ChooseFileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledInput = styled.input`
  display: none;
`;

const AppFileUpload: React.FC<Props> = ({
  itemId,
  name = 'file-upload',
  uploadRequest,
  register,
  fieldObj,
  onSuccess,
}: Props) => {
  const { t } = useTranslation(['components']);

  const [fileUploadHttpState, fileUploadRequest] = useHttp();

  const upload = useCallback(
    (formData) => {
      fileUploadRequest(() => uploadRequest(formData))
        .then((fileObj) => {
          onSuccess(fileObj);
        })
        .catch()
        .finally();
    },
    [fileUploadRequest, uploadRequest, onSuccess]
  );

  const selectFile = useCallback(
    (event) => {
      const { files } = event.target;
      const data = new FormData();
      data.append('file', files[0]);
      data.append('name', files[0]?.name);
      data.append('id', itemId);
      upload(data);
    },
    [itemId, upload]
  );

  return (
    <Container>
      <ChooseFileWrapper>
        <StyledInput
          accept='image/*'
          id={`choose-file-${name}`}
          type='file'
          onChange={selectFile}
          fieldObj={fieldObj}
          name={name}
          {...(register && register?.(name))}
        />
        <label htmlFor={`choose-file-${name}`}>
          <AppButton
            variant='outlined'
            color='primary'
            component='span'
            isLoading={fileUploadHttpState.isLoading}
          >
            {t<string>('components:appFileUpload.uploadFileButton')}
          </AppButton>
        </label>
      </ChooseFileWrapper>
    </Container>
  );
};

export default AppFileUpload;
