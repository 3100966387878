import { useTranslation } from 'react-i18next';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomSwitchProps {
  fieldObj: FieldObject;
  defaultValue: boolean;
}

interface CustomLabelProps {
  margin: string;
}

type CustomFormControlLabelProps = CustomLabelProps & FormControlLabelProps;

export type Props = SwitchProps & CustomFormControlLabelProps & CustomSwitchProps;

const StyledFormControlLabel = styled(FormControlLabel)<CustomFormControlLabelProps>`
  &.MuiFormControlLabel-root {
    ${({ margin }) => margin && `margin: ${margin}`};
  }
`;

const AppSwitch: React.FC<Props> = ({ size, defaultChecked, label, margin, fieldObj }: Props) => {
  const { t } = useTranslation(['components']);

  return (
    <StyledFormControlLabel
      control={<Switch size={size} defaultChecked={defaultChecked} {...fieldObj} />}
      label={label}
      margin={margin}
    />
  );
};

export default AppSwitch;
