/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import genericHorse from '@horse-auction/common/assets/images/equibidLogo.png';
import {
  AppTypography,
  EventTimer,
  EventTimeRow,
  Logo,
  LotChip,
  StyledLink,
} from '@horse-auction/common/components/';
import useBreakpoint from '@horse-auction/common/hooks/useBreakpoint';
import { getImageUrl } from '@horse-auction/common/services/RequestService';
import { device } from '@horse-auction/common/themes/device';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import { Card, Divider } from '@mui/material';
import { ImListNumbered } from 'react-icons/im';
import { MdLiveTv } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  auction: AuctionDto;
  isLoading: boolean;
  logoKey: string;
  organizerLogoKey: string;
}

interface AuctionTypeDiv {
  type: string;
}

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 316px 1fr;

  @media ${device.laptop} {
    grid-template-rows: 208px 1fr;
  }

  @media ${device.tablet} {
    grid-template-rows: 196px 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  background-color: black;
`;

const AuctionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OrganizerImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
`;

const OrganizerLogoImage = styled.img`
  height: 4rem;
  object-fit: contain;
`;

const NowTypography = styled(AppTypography)`
  &.MuiTypography-root {
    margin-left: 2rem;
  }

  @media ${device.tablet} {
    &.MuiTypography-root {
      font-size: 24px;
      margin-left: 1rem;
    }
  }
`;

const AuctionType = styled.div<AuctionTypeDiv>`
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;

  span {
    color: ${({ theme, type }) => {
      if (type === 'HYBRID') {
        return theme?.palette?.primary?.main;
      }
      return '#9a4c71';
    }};
    font-weight: 700;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 1rem;
`;

const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 2rem;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media ${device.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;

const EventTimeWrapper = styled.div`
  display: flex;

  align-self: flex-end;
  justify-self: center;
  margin-top: 1rem;
`;

const LotsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-end;
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const ChipWrapper = styled.div`
  align-self: flex-end;
`;

const LotsTypography = styled(AppTypography)`
  &.MuiTypography-root {
    margin-left: 1rem;
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  position: relative;

  &.MuiCard-root {
    /* width: 100%; */
    transition: transform 0.4s;

    &:hover {
      transform: scale(1.03);
    }
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    align-self: flex-end;
  }
`;

const AuctionCardLong: React.FC<CustomProps> = ({
  auction,
  isLoading,
  logoKey,
  organizerLogoKey,
}: CustomProps) => {
  const { t } = useTranslation(['components']);
  const breakpoint = useBreakpoint();

  const getImagePath = useCallback(
    (imageKey) => (imageKey ? getImageUrl(`/${imageKey}`) : genericHorse),
    []
  );

  return (
    <StyledCard role='auction-card-long'>
      <StyledLink to={`/auctions/${auction?._id}`} noUnderline>
        <CardWrapper>
          {breakpoint !== 'mobile' && (
            <ImageWrapper>
              <AuctionImage
                alt={auction?.title || 'horse'}
                src={getImagePath(logoKey || auction?.logo?.key)}
              />
            </ImageWrapper>
          )}
          <ContentWrapper>
            <BasicInfoWrapper>
              <ChipWrapper>
                <LotChip status={auction?.status} size='small' />
              </ChipWrapper>
              <OrganizerImageWrapper>
                {auction?.organizerLogo?.key && (
                  <OrganizerLogoImage
                    alt={auction?.title || 'logo'}
                    src={getImagePath(organizerLogoKey || auction?.organizerLogo?.key)}
                  />
                )}
                {!auction?.organizerLogo?.key && <Logo positioning='horizontal' />}
              </OrganizerImageWrapper>
              <DatesWrapper>
                <EventTimeRow
                  eventDate={auction?.startDate}
                  label={
                    !['tablet', 'laptop'].includes(breakpoint)
                      ? t('components:auctionCard.startsAt')
                      : ''
                  }
                />
                {auction?.endDate && (
                  <EventTimeRow
                    eventDate={auction?.endDate}
                    label={
                      !['tablet', 'laptop'].includes(breakpoint)
                        ? t('components:auctionCard.endsAt')
                        : ''
                    }
                  />
                )}
              </DatesWrapper>
            </BasicInfoWrapper>
            <StyledDivider />
            <EventTimeWrapper>
              {auction?.type === 'HYBRID' && auction?.status === 'SCHEDULED' && (
                <EventTimer
                  deadlineDate={auction?.startDate}
                  topLabel={t<string>('components:auctionCard.auctionStartsIn')}
                  size={['tablet', 'laptop'].includes(breakpoint) ? 'small' : ''}
                />
              )}
              {auction?.type === 'HYBRID' && auction?.status === 'IN_PROGRESS' && (
                <>
                  <MdLiveTv size='3rem' />
                  <NowTypography gutterBottom variant='h4' component='h2'>
                    Now live
                  </NowTypography>
                </>
              )}
              {auction?.type === 'ONLINE' && auction?.status === 'SCHEDULED' && (
                <EventTimer
                  deadlineDate={auction?.startDate}
                  topLabel={t<string>('components:auctionCard.auctionStartsIn')}
                  {...(['tablet', 'laptop'].includes(breakpoint) && { size: 'small' })}
                />
              )}
              {auction?.type === 'ONLINE' && auction?.status === 'IN_PROGRESS' && (
                <EventTimer
                  deadlineDate={auction?.endDate}
                  topLabel={t<string>('components:auctionCard.auctionEndsIn')}
                  {...(['tablet', 'laptop'].includes(breakpoint) && { size: 'small' })}
                />
              )}
            </EventTimeWrapper>
          </ContentWrapper>
        </CardWrapper>
        <DescriptionWrapper>
          <InfoWrapper>
            {auction?.type === 'HYBRID' && (
              <AuctionType type='HYBRID'>
                <>
                  <span>{t<string>('components:auctionCard.onliveAuction1')}</span>{' '}
                  {t<string>('components:auctionCard.onliveAuction2')}{' '}
                </>
              </AuctionType>
            )}
            {auction?.type === 'ONLINE' && (
              <AuctionType type='ONLINE'>
                <>
                  <span>{t<string>('components:auctionCard.onlineAuction1')}</span>{' '}
                  {t<string>('components:auctionCard.onlineAuction2')}{' '}
                </>
              </AuctionType>
            )}
            <LotsWrapper>
              <ImListNumbered />
              <LotsTypography variant='body1'>
                {auction?.lotsQuantity || 0} {t<string>('components:auctionCard.lots')}
              </LotsTypography>
            </LotsWrapper>
          </InfoWrapper>
          <AppTypography variant='h5' lineHeight='32px'>
            {auction?.title}
          </AppTypography>
        </DescriptionWrapper>
      </StyledLink>
    </StyledCard>
  );
};

export default AuctionCardLong;
