import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { FormHelperText, FormHelperTextProps } from '@mui/material';
import styled from 'styled-components/macro';

import colors from '../../themes/colorPallete';
import 'react-phone-input-2/lib/material.css';

type CustomProps = {
  helperText: string;
  label: string;
  fieldObj?: FieldObject;
};

export type Props = CustomProps & PhoneInputProps & FormHelperTextProps;

const StyledPhoneInput = styled(PhoneInput)`
  .special-label {
    color: #cacaca;
  }
  input {
    width: 100%;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    margin-left: 14px;
    margin-right: 14px;
  }
`;

const handleDarkTheme = () => `
  background-color: #212121;
  color: ${colors.white};
  `;

const handleDarkThemeHighlight = () => `
  background-color: ${colors.black};
  color: ${colors.white};
  `;

const PhoneInputWrapper = styled.div<Partial<Props>>`
  margin-top: 1rem;
  .react-tel-input {
    input {
      width: 100%;
    }

    .special-label {
      color: rgba(0, 0, 0, 0.54);
      font-family: WorkSans;
      font-size: 12px;
    }
  }

  ${({ theme }) => {
    if (theme?.name === 'dark') {
      return `
      .react-tel-input {
        ${handleDarkTheme()}

        .selected-flag {
          .arrow,
          .arrow.up {
            border-top-color: ${colors.white};
            border-bottom-color: ${colors.white};
          }
        }

        .special-label {
            ${handleDarkTheme()}
        }

        input {
            ${handleDarkTheme()}
        }

        .form-control {
          border-color: rgba(255, 255, 255, 0.23);

          &:hover {
          border-color: ${colors.white};
          }
        }

        .flag-dropdown {
          ul {
          background-color: #212121;
          color: ${colors.white};
            li.highlight {
            ${handleDarkThemeHighlight()}
            }
          }
        }
        
        .country-list {
          .country {
            &:hover {
            ${handleDarkThemeHighlight()}
            }
            .dial-code {
            ${handleDarkTheme()}
            }
          }
        }
      }
      `;
    }
    return '';
  }};

  ${({ error }) => {
    if (error) {
      return `
        .react-tel-input {
          .form-control {
            border-color: #e57373;
            
            &:hover {
              border-color: #e57373;
            }
          }

          .special-label {
            color: #e57373;
          }
        }
  }}`;
    }
    return '';
  }}
`;

const AppPhoneField = ({ helperText, required, error, label, fieldObj, onClick }: Props) => {
  const getSpecialLabel = () => {
    if (required) {
      return `${label} *`;
    }
    return label;
  };
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <PhoneInputWrapper error={error}>
      <StyledPhoneInput country='pl' {...fieldObj} specialLabel={getSpecialLabel()} />
      {error && <StyledFormHelperText error>{helperText}</StyledFormHelperText>}
    </PhoneInputWrapper>
  );
};

export default AppPhoneField;
