import React, { Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

import { ConnectionLostAlert } from '@horse-auction/common/components';
import styled from 'styled-components/macro';

const ContactPageLazy = React.lazy(() => import('src/pages/contact/ContactPage'));
const TermsPageLazy = React.lazy(() => import('src/pages/terms/TermsPage'));
const HowToBidPageLazy = React.lazy(() => import('src/pages/how-to-bid/HowToBidPage'));

const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 4rem;
  justify-content: flex-start;
`;

const EmptyLayout = () => (
  <PageContent>
    <ConnectionLostAlert />
    <Switch>
      <Suspense fallback={<p>loading</p>}>
        <Route path='/contact' component={ContactPageLazy} />
        <Route path='/how-to-bid' component={HowToBidPageLazy} />
        <Route path='/terms' component={TermsPageLazy} />
      </Suspense>
    </Switch>
  </PageContent>
);

export default EmptyLayout;
