import { createContext } from 'react';

import NotificationDto from '@horse-auction/common/types/notification.dto';
import useNotifications from 'src/hooks/useNotifications';

interface ContextProps {
  children: React.ReactNode;
}

type INotificationsContext = {
  notifications?: NotificationDto[];
  notificationsHttpState?: any;
  markNotificationsAsRead?: (notiticationIds: string[]) => void;
  markNotificationAsReadHttpState?: any;
};

const NotificationsContext = createContext<INotificationsContext>({});

const NotificationsProvider = ({ children }: ContextProps) => {
  const {
    notifications,
    notificationsHttpState,
    markNotificationsAsRead,
    markNotificationsAsReadHttpState,
  } = useNotifications();

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        notificationsHttpState,
        markNotificationsAsRead,
        markNotificationAsReadHttpState: markNotificationsAsReadHttpState,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
