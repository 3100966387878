import { useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import useBreakpoint from '@horse-auction/common/hooks/useBreakpoint';
import { Box, Tabs } from '@mui/material';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

import LotsNavCard from './LotsNavCard';

import 'src/locales/i18n';

interface Props {
  selectedLotId?: string;
  auctionType?: string;
  lotsQuantity?: number;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const StyledTabs = styled(Tabs)``;

export const LotList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const LotsNav = ({ selectedLotId, lotsQuantity = 0, auctionType = 'ONLINE' }: Props) => {
  const { auction, previewToken } = useContext(AuctionContext);
  const breakpoint = useBreakpoint();

  const getCardSize = useCallback(() => {
    if (
      auction?.type === 'HYBRID' &&
      auction?.status === 'IN_PROGRESS' &&
      ['laptop', 'tablet'].includes(breakpoint)
    ) {
      return 'small';
    }
    if (auction?.type === 'HYBRID' && ['tablet'].includes(breakpoint)) {
      return 'small';
    }
    if (auction?.type === 'ONLINE' && ['tablet'].includes(breakpoint)) {
      return 'small';
    }
    return 'medium';
  }, [breakpoint, auction?.type, auction?.status]);

  const { t } = useTranslation(['auction']);

  return (
    <Container>
      <LotList>
        {auction?.lots.map((lot) => (
          <LotsNavCard
            key={lot._id}
            lot={lot}
            previewToken={previewToken}
            active={lot._id === selectedLotId}
            lotIndex={lot.auctionOrder + 1}
            lotsQuantity={lotsQuantity}
            size={getCardSize()}
          />
        ))}
        {auction?.lots.length === 0 && <Box>{t<string>('auction:lotsNav.noLots')}</Box>}
      </LotList>
    </Container>
  );
};

export default LotsNav;
