import axios from 'axios';

const commonRequestOptions = {
  credentials: 'include',
  withCredentials: true,
};

export const httpPost = (url, initialData?: any, options?: any) => {
  const data = {
    ...initialData,
    timestamp: Date.now(),
  };

  return axios.post(url, data, { ...commonRequestOptions, ...options });
};

export const fileUpload = (url, formData, options) =>
  axios.post(url, formData, { ...commonRequestOptions, ...options });

export const httpPut = (url, initialData?) => {
  const data = {
    ...initialData,
    timestamp: Date.now(),
  };

  return axios.put(url, data, commonRequestOptions);
};

export const httpDelete = (url, data?: any) => axios.delete(url, { data, ...commonRequestOptions });

export const httpGet = (url, params?: any) => {
  const config = {
    params,
    ...commonRequestOptions,
  };

  return axios.get(url, config);
};

export const getFullUrl = (url: string) => {
  const { REACT_APP_SERVER_URL, REACT_APP_API_V1_URL } = process.env;

  return `${REACT_APP_SERVER_URL}${REACT_APP_API_V1_URL}${url}`;
};

export const getImageUrl = (url: string) => {
  const { REACT_APP_SERVER_URL, REACT_APP_API_V1_URL, REACT_APP_PRODUCT_IMAGE_PATH } = process.env;

  return `${REACT_APP_SERVER_URL}${REACT_APP_API_V1_URL}${REACT_APP_PRODUCT_IMAGE_PATH}${url}`;
};

export const setBaseUrl = () => {
  const { REACT_APP_SERVER_URL, REACT_APP_API_V1_URL } = process.env;

  const baseURL = `${REACT_APP_SERVER_URL}${REACT_APP_API_V1_URL}`;
  axios.defaults.baseURL = baseURL;
};
