import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppTypography, LotChip } from '@horse-auction/common/components';
import { device } from '@horse-auction/common/themes/device';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

const CurrentLot = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  flex: 1;

  .MuiTypography-root {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const TvHeader = () => {
  const { currentLot } = useContext(AuctionContext);

  const { t } = useTranslation(['bid']);

  return (
    <Container>
      <CurrentLot>
        {currentLot && (
          <AppTypography fontSize='88px' lineHeight='100px' variant='h4'>
            <LotChip status={currentLot?.status} margin='0 0.5rem' size='small' variant='filled' />
            {`${currentLot?.auctionOrder + 1}. ${currentLot?.product?.name}`}
          </AppTypography>
        )}
        {!currentLot && (
          <AppTypography variant='h6' margin='1rem 1rem 0 0' fontSize='24px'>
            {t<string>('bid:biddingConsole.noLotForBiddingAvailable')}
          </AppTypography>
        )}
      </CurrentLot>
    </Container>
  );
};

export default TvHeader;
