import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { AppTypography } from '@horse-auction/common/components/';
import { DialogContent, Dialog, IconButton, DialogTitle } from '@mui/material';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components/macro';

import PaymentFailureContent from './PaymentFailureContent';
import PaymentSuccessContent from './PaymentSuccessContent';

interface Props {
  paymentStatus: string;
  open: boolean;
  lotId?: string;
  onCancel: () => void;
  onBackToAuction?: () => void;
  onPayNow: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
  }

  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-transform: uppercase;

  &.MuiDialogTitle-root {
    display: flex;
    align-items: center;
    padding-right: 3rem;
  }
`;

const PaymentOutcomeDialog = ({
  paymentStatus,
  open,
  lotId,
  onCancel,
  onBackToAuction = () => undefined,
  onPayNow,
}: Props) => {
  const { t } = useTranslation();

  const getTitleColor = useCallback(() => {
    let color = 'primary';
    if (paymentStatus === 'failure') {
      color = 'error';
    }
    return color;
  }, [paymentStatus]);

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle id='alert-dialog-title'>
        <AppTypography variant='h5' lineHeight='28px' color={getTitleColor()}>
          <>
            {paymentStatus === 'succeeded' &&
              t('payments:paymentOutcomeDialog.success.paymentSuccessTitle')}
            {paymentStatus === 'failed' &&
              t('payments:paymentOutcomeDialog.failure.paymentFailedTitle')}
          </>
        </AppTypography>
      </StyledDialogTitle>
      {onCancel ? (
        <StyledIconButton aria-label='close' onClick={onCancel}>
          <MdClear />
        </StyledIconButton>
      ) : null}
      <DialogContent>
        {paymentStatus === 'succeeded' && (
          <PaymentSuccessContent onCancel={onCancel} onBackToAuction={onBackToAuction} />
        )}
        {paymentStatus === 'failed' && (
          <PaymentFailureContent
            onCancel={onCancel}
            onPayNow={onPayNow}
            onBackToAuction={onBackToAuction}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default PaymentOutcomeDialog;
