import { useEffect, useCallback, useContext, useState } from 'react';

import { useParams } from 'react-router-dom';

import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import UserOrganizationDto from '@horse-auction/common/types/userOrganization.dto';
import UserOrganizationAPI from 'src/api/UserOrganizationAPI';

interface RouteParams {
  auctionId: string;
}

const useUserOrganization = () => {
  const { user } = useContext(AuthContext);

  const { auctionId } = useParams<RouteParams>();

  const [userOrganization, setUserOrganization] = useState<UserOrganizationDto>();
  const [userOrganizationHttpState, userOrganizationRequest] = useHttp();

  const { socket, socketId } = useContext(SocketContext);

  const getUserOrganization = useCallback(() => {
    if (auctionId) {
      userOrganizationRequest(() =>
        UserOrganizationAPI.getUserOrganizationByAuction(auctionId)
      ).then((dataObj: UserOrganizationDto) => {
        setUserOrganization(dataObj);
      });
    }
  }, [userOrganizationRequest, auctionId]);

  useEffect(() => {
    if (user) {
      socket?.on('user-organization-change', (userOrganizationObj) => {
        setUserOrganization(userOrganizationObj);
      });
    }
  }, [socket, getUserOrganization, user]);

  useEffect(() => {
    if (user) {
      getUserOrganization();
    }
  }, [getUserOrganization, socketId, user]);

  return {
    userOrganization,
    userOrganizationHttpState,
  };
};

export default useUserOrganization;
