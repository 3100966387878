import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

interface Country {
  label: string;
  value: string;
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);

  const { t } = useTranslation(['countries']);

  useEffect(() => {
    const countriesObject = t('countries:countries', { returnObjects: true });
    const countriesArray: Country[] = [];
    for (const [key, value] of Object.entries(countriesObject)) {
      countriesArray.push({ label: value, value: key });
    }
    setCountries(countriesArray);
  }, [t]);

  const codeToCountryLabel = (code) => {
    const country = countries?.find((ctry) => ctry.value === code);
    return country?.label || '';
  };

  const codeToCountryOption = useCallback(
    (code) => {
      const country = countries?.find((ctry) => ctry.value === code);

      return country;
    },
    [countries]
  );

  return {
    countries,
    countryToFlag,
    codeToCountryLabel,
    codeToCountryOption,
  };
};

export default useCountries;
