import { httpGet, httpPut } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getAuction = (auctionId: string) => CancellablePromise(httpGet(`/auctions/${auctionId}`));

const getAuctions = () => {
  const payload = {};

  return CancellablePromise(httpGet('/auctions', payload));
};

const getUpcomingAuctions = () => CancellablePromise(httpGet('/auctions/upcoming'));

const getCustomerAuction = (auctionId: string, previewToken: string) => {
  if (previewToken) {
    return CancellablePromise(httpGet(`/auctions/customer/${auctionId}?preview=${previewToken}`));
  }
  return CancellablePromise(httpGet(`/auctions/customer/${auctionId}`));
};

const getCustomerAuctions = () => {
  const payload = {};

  return CancellablePromise(httpGet('/auctions/customer', payload));
};

const getModeratorAuction = (auctionId: string) =>
  CancellablePromise(httpGet(`/auctions/moderator/${auctionId}`));

const getModeratorAuctions = () => {
  const payload = {};

  return CancellablePromise(httpGet('/auctions/moderator', payload));
};

const getAuctioneerAuctions = () => {
  const payload = {};

  return CancellablePromise(httpGet('/auctions/auctioneer', payload));
};

const getCurrentAuction = () => {
  const payload = {};

  return CancellablePromise(httpGet('/auctions/current', payload));
};

const updateAuctionStatus = (auctionId: string, status: string) => {
  const payload = { auctionId, status };

  return CancellablePromise(httpPut('/auctions/status', payload));
};

const updateAuctionByModerator = (auctionId: string, streamUrl: string) => {
  const payload = { streamUrl };

  return CancellablePromise(httpPut(`/auctions/moderator/${auctionId}`, payload));
};

const AuctionAPI = {
  getAuction,
  getAuctions,
  getUpcomingAuctions,
  getCustomerAuction,
  getCustomerAuctions,
  getModeratorAuction,
  getModeratorAuctions,
  getAuctioneerAuctions,
  getCurrentAuction,
  updateAuctionStatus,
  updateAuctionByModerator,
};

export default AuctionAPI;
