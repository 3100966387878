import { useEffect, useCallback, useContext, useState } from 'react';

import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import NotificationDto from '@horse-auction/common/types/notification.dto';
import NotificationAPI from 'src/api/NotificationAPI';

const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [notificationsHttpState, notificationsRequest] = useHttp([]);
  const [markNotificationsAsReadHttpState, markNotificationsAsReadRequest] = useHttp([]);
  const { user, accessToken } = useContext(AuthContext);

  const { socket, socketId } = useContext(SocketContext);

  const getNotifications = useCallback(() => {
    if (accessToken) {
      return notificationsRequest(() => NotificationAPI.getNotifications());
    }
    return Promise.resolve([]);
  }, [notificationsRequest, accessToken]);

  const markNotificationsAsRead = useCallback(
    (notificationIds) =>
      markNotificationsAsReadRequest(() => NotificationAPI.markAsRead(notificationIds)),
    [markNotificationsAsReadRequest]
  );

  const updateNotifications = useCallback((notificationsArray: NotificationDto[]) => {
    const notificationsWithId = notificationsArray.map((notification) => ({
      ...notification,
      id: notification?._id,
    }));
    setNotifications(notificationsWithId);
  }, []);

  useEffect(() => {
    if (user?.role === 'CUSTOMER') {
      getNotifications().then(updateNotifications);
    }
  }, [getNotifications, updateNotifications, user?.role]);

  // socketId as dependency because it changes upon new socket connection, so reestablishing socket subscription is necessery
  useEffect(() => {
    if (socket && user?.role === 'CUSTOMER') {
      socket.on('notifications-change', updateNotifications);
    }

    return () => {
      socket?.off('notifications-change', updateNotifications);
    };
  }, [socket, updateNotifications, user?.role, socketId]);

  return {
    notifications,
    notificationsHttpState,
    markNotificationsAsRead,
    markNotificationsAsReadHttpState,
  };
};

export default useNotifications;
