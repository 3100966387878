import { httpGet } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getUserOrganizationByAuction = (auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpGet('/user-organizations/auction', payload));
};

const UserOrganizationAPI = {
  getUserOrganizationByAuction,
};

export default UserOrganizationAPI;
