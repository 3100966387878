import 'src/locales/i18n';

import { useTranslation } from 'react-i18next';

import { AppButton } from '@horse-auction/common/components';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import styled from 'styled-components/macro';

interface Props {
  previousLink: string;
  nextLink: string;
}

export const PreviousNextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PreviousNextLotNav = ({ nextLink, previousLink }: Props) => {
  const { t } = useTranslation();

  return (
    <PreviousNextContainer>
      <AppButton
        to={previousLink}
        size='large'
        color='primary'
        variant='text'
        startIcon={<MdArrowBack />}
        disabled={!previousLink}
      >
        {t<string>('auction:lotDetail.previous')}
      </AppButton>
      <AppButton
        to={nextLink}
        size='large'
        color='primary'
        endIcon={<MdArrowForward />}
        variant='text'
        disabled={!nextLink}
      >
        {t<string>('auction:lotDetail.next')}
      </AppButton>
    </PreviousNextContainer>
  );
};

export default PreviousNextLotNav;
