import AppDateTimePicker, {
  Props as AppDateTimePickerProps,
} from '../../AppDateTimePicker/AppDateTimePicker';
import withController from './withController';

interface CustomProps {
  defaultValue?: Date;
}

type Props = CustomProps & Partial<Omit<AppDateTimePickerProps, 'fieldObject'>>;

const FormDateTimePicker: React.FC<Props> = ({ fullWidth = true, defaultValue, ...props }: Props) =>
  withController({ fullWidth, defaultValue, ...props })(AppDateTimePicker);

export default FormDateTimePicker;
