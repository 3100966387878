import { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageContainer } from '@horse-auction/common/components';
import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import EditorDisplay from '@horse-auction/common/components/EditorDisplay/EditorDisplay';
import useHttp from '@horse-auction/common/hooks/useHttp';
import AuctionTermsDto from '@horse-auction/common/types/auctionTerms.dto';
import { Breadcrumb } from '@horse-auction/common/types/Breadcrumb';
import { Paper } from '@mui/material';
import AuctionTermsAPI from 'src/api/AuctionTermsAPI';
import styled from 'styled-components/macro';

interface UrlParams {
  auctionId: string;
}

const breadcrumbs: Breadcrumb[] = [
  {
    key: 'home',
    label: 'Home',
    link: '/',
  },
  {
    key: 'terms',
    label: 'Auction terms',
    link: '',
    active: true,
  },
];

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    flex: 1;
    padding: 3rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .MuiButton-root {
    margin-left: 1rem;
  }
`;

const AuctionTermsPage = () => {
  const { t } = useTranslation();
  const { auctionId } = useParams<UrlParams>();
  const [auctionTermsHttpState, auctionTermsRequest] = useHttp(null);
  const [auctionTerms, setAuctionTerms] = useState<AuctionTermsDto>();

  const getAuctionTerms = useCallback(() => {
    auctionTermsRequest(() => AuctionTermsAPI.getAuctionTerms(auctionId)).then(
      (auctionTermsResponse: AuctionTermsDto) => {
        setAuctionTerms(auctionTermsResponse);
      }
    );
  }, [auctionTermsRequest, auctionId]);

  useEffect(() => {
    getAuctionTerms();
  }, [getAuctionTerms]);

  return (
    <PageContainer breadcrumbs={breadcrumbs} title='Auction terms'>
      <StyledPaper>
        <HeaderWrapper>
          <ButtonsWrapper>
            <AppButton to='/' size='large' color='primary' variant='outlined'>
              {t<string>('auctionTerms:home')}
            </AppButton>
            <AppButton
              to={`/auctions/${auctionId}`}
              size='large'
              color='primary'
              variant='outlined'
            >
              {t<string>('auctionTerms:goToAuction')}
            </AppButton>
          </ButtonsWrapper>
        </HeaderWrapper>
        <EditorDisplay text={auctionTerms?.terms || ''} />
      </StyledPaper>
    </PageContainer>
  );
};

export default AuctionTermsPage;
