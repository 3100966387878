/* eslint react/jsx-props-no-spreading: 0 */

import { Trans, useTranslation } from 'react-i18next';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import StyledLink from '@horse-auction/common/components/StyledLink/StyledLink';
import { Alert } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  auctionId: string;
  isLoading: boolean;
  onAccept: () => void;
}

const StyledAlert = styled(Alert)`
  margin: 1rem 0;

  &.MuiAlert-root {
    align-items: center;

    .MuiAlert-message {
      width: 100%;
    }
  }
`;

const AcceptTermsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiButton-root {
    max-width: 200px;
    min-width: 80px;
  }
`;

const TermsStyledLink = styled(StyledLink)``;

const TermsLabel = styled.div`
  margin-right: 1rem;
`;

const AcceptAuctionTerms = ({ auctionId, isLoading, onAccept }: CustomProps) => {
  const { t } = useTranslation(['bid']);

  return (
    <StyledAlert severity='warning'>
      <AcceptTermsWrapper>
        <TermsLabel>
          <Trans i18nKey='auction:auctionAlerts.acceptAuctionTerms'>
            <TermsStyledLink target='_blank' to={`/auction-terms/${auctionId}`} />
          </Trans>
        </TermsLabel>
        <AppButton size='large' color='warning' onClick={onAccept}>
          {t<string>('auction:auctionAlerts.acceptTermsButton')}
        </AppButton>
      </AcceptTermsWrapper>
    </StyledAlert>
  );
};

export default AcceptAuctionTerms;
