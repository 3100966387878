import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';

import styled from 'styled-components/macro';

import { variants } from '../../themes/customTheme';

interface CustomProps {
  fontBig?: string;
  variant: string;
}

interface Props extends CustomProps, NavLinkProps {}

export const StyledLink = styled(RouterLink)<Props>`
  text-decoration: none;
  background-color: transparent;
  color: ${({ variant }) => variants[variant].default};
  white-space: nowrap;
  line-height: 1.2;
  font-weight: 500;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${({ variant }) => variants[variant].dark};
    color: black;
  }

  &:visited {
    color: ${({ variant }) => variants[variant].default};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
