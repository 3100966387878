import { httpGet } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getAuctionTerms = (auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpGet('/auction-terms', payload));
};

const AuctionTermsAPI = {
  getAuctionTerms,
};

export default AuctionTermsAPI;
