import { Controller, useFormContext } from 'react-hook-form';
import AppCheckbox, { Props as AppCheckboxProps } from '../../AppCheckbox/AppCheckbox';

type Props = AppCheckboxProps & { name: string };

const RHFCheckbox = ({ name, ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const helperText = (errors?.[name]?.message as string) || '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <AppCheckbox helperText={helperText} {...rest} {...field} error={!!error} />
      )}
    />
  );
};

export default RHFCheckbox;
