import { useCallback, useContext, useEffect, useState, PropsWithChildren } from 'react';

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { css } from 'styled-components';

import {
  AppBackdrop,
  AppButton,
  ResponsivePage,
  StyledLink,
} from '@horse-auction/common/components';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import useBreakpoint from '@horse-auction/common/hooks/useBreakpoint';
import { device } from '@horse-auction/common/themes/device';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Breadcrumbs, Paper } from '@mui/material';
import { MdFormatListNumbered, MdLiveTv } from 'react-icons/md';
import AuctionAlerts from 'src/components/AuctionAlerts/AuctionAlerts';
import BiddingConsole from 'src/components/BiddingConsole/BiddingConsole';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

import LotDetail from './components/LotDetail';
import LotMedia from './components/LotMedia';
import LotsNav from './components/LotsNav';
import PreviousNextLotNav from './components/PreviousNextLotNav';
import AuctionFooter from '../../components/AuctionFooter/AuctionFooter';

export type LotPageGridType = 'STANDARD' | 'LIST_OF_LOTS' | 'STREAM' | 'ONLINE_LIVE';

type LotPageMode = 'LOTS' | 'STREAM';

interface ModeSwitchButtonProps {
  pageMode: LotPageMode;
  onLotSelected: () => void;
  onStreamMode: () => void;
}

interface ContainerProps extends PropsWithChildren {
  gridType: LotPageGridType;
}

interface PageModeButtonPaperProps {
  pageMode: LotPageMode;
}

interface LayoutProps extends PropsWithChildren {
  layout: boolean;
}

interface NavGridDivProps extends PropsWithChildren {
  visible: boolean;
  layout: boolean;
}

interface RouteProps {
  lotId: string;
}

const scroll = Scroll.animateScroll;

const streamGrid = css`
  grid-template-columns: 1fr 450px;
  grid-template-areas: 'video console' 'detail console';

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: 'video' 'console' 'detail';
  }
`;

const listOfLotsGrid = css`
  grid-template-columns: 300px 2fr 450px;
  grid-template-areas: 'nav detail video' 'nav detail console';

  @media ${device.laptop} {
    grid-template-columns: 100px 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 100px 1fr 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-areas: 'detail';
  }
`;

const onlineLiveGrid = css`
  grid-template-columns: 300px 2fr 450px;
  grid-template-areas: 'nav detail console';

  @media ${device.laptop} {
    grid-template-columns: 100px 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 100px 1fr 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-areas: 'console' 'detail';
  }
`;

const standardGrid = css`
  grid-template-columns: 300px 1fr;
  grid-template-areas: 'nav detail';

  @media ${device.tablet} {
    grid-template-columns: 100px 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-areas: 'detail';
  }
`;

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  width: 100%;

  ${({ gridType }) => {
    if (gridType === 'LIST_OF_LOTS') {
      return listOfLotsGrid;
    }
    if (gridType === 'ONLINE_LIVE') {
      return onlineLiveGrid;
    }
    if (gridType === 'STREAM') {
      return streamGrid;
    }
    return standardGrid;
  }};
`;

const VideoGridDiv = styled(motion.div)`
  grid-area: video;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const PageModeButtonPaper = styled(Paper)<PageModeButtonPaperProps>`
  /* padding: 0.5rem 0; */
  display: flex;
  justify-content: center;
  align-items: center;

  &.MuiPaper-root {
    border-radius: 4px 4px 0 0;
  }

  @media ${device.mobile} {
    ${({ pageMode }) => (pageMode === 'LOTS' ? 'margin-bottom: 1rem;' : 'margin-bottom: 0;')}
  }
`;

const ConsoleGridDiv = styled.div<LayoutProps>`
  grid-area: console;
`;

const NavGridDiv = styled.div<NavGridDivProps>`
  grid-area: nav;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const DetailGridDiv = styled.div<LayoutProps>`
  grid-area: detail;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiBreadcrumbs-root {
    margin-bottom: 1rem;
  }
`;

const BiddingConsolePaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem 1rem;
  flex: 1;
`;

const useQuery = () => new URLSearchParams(useLocation().search);

const ModeSwitchButton = ({ pageMode, onLotSelected, onStreamMode }: ModeSwitchButtonProps) => {
  const { t } = useTranslation();

  return (
    <PageModeButtonPaper pageMode={pageMode}>
      {pageMode === 'STREAM' && (
        <AppButton
          variant='text'
          color='info'
          fullWidth
          startIcon={<MdFormatListNumbered />}
          onClick={onLotSelected}
        >
          {t<string>('bid:header.listOfLotsButton')}
        </AppButton>
      )}
      {pageMode === 'LOTS' && (
        <AppButton
          variant='text'
          color='info'
          fullWidth
          startIcon={<MdLiveTv />}
          onClick={onStreamMode}
        >
          {t<string>('bid:header.streamMode')}
        </AppButton>
      )}
    </PageModeButtonPaper>
  );
};

const LotPage = () => {
  const { lotId } = useParams<RouteProps>();
  const breakpoint = useBreakpoint();
  const history = useHistory();

  const query = useQuery();
  const pageModeParam = query.get('mode');

  const { user } = useContext(AuthContext);
  const { auction, auctionHttpState, currentLot, userAuction, userOrganization, previewToken } =
    useContext(AuctionContext);

  const [selectedLot, setSelectedLot] = useState<LotDto | undefined>();
  const [previousLotId, setPreviousLotId] = useState<string | undefined>();
  const [nextLotId, setNextLotId] = useState<string | undefined>();
  const [pageMode, setPageMode] = useState<LotPageMode>('LOTS');
  const [gridType, setGridType] = useState<LotPageGridType>('STANDARD');

  const getSelectedLot = useCallback(
    () => auction?.lots?.find((lot) => lot._id === lotId) || auction?.lots?.[0],
    [lotId, auction]
  );
  const getNextLotId = useCallback(
    (currentLotId?: string) => {
      const lotsQuantity = auction?.lotsQuantity;
      const currentLotIndex = auction?.lots?.findIndex((lot) => lot._id === currentLotId) || 0;
      let nextLotIdValue;
      if (lotsQuantity && currentLotIndex >= 0 && currentLotIndex + 1 < lotsQuantity) {
        nextLotIdValue = auction?.lots?.[currentLotIndex + 1]._id;
      }
      return nextLotIdValue;
    },
    [auction]
  );

  const lotSelectedClickHandler = useCallback(
    (selectedLotId) => {
      history.push(`/auctions/${auction?._id}/lots/${selectedLotId}`);
    },
    [auction, history]
  );

  const streamModeHandler = useCallback(() => {
    let lotIdRedirection = auction?.lots?.[0]?._id;
    if (auction?.status === 'IN_PROGRESS') {
      lotIdRedirection = currentLot?._id || auction?.lots?.[0]?._id;
    }
    let urlRedirection = `/auctions/${auction?._id}`;
    if (lotIdRedirection) {
      urlRedirection = `/auctions/${auction?._id}/lots/${lotIdRedirection}?mode=stream`;
    }
    history.push(urlRedirection);
  }, [auction, history, currentLot]);

  const getPreviousLotId = useCallback(
    (currentLotId?: string) => {
      const currentLotIndex = auction?.lots?.findIndex((lot) => lot._id === currentLotId);
      let previousLotIdValue;
      if (currentLotIndex && currentLotIndex >= 0 && currentLotIndex - 1 >= 0) {
        previousLotIdValue = auction?.lots?.[currentLotIndex - 1]._id;
      }
      return previousLotIdValue;
    },
    [auction]
  );

  const getPreviousLink = useCallback(() => {
    if (auction?._id && previousLotId) {
      if (previewToken) {
        return `/auctions/${auction?._id}/lots/${previousLotId}?preview=${previewToken}`;
      }
      return `/auctions/${auction?._id}/lots/${previousLotId}`;
    }
    return '';
  }, [previousLotId, auction, previewToken]);

  const getNextLink = useCallback(() => {
    if (auction?._id && nextLotId) {
      if (previewToken) {
        return `/auctions/${auction?._id}/lots/${nextLotId}?preview=${previewToken}`;
      }
      return `/auctions/${auction?._id}/lots/${nextLotId}`;
    }
    return '';
  }, [nextLotId, auction, previewToken]);

  const navRendered = useCallback(
    () => breakpoint !== 'mobile' && pageMode === 'LOTS',
    [pageMode, breakpoint]
  );

  const consoleRendered = useCallback(
    () =>
      (breakpoint !== 'mobile' || pageMode === 'STREAM' || auction?.type === 'ONLINE') &&
      auction?.status === 'IN_PROGRESS',
    [breakpoint, pageMode, auction?.status, auction?.type]
  );

  const videoRendered = useCallback(
    () =>
      (breakpoint !== 'mobile' || pageMode === 'STREAM') &&
      auction?.type === 'HYBRID' &&
      auction?.status === 'IN_PROGRESS',
    [breakpoint, auction?.type, auction?.status, pageMode]
  );

  const getGridType = useCallback(() => {
    if (auction?.status === 'IN_PROGRESS') {
      if (auction?.type === 'ONLINE') {
        return 'ONLINE_LIVE';
      }
      if (pageMode === 'STREAM') {
        return 'STREAM';
      }
      return 'LIST_OF_LOTS';
    }
    return 'STANDARD';
  }, [auction?.type, auction?.status, pageMode]);

  useEffect(() => {
    let pageModeOption: LotPageMode = 'LOTS';
    if (
      auction?.type === 'HYBRID' &&
      auction?.status === 'IN_PROGRESS' &&
      pageModeParam === 'stream'
    ) {
      pageModeOption = 'STREAM';
    }
    setPageMode(pageModeOption);
  }, [auction?.type, auction?.status, pageModeParam]);

  useEffect(() => {
    if (history?.location?.search === '?mode=stream' && currentLot) {
      history.push(`/auctions/${auction?._id}/lots/${currentLot?._id}?mode=stream`);
    }
  }, [currentLot, history, auction?._id]);

  useEffect(() => {
    const newSelectedLot = getSelectedLot();
    const newPreviousLotid = getPreviousLotId(newSelectedLot?._id);
    const newNextLotId = getNextLotId(newSelectedLot?._id);
    setSelectedLot(newSelectedLot);
    setPreviousLotId(newPreviousLotid);
    setNextLotId(newNextLotId);
  }, [auction, lotId, getSelectedLot, getNextLotId, getPreviousLotId]);

  useEffect(() => {
    scroll.scrollToTop();
  }, [lotId]);

  useEffect(() => {
    const gridTypeTemp = getGridType();
    setGridType(gridTypeTemp);
  }, [getGridType]);

  return (
    <ResponsivePage>
      <AppBackdrop isLoading={auctionHttpState?.isLoading}>
        <StyledBreadcrumbs separator='›' aria-label='breadcrumb'>
          <StyledLink to='/'>Home</StyledLink>
          <StyledLink to='/auctions'>Auctions</StyledLink>
          <StyledLink
            to={
              previewToken
                ? `/auctions/${auction?._id}?preview=${previewToken}`
                : `/auctions/${auction?._id}`
            }
          >
            {auction?.title || 'Auction'}
          </StyledLink>
          <StyledLink active>{selectedLot?.product?.name || 'Lot'}</StyledLink>
        </StyledBreadcrumbs>
        <AuctionAlerts />

        <Container gridType={gridType}>
          <NavGridDiv layout visible={navRendered()}>
            <LotsNav selectedLotId={selectedLot?._id} lotsQuantity={auction?.lotsQuantity} />
          </NavGridDiv>

          <DetailGridDiv layout>
            <LotDetail
              lot={selectedLot}
              lotsQuantity={auction?.lotsQuantity}
              streamView={pageMode === 'STREAM'}
              previousLink={getPreviousLink()}
              nextLink={getNextLink()}
            />
          </DetailGridDiv>

          {videoRendered() && (
            <VideoGridDiv layout>
              <ModeSwitchButton
                pageMode={pageMode}
                onLotSelected={() => lotSelectedClickHandler(currentLot?._id)}
                onStreamMode={streamModeHandler}
              />
              <LotMedia
                url={auction?.streamUrl}
                available={!!auction?.streamUrl && auction?.status === 'IN_PROGRESS'}
              />
            </VideoGridDiv>
          )}

          {consoleRendered() && (
            <ConsoleGridDiv layout>
              <BiddingConsolePaper>
                {auction?.type === 'ONLINE' && (
                  <>
                    <PreviousNextLotNav previousLink={getPreviousLink()} nextLink={getNextLink()} />
                    <BiddingConsole
                      lot={selectedLot}
                      noForm={
                        (user?.role && ['MODERATOR', 'AUCTIONEER']?.includes(user?.role)) ||
                        userAuction?.blocked === true ||
                        userOrganization?.blocked === true
                      }
                    />
                  </>
                )}
                {auction?.type === 'HYBRID' && (
                  <BiddingConsole
                    lot={currentLot}
                    noForm={
                      (user?.role && ['MODERATOR', 'AUCTIONEER']?.includes(user?.role)) ||
                      userAuction?.blocked === true ||
                      userOrganization?.blocked === true
                    }
                  />
                )}
              </BiddingConsolePaper>
            </ConsoleGridDiv>
          )}
        </Container>
        {auction?._id && <AuctionFooter auctionId={auction._id} />}
      </AppBackdrop>
    </ResponsivePage>
  );
};

export default LotPage;
