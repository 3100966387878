import { Controller, useFormContext } from 'react-hook-form';
import AppTextField, { Props as AppTextFieldProps } from '../../AppTextField/AppTextField';

type Props = AppTextFieldProps & { name: string; validationTrigger?: string[] };

const RHFInputText = ({ fullWidth = true, validationTrigger, onKeyPress, ...props }: Props) => {
  const {
    control,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext();

  const helperText = (errors?.[props.name]?.message as string) || '';

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <AppTextField
          fullWidth={fullWidth}
          helperText={helperText}
          {...props}
          {...field}
          error={!!error}
          onChange={async (e) => {
            field.onChange(e);

            if (isSubmitted && validationTrigger && validationTrigger.length > 0) {
              await trigger(validationTrigger);
            }
          }}
        />
      )}
    />
  );
};

export default RHFInputText;
