import { useTranslation } from 'react-i18next';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import styled from 'styled-components/macro';

interface Props {
  onPayNow: () => void;
  onBackToAuction: () => void;
  onCancel: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Description = styled(AppTypography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonRow = styled.div`
  padding-top: 1rem;
`;

const PaymentFailureContent = ({ onPayNow, onBackToAuction, onCancel }: Props) => {
  const { t } = useTranslation(['payments']);

  return (
    <Container>
      <Description variant='body1'>
        {t<string>('payments:paymentOutcomeDialog.failure.paymentFailedDescription')}
      </Description>
      <ButtonRow>
        <AppButton fullWidth color='primary' variant='outlined' onClick={onCancel}>
          {t<string>('payments:paymentOutcomeDialog.failure.closeButton')}
        </AppButton>
      </ButtonRow>
    </Container>
  );
};

export default PaymentFailureContent;
