import { createGlobalStyle } from 'styled-components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AppTheme } from './customTheme';

interface GlobalStylesProps {
  theme: AppTheme;
}

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  body {
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.5+9++0s linear;
  }

  a {

  text-decoration: none;
  
    &:visited {
    color: inherit;
    text-decoration: none;
  }
  }

// TODO: create AppIconButton
  .MuiIconButton-root {
    border-radius: 3% !important;
  }
`;

export default GlobalStyles;
