import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AppButton, ConfirmationDialog } from '@horse-auction/common/components';
import { ButtonProps } from '@mui/material';

import { CustomProps as ConfirmationDialogCustomProps } from '../ConfirmationDialog/ConfirmationDialog';

interface CustomProps {
  buttonLabel?: string;
  buttonSize?: 'small' | 'medium' | 'large';
}

interface Props extends CustomProps, ButtonProps, ConfirmationDialogCustomProps {}

const ConfirmationButton: React.FC<Props> = ({
  title,
  startIcon,
  description,
  confirmationPhrase,
  variant = 'contained',
  color = 'primary',
  buttonLabel,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  buttonSize = 'large',
  isLoading,
  disabled,
  onConfirm,
  onCancel,
}: Props) => {
  const { t } = useTranslation(['components']);
  const [dialogOpen, setDialogOpen] = useState(false);

  const buttonClickHandler = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const confirmHandler = useCallback(() => {
    onConfirm(null);
    setDialogOpen(false);
  }, [onConfirm]);

  const cancelHandler = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <AppButton
        onClick={buttonClickHandler}
        size={buttonSize}
        variant={variant}
        color={color}
        startIcon={startIcon}
        disabled={disabled}
      >
        {buttonLabel}
      </AppButton>
      <ConfirmationDialog
        open={dialogOpen}
        title={title}
        description={description}
        confirmationPhrase={confirmationPhrase}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        isLoading={isLoading}
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
      />
    </>
  );
};

export default ConfirmationButton;
