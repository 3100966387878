import { device } from '@horse-auction/common/themes/device';
import PedigreeDto from '@horse-auction/common/types/pedigree.dto';
import { IoMdFemale, IoMdMale } from 'react-icons/io';
import styled from 'styled-components/macro';

interface CustomProps {
  ancestorType: string;
  noBorder: boolean;
}

interface HorseWrapperProps {
  area: string;
  noBorder: boolean;
}

interface PedigreeDisplayProps {
  pedigree: PedigreeDto;
}

interface Props extends CustomProps, PedigreeDisplayProps {}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(8, 4rem);
  grid-template-areas:
    'sire sireSire sireSireSire'
    'sire sireSire sireSireDam'
    'sire sireDam sireDamSire'
    'sire sireDam sireDamDam'
    'dam damSire damSireSire'
    'dam damSire damSireDam'
    'dam damDam damDamSire'
    'dam damDam damDamDam';
  justify-items: stretch;
  align-items: stretch;
  width: 100%;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

const HorseWrapper = styled.div<HorseWrapperProps>`
  grid-area: ${({ area }) => area};
  border-bottom: ${({ noBorder }) => (noBorder ? '' : '3px solid #E2E4E9')};
  display: flex;
  align-items: center; ;
`;

const IconWrapper = styled.div``;

const HorseName = styled.div`
  place-self: center stretch;
  margin-left: 0.5rem;
  overflow-wrap: anywhere;
`;

const Ancestor: React.FC<Props> = ({ pedigree, ancestorType, noBorder }: Props) => (
  <HorseWrapper area={ancestorType} noBorder={noBorder}>
    <IconWrapper>
      {ancestorType.endsWith('ire') && <IoMdMale />}
      {ancestorType.endsWith('am') && <IoMdFemale />}
    </IconWrapper>
    <HorseName>{pedigree?.[ancestorType]?.name || 'NN'}</HorseName>
  </HorseWrapper>
);

const PedigreeDisplay: React.FC<PedigreeDisplayProps> = ({ pedigree }: PedigreeDisplayProps) => {
  if (!pedigree) {
    return null;
  }
  return (
    <Container>
      <Ancestor ancestorType='sire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='dam' noBorder pedigree={pedigree} />
      <Ancestor ancestorType='sireSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='sireDam' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damDam' noBorder pedigree={pedigree} />
      <Ancestor ancestorType='sireSireSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='sireSireDam' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='sireDamSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='sireDamDam' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damSireSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damSireDam' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damDamSire' pedigree={pedigree} noBorder={false} />
      <Ancestor ancestorType='damDamDam' noBorder pedigree={pedigree} />
    </Container>
  );
};

export default PedigreeDisplay;
