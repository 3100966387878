import { httpPost, httpGet } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

import { SignupDto } from '../types/signup.dto';

const login = (email: string, password: string, recaptchaToken: string) => {
  const payload = {
    email,
    password,
    recaptchaToken,
  };

  return CancellablePromise(httpPost('/auth/login', payload));
};

const magicLinkLogin = (token: string) => {
  const payload = {
    token,
  };

  return CancellablePromise(httpPost(`/auth/login/magic`, payload));
};

const signupNatural = (signupDto: SignupDto) => {
  const payload = {
    ...signupDto,
  };

  return CancellablePromise(httpPost('/auth/signup-natural', payload));
};

const signupLegal = (signupDto: SignupDto) => {
  const payload = {
    ...signupDto,
  };

  return CancellablePromise(httpPost('/auth/signup-legal', payload));
};

const activateAccount = (token: string) => {
  const payload = {
    token,
  };

  return CancellablePromise(httpPost(`/auth/activate`, payload));
};

const logout = () => CancellablePromise(httpGet('/auth/logout'));

const verifyPhone = ({
  mobilePhone,
  email,
  recaptchaToken,
}: {
  mobilePhone: string;
  email: string;
  recaptchaToken: string;
}) => {
  const payload = {
    mobilePhone,
    email,
    recaptchaToken,
  };

  return CancellablePromise(httpPost('/auth/verify-phone', payload));
};

const changePassword = (oldPassword: string, newPassword: string, repeatNewPassword: string) => {
  const payload = {
    oldPassword,
    newPassword,
    repeatNewPassword,
  };

  return CancellablePromise(httpPost('/auth/change-password', payload));
};

const forgotPassword = (email: string) => {
  const payload = {
    email,
  };

  return CancellablePromise(httpPost('/auth/forgot-password', payload));
};

const resetPassword = (password: string, token: string) => {
  const payload = {
    password,
    token,
  };

  return CancellablePromise(httpPost('/auth/reset-password', payload));
};

const AuthAPI = {
  login,
  magicLinkLogin,
  signupNatural,
  signupLegal,
  activateAccount,
  verifyPhone,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
};

export default AuthAPI;
