import { useTranslation } from 'react-i18next';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import { Paper } from '@mui/material';
import styled from 'styled-components/macro';

import StyledLink from '../StyledLink/StyledLink';

const Container = styled.footer`
  margin-top: auto;
  background-color: transparent;
`;

const StyledPaper = styled(Paper)`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  width: 100%;

  a,
  p {
    justify-self: center;
  }
`;

const WebsiteRights = styled.small`
  padding-top: 0.5rem;
  color: ${({ theme }) => theme.colors.text};
`;

const Footer = () => {
  const { t } = useTranslation(['footer']);

  return (
    <Container>
      <StyledPaper elevation={0} square>
        <LinksWrapper>
          <StyledLink to='/how-to-bid'>{t<string>('footer:howToBid')}</StyledLink>
          <StyledLink to='/terms'>{t<string>('footer:terms')}</StyledLink>
          <AppTypography>info@equibid.pl</AppTypography>
          <AppTypography>+48 ......</AppTypography>
        </LinksWrapper>
        <WebsiteRights>Equibid © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
      </StyledPaper>
    </Container>
  );
};

export default Footer;
