import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import alertEn from '@horse-auction/common/locales/en/alert.json';
import commonComponentsEn from '@horse-auction/common/locales/en/components.json';
import countriesEn from '@horse-auction/common/locales/en/countries.json';
import dictionaryEn from '@horse-auction/common/locales/en/dictionary.json';
import navbarEn from '@horse-auction/common/locales/en/navbar.json';
import countriesPl from '@horse-auction/common/locales/pl/countries.json';
import navbarPl from '@horse-auction/common/locales/pl/navbar.json';

import accountEn from './en/account.json';
import auctionEn from './en/auction.json';
import auctionsEn from './en/auctions.json';
import auctionTermsEn from './en/auctionTerms.json';
import authEn from './en/auth.json';
import bidEn from './en/bid.json';
import componentsEn from './en/components.json';
import howToBidEn from './en/howToBid.json';
import moderateEn from './en/moderate.json';
import moderatorEn from './en/moderator.json';
import paymentsEn from './en/payments.json';
import purchasesEn from './en/purchases.json';
import signupEn from './en/signup.json';
import startEn from './en/start.json';
import termsEn from './en/terms.json';
import validationEn from './en/validation.json';
import accountPL from './pl/account.json';
import signupPl from './pl/signup.json';
import startPl from './pl/start.json';
import validationPl from './pl/validation.json';

const initializeI18next = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: false },
      resources: {
        en: {
          account: accountEn,
          auction: auctionEn,
          auctions: auctionsEn,
          auctionTerms: auctionTermsEn,
          auth: authEn,
          bid: bidEn,
          countries: countriesEn,
          components: { ...commonComponentsEn, ...componentsEn },
          dictionary: dictionaryEn,
          howToBid: howToBidEn,
          moderate: moderateEn,
          moderator: moderatorEn,
          alert: alertEn,
          navbar: navbarEn,
          payments: paymentsEn,
          purchases: purchasesEn,
          signup: signupEn,
          start: startEn,
          terms: termsEn,
          validation: validationEn,
        },
        pl: {
          account: accountPL,
          countries: countriesPl,
          navbar: navbarPl,
          signup: signupPl,
          start: startPl,
          validation: validationPl,
        },
      },
      fallbackLng: 'en',
    });

  i18n.changeLanguage('en');
};

export default initializeI18next;
