import { useEffect, useCallback, useContext, useState } from 'react';

import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import IEventObject from '@horse-auction/common/types/IEventObject';
import AuctionAPI from 'src/api/AuctionAPI';

const statusOrder = { IN_PROGRESS: 0, SCHEDULED: 1, ENDED: 2, CANCELED: 2 };
const typeOrder = { HYBRID: 0, ONLINE: 1 };

const useUpcomingAuctions = () => {
  const [eventObject, setEventObject] = useState<IEventObject | null>(null);

  const [auction, setAuction] = useState<AuctionDto>();
  const [auctions, setAuctions] = useState<AuctionDto[]>();
  const [auctionsHttpState, auctionsRequest] = useHttp();
  const { socketId, subscribeEvent, unsubscribeEvent } = useContext(SocketContext);

  const updateAuction = useCallback((auctionResponse) => {
    setAuction(auctionResponse);
  }, []);

  const getUpcomingAuction = useCallback(() => {
    auctionsRequest(() => AuctionAPI.getUpcomingAuctions()).then(
      (auctionResponse: AuctionDto[]) => {
        auctionResponse?.sort((a, b) => {
          const compareStatus = (statusOrder[a.status] || 0) - (statusOrder[b?.status] || 0);
          if (compareStatus === 0 && a?.status === 'IN_PROGRESS') {
            return (typeOrder?.[a?.type] || 0) - (typeOrder?.[b?.type] || 0);
          }
          return compareStatus;
        });
        setAuctions(auctionResponse);
      }
    );
  }, [auctionsRequest]);

  useEffect(() => {
    if (socketId) {
      getUpcomingAuction();
    }
  }, [getUpcomingAuction, socketId]);

  useEffect(() => {
    if (eventObject?.auctionId !== auction?._id) {
      unsubscribeEvent(eventObject, updateAuction);
      const eventName = 'auction-update';
      const eventKey = `auction-update-${auction?._id}`;
      const newEventObject = {
        name: eventName,
        type: 'AUCTION',
        auctionId: auction?._id,
        key: eventKey,
      };
      setEventObject(newEventObject);
      subscribeEvent(newEventObject, updateAuction);
    }
    return () => {
      unsubscribeEvent(eventObject, updateAuction);
    };
  }, [unsubscribeEvent, subscribeEvent, updateAuction, eventObject, auction]);

  return {
    auctionsHttpState,
    auctions,
  };
};

export default useUpcomingAuctions;
