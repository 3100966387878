import { useCallback, useContext } from 'react';
import 'src/locales/i18n';

import { useTranslation } from 'react-i18next';

import {
  PedigreeDisplay,
  AppTypography,
  LotHeader,
  Slideshow,
  LabeledData,
} from '@horse-auction/common/components';
import { device } from '@horse-auction/common/themes/device';
import LotDto from '@horse-auction/common/types/lot.dto';
// import types from '@horse-auction/common/types/types';
import { Link, Paper } from '@mui/material';
import BiddingConsole from 'src/components/BiddingConsole/BiddingConsole';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

import LotDescription from './LotDescription';
import LotFigures from './LotFigures';
import PreviousNextLotNav from './PreviousNextLotNav';

interface Props {
  lot?: LotDto;
  lotsQuantity?: number;
  nextLink: string;
  previousLink: string;
  streamView: boolean;
}

export const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem 1rem 2rem;

  @media ${device.mobile} {
    padding: 0.5rem 1rem;
  }
`;

export const BiddingConsoleWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const LotList = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LotFiguresContainer = styled.div`
  margin-bottom: 1rem;
`;

const LotDetail = ({
  lot,
  lotsQuantity = 0,
  nextLink,
  previousLink,
  streamView = false,
}: Props) => {
  const { t } = useTranslation();

  const { auction } = useContext(AuctionContext);

  const slideshowRendered = useCallback(
    () =>
      streamView !== true &&
      (!!lot?.product?.images?.length || !!lot?.product?.videoUrls?.length) &&
      (lot?.product?.images?.length > 0 || lot?.product?.videoUrls?.length > 0),
    [streamView, lot?.product]
  );

  const biddingRendered = useCallback(() => lot?.status === 'ENDED', [lot?.status]);

  return (
    <StyledPaper>
      {lot && (
        <>
          {(nextLink || previousLink) && (
            <PreviousNextLotNav nextLink={nextLink} previousLink={previousLink} />
          )}

          <LotHeader lot={lot} lotsQuantity={auction?.lotsQuantity || 0} />
          {slideshowRendered() && (
            <Slideshow images={lot?.product?.images} videoUrls={lot?.product?.videoUrls} />
          )}
          <LotFiguresContainer>
            <LotFigures lot={lot} lotsQuantity={lotsQuantity} noOrderRow={streamView} />
          </LotFiguresContainer>
          {biddingRendered() && (
            <BiddingConsoleWrapper>
              <BiddingConsole lot={lot} readOnly noHeader />
            </BiddingConsoleWrapper>
          )}
          <LotDescription lot={lot} />
          {lot?.product?.pedigree && (
            <>
              <AppTypography variant='h5'>
                {t<string>('auction:lotDescription.pedigree')}
              </AppTypography>
              {lot?.product?.pedigreeLink && (
                <LabeledData label={t<string>('auction:lotDescription.pedigreeLink')}>
                  <Link
                    href={lot?.product?.pedigreeLink}
                    target='_blank'
                    style={{ overflowWrap: 'anywhere' }}
                  >
                    {lot?.product?.pedigreeLink}
                  </Link>
                </LabeledData>
              )}
              <PedigreeDisplay pedigree={lot?.product?.pedigree} />
            </>
          )}
        </>
      )}
    </StyledPaper>
  );
};

export default LotDetail;
