import { useCallback, useContext, useRef, useState } from 'react';

import axios, { AxiosError } from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import FormButton from '@horse-auction/common/components/Form/components/FormButton';
import FormTextField from '@horse-auction/common/components/Form/components/FormTextField';
import Form from '@horse-auction/common/components/Form/Form';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import { device } from '@horse-auction/common/themes/device';
import { Alert, Collapse } from '@mui/material';
import AuthAPI from 'src/api/AuthAPI';
import styled from 'styled-components/macro';

interface Props {
  redirectPath: string;
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const FormAlert = styled(Alert)`
  margin: 1rem 0;

  &.MuiAlert-standardError {
  }
`;

const ReCAPTCHAContainer = styled.div`
  margin: 1rem 0;
  position: relative;
  height: 76px;

  @media ${device.mobile} {
    transform: scale(0.8);
    transform-origin: 0 0;
  }

  > div {
    position: absolute;
  }
`;

const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

const schema = yup.object().shape({
  email: yup.string().required('Please enter your email').email('Please enter valid email'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Must contain 8 characters, one uppercase, one lowercase, one number'
    ),
});

const LoginForm = ({ redirectPath }: Props) => {
  const authContext = useContext(AuthContext);
  const { socket, browserSessionId } = useContext(SocketContext);

  const { t } = useTranslation('auth');
  const history = useHistory();

  const [loginState, loginRequest] = useHttp(null);
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');
  const [recaptchaRequired, setRecaptchaRequired] = useState(false);

  const recaptchaRef = useRef<any>();

  const recaptchaChangeHandler = useCallback((value) => {
    setRecaptchaToken(value);
  }, []);

  const onSubmit = useCallback(
    (formData) => {
      setFormErrorMessage(null);

      loginRequest(() => AuthAPI.login(formData.email, formData.password, recaptchaToken))
        .then((response: any) => {
          const JWT = response.access?.token;
          axios.defaults.headers.common.Authorization = `Bearer ${JWT} }), `;
          authContext.updateUser(response.user);
          authContext.updateAccessToken(JWT);
          if (redirectPath) {
            history.push(redirectPath);
          }
          recaptchaRef.current?.reset();
          setRecaptchaToken('');
          setRecaptchaRequired(false);

          socket?.emit('newLogin', browserSessionId);
        })
        .catch((error: AxiosError) => {
          const name = error?.response?.data?.name;
          const message = error?.response?.data?.message;
          recaptchaRef.current?.reset();
          setRecaptchaToken('');
          if (name === 'INCORRECT_EMAIL_PASSWORD') {
            setFormErrorMessage(t('auth:login.incorrectLoginCredentials'));
          }
          if (name === 'RECAPTCHA_VALIDATION_FAILURE') {
            setFormErrorMessage(message);
            setRecaptchaRequired(true);
          }
          if (name === 'RECAPTCHA_REQUIRED') {
            setRecaptchaRequired(true);
          }
        });
    },
    [loginRequest, recaptchaToken, authContext, redirectPath, socket, browserSessionId, history, t]
  );

  return (
    <StyledForm schema={schema}>
      <Collapse in={!!formErrorMessage}>
        <FormAlert severity='error'>{formErrorMessage}</FormAlert>
      </Collapse>
      <FormTextField name='email' label={t<string>('auth:login.emailLabel')} type='text' />
      <FormTextField
        name='password'
        label={t<string>('auth:login.passwordLabel')}
        type='password'
      />
      {recaptchaRequired && recaptchaPublicKey && (
        <ReCAPTCHAContainer>
          <ReCAPTCHA
            sitekey={recaptchaPublicKey}
            ref={recaptchaRef}
            onChange={recaptchaChangeHandler}
          />
        </ReCAPTCHAContainer>
      )}
      <FormButton
        size='large'
        isLoading={loginState.isLoading}
        onClick={onSubmit}
        disabled={recaptchaRequired && !recaptchaToken}
      >
        {t<string>('auth:login.loginButton')}
      </FormButton>
    </StyledForm>
  );
};

export default LoginForm;
