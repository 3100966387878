import { useState, useContext, useEffect } from 'react';

import NotificationDto from '@horse-auction/common/types/notification.dto';
import PurchaseDto from '@horse-auction/common/types/purchase.dto';
import { PurchasesContext } from 'src/context/PurchasesContext';

import PurchasesWonDialog from '../PurchasesWonDialog/PurchasesWonDialog';

interface CustomProps {
  notifications: NotificationDto[];
  open: boolean;
  isLoading: boolean;
  onCancel: () => void;
}

const LotNotificationDialog = ({ notifications = [], open, isLoading, onCancel }: CustomProps) => {
  const { purchases } = useContext(PurchasesContext);
  const [purchasesWon, setPurchasesWon] = useState<PurchaseDto[]>([]);

  useEffect(() => {
    const purchasesWonArray =
      purchases?.filter((purch) => notifications.some((notif) => notif.purchaseId === purch._id)) ||
      [];
    setPurchasesWon(purchasesWonArray);
  }, [notifications, purchases]);

  return (
    <PurchasesWonDialog
      open={open}
      purchaseObligations={purchasesWon}
      isLoading={isLoading}
      onCancel={onCancel}
    />
  );
};

export default LotNotificationDialog;
