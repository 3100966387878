import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Chip, ChipProps } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  status?: string;
  margin?: string;
}

interface Props extends CustomProps, ChipProps {}

const StyledChip = styled(Chip)<CustomProps>`
  &.MuiChip-root {
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    ${({ margin }) => margin && `margin: ${margin}`};
  }
`;

const LotChip: React.FC<Props> = ({
  status,
  variant = 'outlined',
  size = 'medium',
  margin,
}: Props) => {
  const { t } = useTranslation();

  const getLabel = useCallback(() => {
    if (status === 'SCHEDULED') {
      return t('components:lotChip.scheduledChip');
    }
    if (status === 'UPCOMING') {
      return t('components:lotChip.upcomingChip');
    }
    if (status === 'IN_PROGRESS') {
      return t('components:lotChip.inProgressChip');
    }
    if (status === 'LOT_SOLD') {
      return t('components:lotChip.soldChip');
    }
    if (status === 'ENDED') {
      return t('components:lotChip.endedChip');
    }
    if (status === 'CANCELED') {
      return t('components:lotChip.canceledChip');
    }
    return '';
  }, [status, t]);

  const getColor = useCallback(() => {
    if (status === 'SCHEDULED') {
      return 'primary';
    }
    if (status === 'IN_PROGRESS') {
      return 'secondary';
    }
    if (status === 'LOT_SOLD' || status === 'UPCOMING') {
      return 'warning';
    }
    return 'default';
  }, [status]);

  const label = getLabel();
  if (!label) {
    return null;
  }

  return (
    <StyledChip size={size} label={label} color={getColor()} variant={variant} margin={margin} />
  );
};

export default LotChip;
