import { useCallback, useState } from 'react';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  .MuiTextField-root {
    margin-top: 1rem;
  }
`;

interface CustomProps {
  isLoading?: boolean;
  fieldObj?: FieldObject;
}

export type Props = CustomProps & Partial<DatePickerProps<Date, Date>> & Partial<TextFieldProps>;

const AppDatePicker: React.FC<Props> = ({
  label,
  required,
  error,
  helperText,
  disabled,
  fullWidth,
  isLoading = false,
  inputFormat = 'dd/MM/yyyy',
  disablePast,
  disableFuture,
  fieldObj,
}: Props) => {
  // };
  const [value, setValue] = useState(fieldObj?.value || null);
  const { ref, onChange: fieldObjOnChange, ...fieldObjRest } = fieldObj || {};

  const changeHadler = useCallback(
    (val) => {
      setValue(val);
      fieldObjOnChange?.(val);
    },
    [fieldObjOnChange]
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Wrapper>
      <DatePicker
        label={label}
        disabled={isLoading || disabled}
        inputFormat={inputFormat}
        disablePast={disablePast}
        disableFuture={disableFuture}
        {...fieldObjRest}
        value={value}
        onChange={changeHadler}
        inputRef={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={fullWidth}
            helperText={helperText}
            disabled={isLoading || disabled}
            required={required}
            error={error}
          />
        )}
      />
    </Wrapper>
  );
};

export default AppDatePicker;
