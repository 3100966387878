import BidDto from '@horse-auction/common/types/bid.dto';
import Increment from '@horse-auction/common/types/Increment';
import LotDto from '@horse-auction/common/types/lot.dto';

const getIncrement = (price: number, increments: Increment[]) => {
  let increment = { limit: 0, increment: 1 };
  if (increments?.length === 1) {
    increment = increments?.[0];
  }
  if (increments?.length > 1) {
    increments.forEach((incrementObj, index) => {
      const floor = incrementObj?.limit;
      const ceiling =
        index < increments?.length - 1 ? increments[index + 1]?.limit : Number.MAX_SAFE_INTEGER;
      if (price < ceiling && price >= floor) {
        increment = incrementObj;
      }
    });
  }

  return increment;
};

const getIncrementAdjustedPrice = (price: number, increments: Increment[]) => {
  const increment = getIncrement(price, increments);
  let adjustedPrice = price;

  if ((price - increment?.limit) % increment?.increment !== 0) {
    adjustedPrice =
      increment?.limit +
      increment?.increment * Math.ceil((price - increment?.limit) / increment?.increment);
  }
  return adjustedPrice;
};

const calculateNextAvailablePrice = (
  lot: LotDto,
  price: number,
  auctionStartingPrice: number,
  increments: Increment[]
) => {
  const startPrice = lot?.startingPrice || auctionStartingPrice || 0;
  const incrementAdjustedStartPrice = getIncrementAdjustedPrice(startPrice, increments);

  const incrementAdjustedLatestPrice = getIncrementAdjustedPrice(price, increments);
  const nextAfterLatestPrice =
    incrementAdjustedLatestPrice +
    getIncrement(incrementAdjustedLatestPrice, increments)?.increment;

  const nextPrice = Math.max(nextAfterLatestPrice, incrementAdjustedStartPrice);

  return nextPrice;
};

const calculatePreviousAvailablePrice = (
  lot: LotDto,
  price: number,
  auctionStartingPrice: number,
  increments: Increment[]
) => {
  const startPrice = lot?.startingPrice || auctionStartingPrice || 0;
  const minIncrement = getIncrement(price - 1, increments)?.increment;

  const highestAvailablePrice = Math.max(price - minIncrement, startPrice);

  return highestAvailablePrice;
};

const calculateLotRequiredMinPrice = (
  lot: LotDto,
  bids: BidDto[],
  auctionStartingPrice: number,
  increments: Increment[]
) => {
  const bidPricesArray = bids?.map((bid) => bid.price);

  const highestBidPrice =
    bidPricesArray && bidPricesArray.length > 0 ? Math.max(...bidPricesArray) : 0;

  return calculateNextAvailablePrice(lot, highestBidPrice, auctionStartingPrice, increments);
};

const calculateTwoLotMinPrices = (
  lot: LotDto,
  bids: BidDto[],
  auctionStartingPrice: number,
  increments: Increment[]
) => {
  const nextPrice = calculateLotRequiredMinPrice(lot, bids, auctionStartingPrice, increments);
  const nextMinIncrement = getIncrement(nextPrice, increments)?.increment;

  const secondNextPrice = nextPrice + nextMinIncrement;

  return [nextPrice, secondNextPrice];
};

export {
  getIncrement,
  getIncrementAdjustedPrice,
  calculateLotRequiredMinPrice,
  calculateTwoLotMinPrices,
  calculatePreviousAvailablePrice,
  calculateNextAvailablePrice,
};
