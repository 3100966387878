import { useContext } from 'react';

import { AlertCollapse } from '@horse-auction/common/components';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

interface CustomProps {
  top?: string;
}

const Container = styled.div<CustomProps>`
  width: 100%;
  position: sticky;
  top: ${({ top }) => top || '4rem'};
  z-index: 10;

  @media ${device.mobile} {
    top: ${({ top }) => top || '3.5rem'};
  }

  .MuiCollapse-root {
    width: 100%;
  }

  .MuiPaper-root {
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;

const ConnectionLostAlert: React.FC<CustomProps> = ({ top = '4rem' }: CustomProps) => {
  const { connected } = useContext(SocketContext);

  return (
    <Container top={top}>
      <AlertCollapse message='Connection lost' rendered={connected === false} severity='error' />
    </Container>
  );
};

export default ConnectionLostAlert;
