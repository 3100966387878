import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import genericHorse from '@horse-auction/common/assets/images/equibidLogo.png';
import { AppTypography, LotChip, StyledLink } from '@horse-auction/common/components/';
import { getImageUrl } from '@horse-auction/common/services/RequestService';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { ImListNumbered } from 'react-icons/im';
import styled from 'styled-components/macro';

interface CustomProps {
  auction: AuctionDto;
  isLoading: boolean;
  logoKey?: string;
}

interface AuctionTypeDiv {
  type: string;
}

const StyledCardActionArea = styled(CardActionArea)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 0.25rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
  gap: 0.5rem;
  text-align: center;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const AuctionType = styled.div<AuctionTypeDiv>`
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;

  span {
    color: ${({ theme, type }) => {
      if (type === 'HYBRID') {
        return theme?.palette?.primary?.main;
      }
      return '#9a4c71';
    }};
    font-weight: 700;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LotsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LotsTypography = styled(AppTypography)`
  &.MuiTypography-root {
    margin-left: 1rem;
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  position: relative;
  word-break: break-all;
  height: 100%;

  &.MuiCard-root {
    width: 100%;
    transition: transform 0.4s;

    &:hover {
      transform: scale(1.03);
    }
  }
`;

const AuctionCard: React.FC<CustomProps> = ({ auction, isLoading, logoKey }: CustomProps) => {
  const { t } = useTranslation(['components']);

  const getImagePath = useCallback(
    (imageKey) => (imageKey ? getImageUrl(`/${imageKey}`) : genericHorse),
    []
  );

  return (
    <StyledCard>
      <StyledLink to={`/auctions/${auction?._id}`} noUnderline>
        <StyledCardActionArea>
          <TitleWrapper>
            <ImageWrapper>
              <StyledImage
                alt={auction?.title || 'horse'}
                src={getImagePath(logoKey || auction?.logo?.key)}
              />
            </ImageWrapper>
            <AppTypography variant='h5' lineHeight='32px' paddingX='1rem'>
              {auction?.title}
            </AppTypography>
          </TitleWrapper>
          <CardContent>
            {auction?.type === 'HYBRID' && (
              <AuctionType type='HYBRID'>
                <>
                  <span>{t<string>('components:auctionCard.onliveAuction1')}</span>{' '}
                  {t<string>('components:auctionCard.onliveAuction2')}{' '}
                </>
              </AuctionType>
            )}
            {auction?.type === 'ONLINE' && (
              <AuctionType type='ONLINE'>
                <>
                  <span>{t<string>('components:auctionCard.onlineAuction1')}</span>{' '}
                  {t<string>('components:auctionCard.onlineAuction2')}{' '}
                </>
              </AuctionType>
            )}
            <InfoWrapper>
              <LotsWrapper>
                <ImListNumbered />
                <LotsTypography variant='body1'>
                  {auction?.lotIds?.length || 0} {t<string>('components:auctionCard.lots')}
                </LotsTypography>
              </LotsWrapper>
              <LotChip status={auction?.status} size='small' />
            </InfoWrapper>
          </CardContent>
        </StyledCardActionArea>
      </StyledLink>
    </StyledCard>
  );
};

export default AuctionCard;
