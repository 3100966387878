/* eslint-disable react/jsx-wrap-multilines */
import { useCallback, PropsWithChildren } from 'react';

import { differenceInSeconds } from 'date-fns';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import BidDto from '@horse-auction/common/types/bid.dto';
import { ListItem, ListItemText, ListItemProps } from '@mui/material';
import { orange } from '@mui/material/colors';
import { RiAuctionFill } from 'react-icons/ri';
import styled from 'styled-components/macro';

interface CustomProps {
  bid: BidDto;
  index: number;
  size: string;
}

interface ListItemCustomProps {
  size: string;
  canceled: boolean;
  highlighted: boolean;
}

interface StyledListItemProps extends ListItemCustomProps, ListItemProps {}

interface StyledDivWithSizeProps extends PropsWithChildren {
  size: string;
}

const StyledListItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['highlighted'].includes(prop) && defaultValidatorFn(prop),
})<StyledListItemProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({ size }) => {
    if (size === 'large') {
      return `
          grid-column-gap: 3rem;
      `;
    }

    if (size === 'medium') {
      return `
          grid-column-gap: 1rem;`;
    }
    return '';
  }}

  &.MuiListItem-root {
    ${({ canceled }) => {
      if (canceled) {
        return `
        text-decoration: line-through;
        opacity: 0.5;
        font-style: italic;
      `;
      }
      return '';
    }};
  }

  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-size: ${({ size }) => (size === 'large' ? '48px' : '32px')};
  }

  &.MuiListItem-root:nth-child(1) {
    ${({ highlighted }) => {
      if (highlighted) {
        return `
          animation: fade 10s forwards;
      `;
      }
      return '';
    }};
    padding: 4px;

    .MuiTypography-root {
      font-weight: 700;
      font-size: ${({ size }) => (size === 'large' ? '84px' : '40px')};

      span {
        white-space: normal;
      }
    }
  }
  @keyframes fade {
    from {
      background-color: ${orange[200]};
    }
    to {
      background-color: none;
    }
  }
  &.MuiListItem-root:nth-child(odd) {
    background-color: ${({ theme }) => {
      if (theme.name === 'dark') {
        return 'rgba(0, 0, 0, 0.4)';
      }
      return 'rgba(0, 0, 0, 0.1)';
    }};
  }
`;

const BidIconsWrapper = styled.div<StyledDivWithSizeProps>`
  margin-right: ${({ size }) => (size === 'large' ? '1rem' : '0')};
`;

const StyledListItemAmount = styled(ListItemText)`
  &.MuiListItemText-root {
  }
`;

const AmountContainer = styled.div<StyledDivWithSizeProps>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: ${({ size }) => (size === 'large' ? 'row' : 'column-reverse')};
`;

const StyledListItemName = styled(ListItemText)`
  &.MuiListItemText-root {
    overflow-wrap: anywhere;
  }
`;

const BidderWrapper = styled.div`
  display: flex;
  overflow: auto;
`;

const FlagWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 1rem;
`;

const InhouseLabel = styled.span`
  font-weight: 500;
`;

const CountryLabel = styled.span`
  white-space: nowrap;
`;

const biddingStatusIconMap = {
  PLACED: [],
  GOING_ONCE: ['a'],
  GOING_TWICE: ['a', 'b'],
  SOLD: ['a', 'b', 'c'],
};

const BidsListItemLarge = ({ bid, index = -1, size = 'large' }: CustomProps) => {
  const { t } = useTranslation(['bid']);

  const isHighlighted = useCallback(
    (bidTime) => {
      if (index > 0 || !bidTime) {
        return false;
      }
      const bidDate = new Date(bidTime);
      const now = new Date();
      const secondsSinceBid = differenceInSeconds(now, bidDate);

      return secondsSinceBid < 10;
    },
    [index]
  );

  return (
    <StyledListItem
      key={bid?._id}
      alignItems='flex-start'
      highlighted={isHighlighted(bid?.createdAt)}
      size={size}
      canceled={bid?.canceled}
    >
      <StyledListItemAmount
        primary={
          <AmountContainer size={size}>
            {index === 0 && (
              <BidIconsWrapper size={size}>
                {biddingStatusIconMap?.[bid.biddingStatus]?.map((iconKey) => (
                  <RiAuctionFill
                    size={size === 'large' ? '48px' : '24px'}
                    key={iconKey}
                    color='#9a4c71'
                  />
                ))}
              </BidIconsWrapper>
            )}
            <NumberFormat
              value={bid.price}
              displayType='text'
              thousandSeparator=' '
              prefix={`${bid.currency} `}
            />
          </AmountContainer>
        }
      />
      <StyledListItemName
        primary={
          <BidderWrapper>
            {bid?.inhouse !== true && (
              <>
                <FlagWrapper>
                  {bid?.userCountry && (
                    <ReactCountryFlag svg countryCode={bid?.userCountry} aria-label='country' />
                  )}
                </FlagWrapper>

                <CountryLabel>{bid?.userCountry}</CountryLabel>
              </>
            )}
            {bid?.inhouse === true && (
              <InhouseLabel>{t<string>('bid:biddingConsole.inhouseLabel')}</InhouseLabel>
            )}
          </BidderWrapper>
        }
      />
    </StyledListItem>
  );
};

export default BidsListItemLarge;
