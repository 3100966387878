import { createContext } from 'react';

import { useParams } from 'react-router-dom';

import AuctionDto from '@horse-auction/common/types/auction.dto';
import LotDto from '@horse-auction/common/types/lot.dto';
import UserDto from '@horse-auction/common/types/user.dto';
import useAuction from 'src/hooks/useAuction';
import useModeratorUsers from 'src/hooks/useModeratorUsers';

interface ContextProps {
  children: React.ReactNode;
}

interface UrlParams {
  auctionId: string;
}

export type IAuctionContext = {
  auction?: AuctionDto;
  auctionHttpState?: any;
  currentLot?: LotDto;
  nextLot?: LotDto;
  customers: UserDto[];
  customersState: any;
  blockUser: (userId: string) => void;
  unblockUser: (userId: string) => void;
};

const ModeratorAuctionContext = createContext<IAuctionContext>({
  customers: [],
  customersState: null,
  blockUser: () => undefined,
  unblockUser: () => undefined,
});

const ModeratorAuctionProvider = ({ children }: ContextProps) => {
  const { auctionId } = useParams<UrlParams>();
  const { auction, auctionHttpState, currentLot, nextLot } = useAuction(auctionId, 'MODERATOR', '');
  const { customers, customersState, blockUser, unblockUser } = useModeratorUsers(auctionId);

  return (
    <>
      <ModeratorAuctionContext.Provider
        value={{
          auction,
          auctionHttpState,
          currentLot,
          nextLot,
          customers,
          customersState,
          blockUser,
          unblockUser,
        }}
      >
        {children}
      </ModeratorAuctionContext.Provider>
    </>
  );
};

export { ModeratorAuctionContext, ModeratorAuctionProvider };
