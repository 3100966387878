import { useEffect, useCallback, useContext, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import UserAuctionDto from '@horse-auction/common/types/userAuction.dto';
import UserAuctionAPI from 'src/api/UserAuctionAPI';

interface RouteParams {
  auctionId: string;
}

const useUserAuction = () => {
  const { user } = useContext(AuthContext);

  const { auctionId } = useParams<RouteParams>();

  const [userAuction, setUserAuction] = useState<UserAuctionDto>();
  const [userAuctionHttpState, userAuctionRequest] = useHttp();
  const [acceptAuctionTermsHttpState, acceptAuctionTermsRequest] = useHttp();

  const { socket, socketId } = useContext(SocketContext);

  const getUserAuction = useCallback(() => {
    if (auctionId) {
      userAuctionRequest(() => UserAuctionAPI.getUserAuction(auctionId)).then(
        (dataObj: UserAuctionDto) => {
          setUserAuction(dataObj);
        }
      );
    }
  }, [userAuctionRequest, auctionId]);

  const acceptAuctionTerms = useCallback(() => {
    if (auctionId) {
      acceptAuctionTermsRequest(() => UserAuctionAPI.acceptAuctionTerms(auctionId));
    }
  }, [acceptAuctionTermsRequest, auctionId]);

  useEffect(() => {
    if (user) {
      socket?.on('user-auction-change', (userAuctionObj) => {
        setUserAuction(userAuctionObj);
      });
    }
  }, [socket, getUserAuction, user]);

  useEffect(() => {
    if (user) {
      getUserAuction();
    }
  }, [auctionId, getUserAuction, socketId, user]);

  return {
    acceptAuctionTerms,
    acceptAuctionTermsHttpState,
    userAuction,
    userAuctionHttpState,
  };
};

export default useUserAuction;
