import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 0.5rem;
  width: 1600px;

  @media ${device.desktop} {
    width: 1400px;
  }

  @media ${device.laptop} {
    width: 1000px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 1rem 2rem;
  flex: 1;

  @media ${device.mobile} {
    padding: 0 1rem 1rem 1rem;
  }
`;

const ResponsivePage = ({ children }: Props) => (
  <Container>
    <PageWrapper>{children}</PageWrapper>
  </Container>
);

export default ResponsivePage;
