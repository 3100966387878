import { httpGet, httpPut } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getNotifications = () => {
  const payload = {};

  return CancellablePromise(httpGet('/notifications', payload));
};

const markAsRead = (notificationIds: string[]) => {
  const payload = {
    notificationIds,
  };

  return CancellablePromise(httpPut(`/notifications/read`, payload));
};

const NotificationAPI = { getNotifications, markAsRead };

export default NotificationAPI;
