import styled from 'styled-components/macro';

const TextDivider = styled.div`
  padding: 1rem 0;
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    background-color: ${({ theme }) => theme.colors.text};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 100px;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`;

export default TextDivider;
