import { useEffect, useCallback, useContext, useState } from 'react';

import { AlertContext } from '@horse-auction/common/context/AlertContext';
import { SocketContext } from '@horse-auction/common/context/SocketContext';
import useHttp from '@horse-auction/common/hooks/useHttp';
import useOnSocketReconnect from '@horse-auction/common/hooks/useOnSocketReconnect';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import IEventObject from '@horse-auction/common/types/IEventObject';
import LotDto from '@horse-auction/common/types/lot.dto';
import { TCancellablePromise } from '@horse-auction/common/utils/CancelablePromise';
import AuctionAPI from 'src/api/AuctionAPI';

const useAuction = (auctionId: string, role: string, previewToken: string) => {
  const [eventObject, setEventObject] = useState<IEventObject>();
  const [auction, setAuction] = useState<AuctionDto>();
  const [currentLot, setCurrentLot] = useState<LotDto>();
  const [nextLot, setNextLot] = useState<LotDto>();
  const [previousLot, setPreviousLot] = useState<LotDto>();

  const [auctionHttpState, auctionRequest] = useHttp();
  const { subscribeEvent, unsubscribeEvent } = useContext(SocketContext);
  const { createAPIErrorAlert } = useContext(AlertContext);

  const getAuction = useCallback(() => {
    if (auctionId) {
      let request!: () => TCancellablePromise;
      if (role === 'CUSTOMER') {
        request = () => AuctionAPI.getCustomerAuction(auctionId, previewToken);
      }
      if (role === 'MODERATOR' || role === 'AUCTIONEER') {
        request = () => AuctionAPI.getModeratorAuction(auctionId);
      }
      if (request) {
        auctionRequest(request)
          .then((auctionDto: AuctionDto) => {
            setAuction(auctionDto);
          })
          .catch((error: any) => {
            createAPIErrorAlert(error);
          });
      }
    }
  }, [auctionRequest, auctionId, createAPIErrorAlert, role, previewToken]);

  useOnSocketReconnect(getAuction);

  useEffect(() => {
    if (!auctionHttpState.initialized) {
      getAuction();
    }
  }, [auctionHttpState.initialized, getAuction]);

  const updateAuction = useCallback((auctionResponse) => {
    setAuction(auctionResponse);
  }, []);

  useEffect(() => {
    if (auction?.type === 'HYBRID' && auction?.lots) {
      const lotsCopy = [...auction?.lots];
      const previousLotObj = lotsCopy
        ?.filter((lot) => lot?.status === 'ENDED')
        ?.reduce((prev: LotDto, curr: LotDto) => {
          if (curr?.endDate && !prev) {
            return curr;
          }
          return curr?.endDate && new Date(curr?.endDate) > new Date(prev?.endDate) ? curr : prev;
        }, auction?.lots[0]);

      let currentLotObj = auction.lots?.find((lot) => lot?.status === 'IN_PROGRESS');
      if (!currentLotObj) {
        currentLotObj = auction.lots?.find((lot) => lot.status === 'UPCOMING');
      }
      if (!currentLotObj && previousLotObj) {
        currentLotObj = previousLotObj;
      }
      if (!currentLotObj) {
        currentLotObj = auction.lots?.find((lot) => lot.status === 'SCHEDULED');
      }

      // TODO: ts check if ok
      let nextLotIndex = auction.lots?.findIndex(
        (lot, index) => lot.status === 'SCHEDULED' && index > (currentLotObj?.auctionOrder || 0)
      );
      if (nextLotIndex < 0) {
        nextLotIndex = auction.lots?.findIndex((lot, index) => lot.status === 'SCHEDULED');
      }
      setCurrentLot(currentLotObj);
      setNextLot(auction.lots?.[nextLotIndex]);
      setPreviousLot(previousLotObj);
    }
  }, [auction]);

  useEffect(() => {
    if (eventObject?.auctionId !== auctionId) {
      unsubscribeEvent(eventObject, updateAuction);
      const eventName = 'auction-update';
      const eventKey = `auction-update-${auctionId}`;
      let eventType = 'AUCTION';
      if (role === 'MODERATOR' || role === 'AUCTIONEER') {
        eventType = 'MODERATOR_AUCTION';
      }
      const newEventObject = { name: eventName, type: eventType, auctionId, key: eventKey };
      setEventObject(newEventObject);
      subscribeEvent(newEventObject, updateAuction);
    }
    return () => {
      unsubscribeEvent(eventObject, updateAuction);
    };
  }, [unsubscribeEvent, subscribeEvent, updateAuction, eventObject, auctionId, role]);

  return {
    auction,
    auctionHttpState,
    previousLot,
    currentLot,
    nextLot,
  };
};

export default useAuction;
