import { format, utcToZonedTime } from 'date-fns-tz';

import { MdEventNote, MdSchedule } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  date: Date;
}

const DateDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const DateValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
`;

const DateDisplay: React.FC<CustomProps> = ({ date }: CustomProps) => {
  if (!date) {
    return null;
  }

  return (
    <DateDisplayWrapper>
      {' '}
      <DateWrapper>
        <MdEventNote />
        <DateValue>{format(utcToZonedTime(date, 'Europe/Warsaw'), 'dd MMMM yyyy')}</DateValue>
      </DateWrapper>
      <DateWrapper>
        <MdSchedule />
        <DateValue>
          {format(utcToZonedTime(date, 'Europe/Warsaw'), 'HH:mm zzz', {
            timeZone: 'Europe/Warsaw',
          })}
        </DateValue>
      </DateWrapper>
    </DateDisplayWrapper>
  );
};

export default DateDisplay;
