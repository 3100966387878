import { useTranslation } from 'react-i18next';

import { AppTypography } from '@horse-auction/common/components';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

import UpcomingAuctions from './components/UpcomingAuctions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2rem;
  justify-self: center;
`;

const HeaderTypography = styled(AppTypography)`
  &.MuiTypography-root {
    align-self: center;
  }

  @media ${device.tablet} {
    &.MuiTypography-root {
    }
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1200px;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: 1fr;

  @media ${device.laptop} {
    grid-template-columns: 800px;
  }

  @media ${device.tablet} {
    grid-template-columns: 700px;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const StartPage = () => {
  const { t } = useTranslation(['start', 'alerts']);

  return (
    <Container>
      <HeaderTypography variant='h4' gutterBottom>
        {t<string>('start:upcomingAuctions')}
      </HeaderTypography>
      <ContentGrid>
        <UpcomingAuctions />
      </ContentGrid>
    </Container>
  );
};

export default StartPage;
