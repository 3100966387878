import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

import colors from '../../themes/colorPallete';

const Container = styled.div`
  display: flex;
  margin: 0 0 10px 0;
`;

const StyledLabelWrapper = styled.label`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 500;
  color: #333;
  font-size: 0.875rem;
  color: ${({ theme }) => {
    if (theme.name === 'dark') {
      return colors.white;
    }
    return colors.grey[500];
  }};
`;

const Label = styled.div`
  padding: 0.5rem 0;
`;

const ErrorLabel = styled.div`
  color: red;
`;

const StyledInput = styled.input`
  padding: 0.75rem 1rem;
  border: ${({ error }) => (error ? '1px solid red' : '1px solid #e6e6e6')};
  border-radius: 7px;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.2;
  font-size: 1rem;
  background-color: ${({ theme }) => {
    if (theme.name === 'dark') {
      return colors.black;
    }
    return colors.white;
  }};

  &:-webkit-autofill {
    box-shadow: ${({ theme }) => {
      if (theme.name === 'dark') {
        return `0 0 0 30px ${colors.black} inset !important`;
      }
      return `0 0 0 30px ${colors.white} inset !important`;
    }};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
  }
  &:hover {
    border: ${({ error }) => (error ? '1px solid red' : '1px solid darkgray')};
  }
  &:focus {
    outline: none;
  }
`;

const Input = ({ id, label, type, required, error, register }) => (
  <Container>
    <StyledLabelWrapper htmlFor={id}>
      <Label>
        {label}
        {required && <span>*</span>}
      </Label>
      <StyledInput name={id} id={id} type={type} ref={register} error={error} />
      {error && <ErrorLabel>{error.message}</ErrorLabel>}
    </StyledLabelWrapper>
  </Container>
);

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  register: PropTypes.func,
};

Input.defaultProps = {
  id: '',
  label: '',
  type: 'text',
  required: false,
  error: null,
  register: () => {},
};

export default Input;
