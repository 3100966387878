import { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import UserDto from '@horse-auction/common/types/user.dto';
import { IconButton, Menu, MenuItem, Box } from '@mui/material';
import { MdAccountCircle } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  user: UserDto | null;
  onLogout?: () => void;
}

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    display: flex;
  }
`;

const StyledBox = styled(Box)`
  font-size: 1rem;
`;

const StyledMenu = styled(Menu)`
  .MuiPopover-paper {
    top: 57px !important;
    right: 1px !important;
    left: unset !important;
    background-color: white;
    color: black;
  }

  .MuiListItem-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const UserMenu: React.FC<CustomProps> = ({ user, onLogout }: CustomProps) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  const { t } = useTranslation(['navbar', 'alert']);

  const userMenuOpen = Boolean(userMenuAnchorEl);

  const userMenuId = 'user-menu';

  const history = useHistory();

  const accountPageClickHandler = useCallback(() => {
    history.push('/account');
  }, [history]);

  const purchasesPageClickHandler = useCallback(() => {
    history.push('/purchases');
  }, [history]);

  const userMenuIconClickHandler = useCallback((event) => {
    setUserMenuAnchorEl(event.currentTarget);
  }, []);

  const userMenuCloseHandler = useCallback(() => {
    setUserMenuAnchorEl(null);
  }, []);

  return (
    <>
      <StyledIconButton
        edge='end'
        aria-label='account of current user'
        aria-controls={userMenuId}
        aria-haspopup='true'
        onClick={userMenuIconClickHandler}
        color='inherit'
      >
        <StyledBox mr={1} display='flex' alignItems='center'>
          {user?.firstName || user?.companyName || user?.email}
        </StyledBox>
        <MdAccountCircle />
      </StyledIconButton>
      <StyledMenu
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={userMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={userMenuOpen}
        onClose={userMenuCloseHandler}
        onClick={userMenuCloseHandler}
      >
        {user && (
          <MenuItem onClick={accountPageClickHandler}>{t<string>('navbar:accountPage')}</MenuItem>
        )}
        {user?.role === 'CUSTOMER' && (
          <MenuItem onClick={purchasesPageClickHandler}>
            {t<string>('navbar:purchasesPage')}
          </MenuItem>
        )}
        <MenuItem onClick={onLogout}>{t<string>('navbar:logout')}</MenuItem>
      </StyledMenu>
    </>
  );
};

export default UserMenu;
