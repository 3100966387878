import { useState } from 'react';

// import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AppButton, FormTextField, Form } from '@horse-auction/common/components';
import {
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogProps,
} from '@mui/material';
import styled from 'styled-components/macro';

export interface CustomProps {
  description: string;
  confirmationPhrase?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  isLoading?: boolean;
  onConfirm: (formData: any) => void;
  onCancel?: () => void;
}

export interface Props extends CustomProps, DialogProps {}

const FormWrapper = styled.div`
  margin-top: 0.5rem;
`;

const Phrase = styled.span`
  font-weight: 700;
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }
`;

const ConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  confirmationPhrase,
  cancelLabel,
  confirmLabel,
  open,
  isLoading,
  onConfirm,
  onCancel,
}: Props) => {
  const { t } = useTranslation(['components']);
  const [confirmationDisabled, setConfirmationDisabled] = useState(!!confirmationPhrase);
  const watchChangeHandler = (values) => {
    const enteredPhrase = values?.[0];
    let isDisabled = true;
    if (enteredPhrase === confirmationPhrase) {
      isDisabled = false;
    }
    setConfirmationDisabled(isDisabled);
  };

  const schema = yup.object().shape({
    confirmationPhrase: yup.string().required(t('validation:required')),
  });

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {title || t<string>('components:confirmationDialog.title')}{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description || t<string>('components:confirmationDialog.description')}
        </DialogContentText>
        {confirmationPhrase && (
          <FormWrapper>
            <Form
              schema={schema}
              watchFields={['confirmationPhrase']}
              onWatchChange={watchChangeHandler}
            >
              <DialogContentText variant='body1'>
                <Trans
                  i18nKey='components:confirmationDialog.enterTextTypography'
                  values={{ confirmationPhrase }}
                >
                  <Phrase />
                </Trans>
              </DialogContentText>
              <FormTextField
                label={t<string>('components:confirmationDialog.enterTextField')}
                name='confirmationPhrase'
                defaultValue=''
                size='small'
                autoComplete='off'
              />
            </Form>
          </FormWrapper>
        )}
      </DialogContent>
      <StyledDialogActions>
        <AppButton onClick={onCancel} color='primary' variant='outlined'>
          {cancelLabel || t<string>('components:confirmationDialog.cancel')}
        </AppButton>
        <AppButton
          onClick={onConfirm}
          color='primary'
          autoFocus
          isLoading={isLoading}
          disabled={confirmationDisabled}
        >
          {confirmLabel || t<string>('components:confirmationDialog.confirm')}
        </AppButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
