import { useCallback, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import ReactCountryFlag from 'react-country-flag';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { animateScroll } from 'react-scroll';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppButton,
  RHFAutocomplete,
  RHFCheckbox,
  RHFPhoneField,
} from '@horse-auction/common/components';
import RHFInputText from '@horse-auction/common/components/Form/components/RHFTextField';
import Link from '@horse-auction/common/components/Link/Link';
import useCountries from '@horse-auction/common/hooks/useCountries';
import useHttp from '@horse-auction/common/hooks/useHttp';
import { device } from '@horse-auction/common/themes/device';
import { Collapse, Grid, Alert } from '@mui/material';
import styled from 'styled-components/macro';

import PhoneVerificationDialog from './PhoneVerificationDialog';
import AuthAPI from '../../../api/AuthAPI';
import { SignupDto } from '../../../types/signup.dto';

import 'react-phone-input-2/lib/material.css';

type Props = {
  customerType: string;
  onSuccess: () => void;
};

type Inputs = {
  email: string;
  mobilePhone: string;
  password: string;
  repeatPassword: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  termsAccepted: boolean;
  code?: string;
};

const CountryFlag = styled.div`
  margin-right: 1rem;
`;

const FormAlert = styled(Alert)`
  margin: 1rem 0;

  &.MuiAlert-standardError {
  }
`;

const ReCAPTCHAContainer = styled.div`
  margin: 1rem 0;
  position: relative;
  height: 76px;

  @media ${device.mobile} {
    transform: scale(0.8);
    transform-origin: 0 0;
  }

  > div {
    position: absolute;
  }
`;

const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupForm = ({ customerType, onSuccess }: Props) => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [verificationErrorMessage, setVerificationErrorMessage] = useState<string | null>();
  const [verificationVisible, setVerificationVisible] = useState(false);
  const [formData, setFormData] = useState<Omit<SignupDto, 'verificationCode'>>();

  const [signupState, signupRequest] = useHttp(null);
  const [verifyState, verifyRequest] = useHttp(null);

  const { countries } = useCountries();

  const { t } = useTranslation(['auth', 'countries']);

  const recaptchaRef = useRef<any>();

  const naturalCustomerSchemaFields = useCallback(
    () => ({
      firstName: yup.string().required(t('validation:required')),
      lastName: yup.string().required(t('validation:required')),
    }),
    [t]
  );

  const legalCustomerSchemaFields = useCallback(
    () => ({
      companyName: yup.string().required(t('validation:required')),
      vatId: yup.string().required(t('validation:required')),
    }),
    [t]
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().required(t('validation:required')).email(t('validation:email')),
        mobilePhone: yup
          .string()
          .required(t('validation:required'))
          .matches(phoneRegExp, t('validation:phone')),
        ...(customerType === 'NATURAL' && naturalCustomerSchemaFields()),
        ...(customerType === 'LEGAL' && legalCustomerSchemaFields()),
        password: yup
          .string()
          .required(t('validation:required'))
          .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, t('validation:password')),
        repeatPassword: yup
          .string()
          .required(t('validation:required'))
          .oneOf([yup.ref('password'), null], 'Passwords do not match'),
        address: yup.string().required(t('validation:required')),
        postalCode: yup.string().required(t('validation:required')),
        city: yup.string().required(t('validation:required')),
        country: yup.object().required(t('validation:required')).nullable(true),
        termsAccepted: yup
          .boolean()
          .required(t('validation:required'))
          .oneOf([true], t('validation:fieldMustBeChecked')),
        ...(verificationVisible && {
          code: yup
            .string()
            .required(t('validation:required'))
            .test('len', 'Must be exactly 6 characters', (val) => val?.length === 6),
        }),
      }),
    [customerType, legalCustomerSchemaFields, naturalCustomerSchemaFields, t, verificationVisible]
  );

  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const getParsedFormData = useCallback(
    (data: any) => {
      const signupDtoBase = {
        email: data.email,
        mobilePhone: data.mobilePhone,
        password: data.password,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
        country: data.country?.value,
        termsAccepted: data.termsAccepted,
      };

      if (customerType === 'NATURAL') {
        const naturalSignupProps = {
          firstName: data.firstName,
          lastName: data.lastName,
        };

        return { ...signupDtoBase, ...naturalSignupProps } as SignupDto;
      }

      const legalSignupProps = {
        companyName: data.companyName,
        vatId: data.vatId,
      };

      return { ...signupDtoBase, ...legalSignupProps } as SignupDto;
    },
    [customerType]
  );

  const submitVerification = useCallback(
    async (data: any) => {
      if (!recaptchaToken) {
        return;
      }

      setFormErrorMessage(null);
      const parsedFormData = getParsedFormData(data);
      setFormData(parsedFormData);

      verifyRequest(() =>
        AuthAPI.verifyPhone({
          mobilePhone: data.mobilePhone,
          email: data.email,
          recaptchaToken,
        })
      )
        .then(() => {
          setVerificationVisible(true);
          recaptchaRef.current.reset();
          setRecaptchaToken(null);
        })
        .catch((error: AxiosError) => {
          recaptchaRef.current.reset();
          setRecaptchaToken(null);

          const name = error?.response?.data?.name;
          const message = error?.response?.data?.message;

          if (name === 'REGISTER_SMS_TOO_MANY_REQUESTS') {
            setFormErrorMessage(message);
          }
          if (name === 'RECAPTCHA_VALIDATION_FAILURE') {
            setFormErrorMessage(message);
          }

          animateScroll.scrollToTop();
        });
    },
    [getParsedFormData, recaptchaToken, verifyRequest]
  );

  const submitForm = useCallback(
    async (data: any) => {
      setVerificationErrorMessage(null);
      const { verificationCode } = data;
      signupRequest(() => {
        let signupPromise;
        if (!formData) {
          return null;
        }
        const signupDto: SignupDto = {
          ...formData,
          verificationCode,
        };
        if (customerType === 'NATURAL') {
          signupPromise = AuthAPI.signupNatural(signupDto);
        }
        if (customerType === 'LEGAL') {
          signupPromise = AuthAPI.signupLegal(signupDto);
        }
        return signupPromise;
      })
        .then(() => {
          setVerificationVisible(false);
          setVerificationErrorMessage(null);
          onSuccess();
          animateScroll.scrollToTop();
        })
        .catch((error: AxiosError) => {
          const name = error?.response?.data?.name;
          const message = error?.response?.data?.message;
          let dialogVisible = false;

          if (name === 'INCORRECT_ACTIVATION_CODE') {
            setVerificationErrorMessage(message);
            dialogVisible = true;
          }
          if (['USERNAME_EMAIL_EXISTS', 'REGISTER_SMS_TOO_MANY_REQUESTS'].includes(name)) {
            setFormErrorMessage(message);
          }

          setVerificationVisible(dialogVisible);
          if (!dialogVisible) {
            recaptchaRef.current.reset();
            setRecaptchaToken(null);
            animateScroll.scrollToTop();
          }
        });
    },
    [customerType, formData, onSuccess, signupRequest]
  );

  const submitFormHandler = useCallback(
    (data) => {
      submitForm(data);
    },
    [submitForm]
  );

  const recaptchaChangeHandler = useCallback((value) => {
    setRecaptchaToken(value);
  }, []);

  const naturalCustomerFields = () => (
    <>
      <RHFInputText
        name='firstName'
        label={t<string>('auth:signup.naturalForm.firstName')}
        type='text'
      />
      <RHFInputText
        name='lastName'
        label={t<string>('auth:signup.naturalForm.lastName')}
        type='text'
      />
    </>
  );

  const legalCustomerFields = () => (
    <>
      <RHFInputText
        name='companyName'
        label={t<string>('auth:signup.legalForm.companyName')}
        type='text'
      />
      <RHFInputText name='vatId' label={t<string>('auth:signup.legalForm.vatId')} type='text' />
    </>
  );

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitVerification)}>
          <Collapse in={!!formErrorMessage}>
            <FormAlert severity='error'>{formErrorMessage}</FormAlert>
          </Collapse>
          <RHFInputText name='email' label={t<string>('auth:signup.legalForm.email')} type='text' />
          <RHFInputText
            name='password'
            label={t('Password')}
            type='password'
            validationTrigger={['password', 'repeatPassword']}
          />
          <RHFInputText
            name='repeatPassword'
            label={t<string>('auth:signup.legalForm.repeatPassword')}
            type='password'
            validationTrigger={['password', 'repeatPassword']}
          />
          {customerType === 'NATURAL' && naturalCustomerFields()}
          {customerType === 'LEGAL' && legalCustomerFields()}
          <RHFPhoneField
            name='mobilePhone'
            label={t<string>('auth:signup.legalForm.mobilePhone')}
          />
          <RHFInputText
            name='address'
            label={t<string>('auth:signup.legalForm.address')}
            type='text'
          />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <RHFInputText
                name='postalCode'
                label={t<string>('auth:signup.legalForm.postalCode')}
                type='text'
              />
            </Grid>
            <Grid item xs={8}>
              <RHFInputText
                name='city'
                label={t<string>('auth:signup.legalForm.city')}
                type='text'
              />
            </Grid>
          </Grid>
          <RHFAutocomplete
            name='country'
            label={t<string>('auth:signup.legalForm.country')}
            options={countries}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props}>
                <CountryFlag>
                  <ReactCountryFlag svg countryCode={option.value} aria-label='country' />
                </CountryFlag>
                {`${option.label} (${option.value})`}
              </li>
            )}
          />
          <RHFCheckbox
            name='termsAccepted'
            label={
              <span>
                {t<string>('auth:signup.terms1')}&nbsp;
                <Link to='/terms' target='_blank'>
                  {t<string>('auth:signup.termsLink')}
                </Link>
                .
              </span>
            }
          />
          {recaptchaPublicKey && (
            <ReCAPTCHAContainer>
              <ReCAPTCHA
                sitekey={recaptchaPublicKey}
                ref={recaptchaRef}
                onChange={recaptchaChangeHandler}
              />
            </ReCAPTCHAContainer>
          )}
          <AppButton
            disabled={!recaptchaToken}
            isLoading={verifyState.isLoading || signupState.isLoading}
            size='large'
            type='submit'
            fullWidth
          >
            {t<string>('auth:signup.signupButton')}
          </AppButton>
        </form>
      </FormProvider>
      <PhoneVerificationDialog
        open={verificationVisible}
        errorMessage={verificationErrorMessage}
        onSubmit={submitFormHandler}
        isLoading={signupState.isLoading}
        onClose={() => setVerificationVisible(false)}
      />
    </>
  );
};

export default SignupForm;
