import { httpPost } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const createNewsletterSubscriber = (email: string, recaptchaToken: string) => {
  const payload = {
    email,
    recaptchaToken,
  };

  return CancellablePromise(httpPost('/newsletters', payload));
};

const NewsletterSubscriberAPI = { createNewsletterSubscriber };

export default NewsletterSubscriberAPI;
