import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AlertCollapse, AppButton, TextTimer } from '@horse-auction/common/components/';
import useTimer from '@horse-auction/common/hooks/useTimer';
import { device } from '@horse-auction/common/themes/device';
import { MdPayment } from 'react-icons/md';
import { PurchasesContext } from 'src/context/PurchasesContext';
import styled from 'styled-components/macro';

const Container = styled.div`
  width: 100%;
  position: sticky;
  z-index: 10;

  @media ${device.mobile} {
  }

  .MuiCollapse-root {
    width: 100%;
  }

  .MuiPaper-root {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .MuiAlert-icon {
    align-self: center;
  }
`;

const Text = styled.div`
  padding-right: 1rem;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 0;

  @media ${device.tablet} {
    padding-top: 1rem;
  }
`;

const PaymentRequiredAlert = () => {
  const { t } = useTranslation();
  const { deadlineObligations, openPurchasesWonDialog } = useContext(PurchasesContext);

  const { ended } = useTimer(deadlineObligations?.[0]?.confirmationDeadline || new Date(), 1000);
  if (ended) {
    return null;
  }

  return (
    <Container>
      <AlertCollapse rendered={!!deadlineObligations?.[0]} severity='info'>
        <ContentWrapper>
          <Text>
            {`${t<string>('purchases:common:confirmationPaymentRequired')} `}
            {deadlineObligations?.[0]?.confirmationDeadline && (
              <TextTimer date={deadlineObligations?.[0]?.confirmationDeadline} />
            )}
          </Text>

          <ButtonWrapper>
            <AppButton
              onClick={() => openPurchasesWonDialog()}
              size='large'
              color='primary'
              variant='outlined'
              startIcon={<MdPayment />}
            >
              {t<string>('purchases:common.showPurchasesButton')}
            </AppButton>
          </ButtonWrapper>
        </ContentWrapper>
      </AlertCollapse>
    </Container>
  );
};

export default PaymentRequiredAlert;
