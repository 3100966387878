import { useContext } from 'react';

import logoHorizontalDark from '@horse-auction/common/assets/images/logo-horizontal-dark.png';
import logoHorizontalLight from '@horse-auction/common/assets/images/logo-horizontal-light.png';
import logoVerticalDark from '@horse-auction/common/assets/images/logo-vertical-dark.png';
import logoVerticalLight from '@horse-auction/common/assets/images/logo-vertical-light.png';
import { ThemeContext } from '@horse-auction/common/context/ThemeContext';
import { ThemeMode } from '@horse-auction/common/themes/customTheme';
import styled from 'styled-components/macro';

type Positioning = 'vertical' | 'horizontal';

export type LogoProps = {
  variant?: 'standard' | 'fullscreen';
  mode?: ThemeMode;
  onClick?: () => void;
  positioning: Positioning;
};

export const LogoWrapper = styled.div<Pick<LogoProps, 'variant' | 'onClick'>>`
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 3rem;
    ${({ variant }) => {
      if (variant === 'fullscreen') {
        return `
        height: 50vh;
      `;
      }
      return '';
    }}

    object-fit: contain;
    margin-right: 1rem;
  }
`;

const Logo = ({ variant = 'standard', positioning, mode, onClick }: LogoProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <LogoWrapper onClick={onClick} variant={variant}>
      {(mode || theme.name) === 'dark' ? (
        <img alt='logo' src={positioning === 'vertical' ? logoVerticalDark : logoHorizontalDark} />
      ) : (
        <img
          alt='logo'
          src={positioning === 'vertical' ? logoVerticalLight : logoHorizontalLight}
        />
      )}
    </LogoWrapper>
  );
};

export default Logo;
