import { useCallback, useState } from 'react';

import FieldObject from '@horse-auction/common/types/FieldObject';
import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import styled from 'styled-components/macro';

interface CustomProps {
  isLoading?: boolean;
  fieldObj: FieldObject;
}

export type Props = CustomProps &
  DateTimePickerProps<Date, Date> &
  Omit<TextFieldProps, 'onChange'>;

const Wrapper = styled.div`
  .MuiTextField-root {
    margin-top: 1rem;
  }
`;

const AppDateTimePicker: React.FC<Props> = ({
  label,
  required,
  error,
  size,
  helperText,
  disabled,
  fullWidth,
  isLoading = false,
  inputFormat = 'dd/MM/yyyy HH:mm',
  ampm = false,
  disablePast,
  maxDateTime,
  minDateTime,
  maxDate,
  minDate,
  fieldObj,
}: Props) => {
  const [value, setValue] = useState<Date | null>(fieldObj.value || null);
  const { ref, onChange: fieldObjOnChange, ...fieldObjRest } = fieldObj;

  const changeHadler = useCallback(
    (val: Date | null) => {
      setValue(val);
      console.log('val', val instanceof Date);
      console.log('val', val);
      fieldObjOnChange(val);
    },
    [fieldObjOnChange]
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Wrapper>
      <DateTimePicker
        label={label}
        disabled={isLoading || disabled}
        inputFormat={inputFormat}
        ampm={ampm}
        disablePast={disablePast}
        maxDateTime={maxDateTime}
        minDateTime={minDateTime}
        maxDate={maxDate}
        minDate={minDate}
        {...fieldObjRest}
        value={value}
        onChange={changeHadler}
        inputRef={ref}
        renderInput={(params) => (
          <TextField
            required={required}
            fullWidth={fullWidth}
            disabled={isLoading || disabled}
            {...params}
            {...fieldObjRest}
            helperText={helperText}
            error={error}
            size={size}
          />
        )}
      />
    </Wrapper>
  );
};

export default AppDateTimePicker;
