import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import PurchaseDto from '@horse-auction/common/types/purchase.dto';
import { DialogContent, DialogTitle, Dialog, IconButton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components/macro';

import StripePaymentIntentIFrame from './StripePaymentIntentIFrame';
import PaymentContent from '../PaymentContent/PaymentContent';

interface CustomProps {
  open: boolean;
  auction?: AuctionDto;
  purchase?: PurchaseDto | null;
  purchaseType?: string;
  stripeSecret?: string;
  onCancel: () => void;
  onError?: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
  }

  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-transform: uppercase;

  &.MuiDialogTitle-root {
    display: flex;
    align-items: center;
    padding-right: 3rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const IFrameWrapper = styled.div`
  margin-top: 1rem;
`;
let stripePromise: () => Promise<Stripe | null>;

const PaymentDialog = ({
  open = false,
  auction,
  purchase,
  purchaseType,
  stripeSecret,
  onCancel,
  onError,
}: CustomProps) => {
  const { t } = useTranslation(['components']);

  const [stripe, setStripe] = useState<Stripe>();

  useEffect(() => {
    if (open) {
      import('@stripe/stripe-js').then(async (stripeLib) => {
        stripePromise = () =>
          stripeLib.loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string, { locale: 'en' });
        const stripeObj = await stripePromise();
        if (stripeObj) {
          setStripe(stripeObj);
        }
      });
    }
  }, [open]);

  const getLabels = useCallback(() => {
    let title = t('components:paymentDialog.genericTitle');
    let description = t('components:paymentDialog.genericDescription');
    let productName = '';
    let totalPriceLabel = '';
    let priceLabel = '';
    if (purchaseType === 'ENTRANCE_DEPOSIT') {
      priceLabel = `${auction?.currency} ${auction?.entranceAmount}`;
      title = t('components:paymentDialog.entranceDeposit.title');
      description = t('components:paymentDialog.entranceDeposit.description', {
        price: priceLabel,
      });
      productName = `${auction?.title} (${t<string>(
        'components:paymentDialog.entranceDeposit.transferTitle'
      )})`;
      totalPriceLabel = `${auction?.currency} ${auction?.entranceAmount}`;
    }
    if (purchaseType === 'LOT' && purchase?.status === 'CONFIRMATION_REQUIRED') {
      title = t('components:paymentDialog.confirmationAmount.title');
      description = t('components:paymentDialog.confirmationAmount.description', {
        price: `${purchase?.lot?.auctionCurrency} ${purchase?.confirmationAmount}`,
      });
      productName = purchase?.lot?.product?.name;
      totalPriceLabel = `${purchase?.lot?.auctionCurrency} ${purchase?.lot?.finalPrice}`;
      priceLabel = `${purchase?.lot?.auctionCurrency} ${purchase?.confirmationAmount}`;
    }
    return { title, description, productName, totalPriceLabel, priceLabel };
  }, [t, purchase, purchaseType, auction]);

  const labels = getLabels();

  return (
    <StyledDialog maxWidth='md' open={open}>
      <StyledDialogTitle id='alert-dialog-title'>
        <AppTypography variant='h5' lineHeight='28px'>
          {labels.title}
        </AppTypography>
      </StyledDialogTitle>
      {onCancel ? (
        <StyledIconButton aria-label='close' onClick={onCancel}>
          <MdClear />
        </StyledIconButton>
      ) : null}
      <DialogContent>
        <PaymentContent labels={labels} purchaseType={purchaseType} isLoading={false} />
        {stripe && (
          <IFrameWrapper>
            <Elements stripe={stripe} options={{ clientSecret: stripeSecret }}>
              <StripePaymentIntentIFrame auction={auction} onCancel={onCancel} />
            </Elements>
          </IFrameWrapper>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default PaymentDialog;
