import { useEffect, useCallback, useContext, useState } from 'react';

import AuctionDto from 'types/auction.dto';

import { AuthContext } from '@horse-auction/common/context/AuthContext';

import { SocketContext } from '../context/SocketContext';
import useHttp from './useHttp';

const statusOrder = { IN_PROGRESS: 0, SCHEDULED: 1, ENDED: 2, CANCELED: 3 };
const typeOrder = { HYBRID: 0, ONLINE: 1 };

const useAuctions = (api: any, roleSpecific = false) => {
  const [auctions, setAuctions] = useState([]);
  const [auctionsHttpState, auctionsRequest] = useHttp([]);
  const { socket, socketId } = useContext(SocketContext);
  const { user } = useContext(AuthContext);

  const getAuctions = useCallback(() => {
    let request = api.getCustomerAuctions;
    if (roleSpecific) {
      if (user?.role === 'MODERATOR') {
        request = api.getModeratorAuctions;
      }
      if (user?.role === 'AUCTIONEER') {
        request = api.getAuctioneerAuctions;
      }
      if (user?.role === 'ADMIN') {
        request = api.getAuctions;
      }
    }
    auctionsRequest(() => request()).then((auctionsArray) => {
      const auctionsWithId = auctionsArray
        .map((entity) => ({ ...entity, id: entity?._id }))
        .sort((a: AuctionDto, b: AuctionDto) => {
          const compareStatus = (statusOrder[a.status] || 0) - (statusOrder[b?.status] || 0);
          if (compareStatus === 0 && a?.status === 'IN_PROGRESS') {
            return (typeOrder?.[a?.type] || 0) - (typeOrder?.[b?.type] || 0);
          }
          if (compareStatus === 0 && ['ENDED', 'CANCELED'].includes(a?.status)) {
            return new Date(b?.startDate).getTime() - new Date(a?.startDate).getTime();
          }

          return compareStatus;
        });
      setAuctions(auctionsWithId);
    });
  }, [auctionsRequest, roleSpecific, user?.role, api]);

  useEffect(() => {
    getAuctions();

    return () => {
      socket?.off('auctions', getAuctions);
    };
  }, [getAuctions, socket, socketId]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket?.on('auctions', getAuctions);
  }, [socket, getAuctions]);

  return {
    auctionsHttpState,
    auctions,
  };
};

export default useAuctions;
