import { HTMLAttributes } from 'react';

import { variants } from '@horse-auction/common/themes/customTheme';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

import NewsletterDialog from '../NewsletterDialog/NewsletterDialog';
import useSubscribeNewsletter from '../NewsletterDialog/useSubscribeNewsletter';

type LinkButtonProps = HTMLAttributes<HTMLDivElement> & { onClick: () => void };

const LinkButton = styled.div<LinkButtonProps>`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 14px;
  margin: 0 1rem;
  min-width: 120px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${variants.primary.main};
    transition: 0.3s ease-out;
  }

  @media ${device.tablet} {
    margin: 1rem 0 0 0;
  }
`;

const NewsletterSignupLink = () => {
  const { newsletterSubscribe, openDialog, isOpen, closeDialog } = useSubscribeNewsletter();

  return (
    <>
      <LinkButton onClick={openDialog}>Newsletter</LinkButton>

      <NewsletterDialog
        open={isOpen}
        onSubmit={newsletterSubscribe}
        onCancel={closeDialog}
        formErrorMessage=''
      />
    </>
  );
};

export default NewsletterSignupLink;
