import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

const tvViewPages = ['stream', 'bids'];

const useTvView = () => {
  const history = useHistory();

  const [tvView, setTvView] = useState(false);

  useEffect(() => {
    const isTvView = tvViewPages.some((page) => history?.location?.pathname?.includes(page));
    if (isTvView) {
      setTvView(true);
    }
  }, [history?.location?.pathname]);

  return {
    tvView,
  };
};

export default useTvView;
