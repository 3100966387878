import { useEffect, useState } from 'react';

import useHttp from '@horse-auction/common/hooks/useHttp';

import PaymentAPI from '../api/PaymentAPI';

const useEntranceDepositPayment = (
  auctionId: string,
  entranceDepositRequired: boolean,
  auctionStatus?: string
) => {
  const [createDepositPaymentState, createDepositPaymentRequest] = useHttp(null);
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState<string>();

  useEffect(() => {
    if (
      auctionId &&
      entranceDepositRequired &&
      auctionStatus &&
      ['SCHEDULED', 'IN_PROGRESS'].includes(auctionStatus)
    ) {
      createDepositPaymentRequest(() => PaymentAPI.getPaymentIntent(auctionId)).then(
        (data: { stripePaymentIntentClientSecret: string }) => {
          if (data.stripePaymentIntentClientSecret) {
            setStripePaymentIntentClientSecret(data.stripePaymentIntentClientSecret);
          }
        }
      );
    }
  }, [auctionId, auctionStatus, createDepositPaymentRequest, entranceDepositRequired]);

  return {
    createDepositPaymentState,
    stripePaymentIntentClientSecret,
  };
};

export default useEntranceDepositPayment;
