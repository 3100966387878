import 'src/locales/i18n';

import { useTranslation } from 'react-i18next';

import { AppButton, AppTypography } from '@horse-auction/common/components';
import styled from 'styled-components/macro';

import NewsletterDialog from '../../../components/NewsletterDialog/NewsletterDialog';
import useSignupNewsletter from '../../../components/NewsletterDialog/useSubscribeNewsletter';

export const Container = styled.div`
  align-self: center;
`;

const NotifyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .MuiButton-root {
    margin-top: 1rem;
  }
`;

const NewsletterSignupButton = () => {
  const { t } = useTranslation(['start']);

  const { newsletterSubscribe, openDialog, isOpen, closeDialog } = useSignupNewsletter();

  return (
    <Container>
      <NotifyButtonWrapper>
        <AppTypography variant='body1' textAlign='center'>
          {`${t<string>('start:notifyIfNewAuctionButton')}:`}
        </AppTypography>
        <AppButton size='large' onClick={openDialog} variant='outlined'>
          {t<string>('start:notify')}
        </AppButton>
      </NotifyButtonWrapper>

      <NewsletterDialog
        open={isOpen}
        onSubmit={newsletterSubscribe}
        onCancel={closeDialog}
        formErrorMessage=''
      />
    </Container>
  );
};

export default NewsletterSignupButton;
