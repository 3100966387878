import { useCallback } from 'react';

import { differenceInSeconds, format } from 'date-fns';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { css } from 'styled-components';

import BidAPI from '@horse-auction/common/api/BidAPI';
import { ConfirmationIconButton } from '@horse-auction/common/components';
import useCountries from '@horse-auction/common/hooks/useCountries';
import useHttp from '@horse-auction/common/hooks/useHttp';
import { device } from '@horse-auction/common/themes/device';
import BidDto from '@horse-auction/common/types/bid.dto';
import { ListItem, ListItemText } from '@mui/material';
import { orange } from '@mui/material/colors';
import { MdClose, MdRestore } from 'react-icons/md';
import { RiAuctionFill } from 'react-icons/ri';
import styled from 'styled-components/macro';

interface CustomProps {
  bid: BidDto;
  index: number;
  userId?: string;
  lotId: string;
  size: string;
  role: string;
  cancelable: boolean;
  onError?: (error: any) => void;
}

interface ListItemProps {
  canceled: boolean;
  highlighted: boolean;
  callIndex: number;
  cancelable: boolean;
}

const customerItemStyle = css`
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'amount time' 'country country';

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: 'amount' 'country';
  }
`;

const moderatorItemStyle = css`
  grid-template-columns: 1fr 1fr 2rem;
  grid-template-areas: 'amount time cancel' 'country email cancel';

  @media ${device.tablet} {
    grid-template-columns: 1fr 2rem;
    grid-template-areas: 'amount cancel' 'email cancel' 'country cancel' 'time cancel';
  }
`;

const auctioneerItemStyle = css`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-template-areas: 'amount country' 'time email';

  .MuiTypography-root {
    font-size: 36px;
  }

  @media ${device.laptop} {
    grid-column-gap: 3rem;
    .MuiTypography-root {
      font-size: 28px;
    }
  }

  @media ${device.tablet} {
    grid-column-gap: 2rem;
    .MuiTypography-root {
      font-size: 24px;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: 'amount' 'country' 'email' 'time';

    > div {
      margin: 0;
    }

    .MuiTypography-root {
      font-size: 16px;
    }
  }
`;

const StyledListItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['highlighted'].includes(prop) && defaultValidatorFn(prop),
})<ListItemProps>`
  &.MuiListItem-root {
    ${({ canceled }) => {
      if (canceled) {
        return `
          text-decoration: line-through;
          opacity: 0.5;
          font-style: italic;
        `;
      }
      return '';
    }};

    display: grid;
    grid-template-rows: 1fr auto;
    ${({ role }) => {
      if (role === 'MODERATOR') {
        return moderatorItemStyle;
      }
      if (role === 'AUCTIONEER') {
        return auctioneerItemStyle;
      }
      return customerItemStyle;
    }};

    button {
      padding: 0;
      height: 20px;
    }
  }

  &.MuiListItem-root:nth-child(1) {
    ${({ highlighted }) => {
      if (highlighted) {
        return `
          animation: fade 10s forwards;
      `;
      }
      return '';
    }};

    .MuiTypography-root {
      font-weight: 700;
    }
  }
  @keyframes fade {
    from {
      background-color: ${orange[200]};
    }
    to {
      background-color: none;
    }
  }
  &.MuiListItem-root:nth-child(odd) {
    background-color: ${({ theme }) => {
      if (theme.name === 'dark') {
        return 'rgba(0, 0, 0, 0.4)';
      }
      return 'rgba(0, 0, 0, 0.1)';
    }};
  }
`;

const customerItemTimeStyle = css`
  @media ${device.tablet} {
    display: none;
  }
`;

const moderatorItemTimeStyle = css`
  &.MuiListItemText-root {
    text-align: start;
  }
`;

const auctioneerItemTimeStyle = css`
  &.MuiListItemText-root {
    text-align: end;
    .MuiTypography-root {
      font-size: 16px;
    }
  }

  @media ${device.mobile} {
    &.MuiListItemText-root {
      text-align: start;
    }
  }
`;

const StyledListItemTime = styled(ListItemText)`
  grid-area: time;
  align-items: flex-start;

  &.MuiListItemText-root {
    overflow-wrap: anywhere;
    text-align: end;
  }

  ${({ role }) => {
    if (role === 'MODERATOR') {
      return moderatorItemTimeStyle;
    }
    if (role === 'AUCTIONEER') {
      return auctioneerItemTimeStyle;
    }
    return customerItemTimeStyle;
  }};
`;

const StyledListItemCancel = styled(ListItemText)`
  grid-area: cancel;

  &.MuiListItemText-root {
    width: 2rem;
    text-align: end;
  }
`;

const auctioneerItemAmountStyle = css`
  &.MuiListItemText-root div {
    justify-content: flex-end;
  }

  @media ${device.mobile} {
    &.MuiListItemText-root div {
      justify-content: flex-start;
      font-size: 20px;
    }
  }
`;

const StyledListItemAmount = styled(ListItemText)`
  grid-area: amount;
  align-items: flex-start;

  &.MuiListItemText-root {
    overflow-wrap: anywhere;
  }

  ${({ role }) => {
    if (role === 'AUCTIONEER') {
      return auctioneerItemAmountStyle;
    }
    return '';
  }};
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const BidIconsWrapper = styled.div`
  svg {
    margin-right: 0.25rem;
  }
`;

const moderatorItemEmailStyle = css`
  justify-content: flex-start;
`;

const auctioneerItemEmailStyle = css`
  justify-content: flex-start;
`;

const StyledListItemEmail = styled(ListItemText)`
  grid-area: email;
  display: flex;
  align-items: flex-start;

  &.MuiListItemText-root {
    overflow-wrap: anywhere;
  }

  ${({ role }) => {
    if (role === 'MODERATOR') {
      return moderatorItemEmailStyle;
    }
    if (role === 'AUCTIONEER') {
      return auctioneerItemEmailStyle;
    }
    return '';
  }};
`;

const StyledListItemCountry = styled(ListItemText)`
  grid-area: country;
  &.MuiListItemText-root {
    overflow-wrap: anywhere;
  }
`;

const BidderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FlagWrapper = styled.div`
  padding-right: 0.5rem;
`;

const YouLabel = styled.span`
  font-weight: 700;
`;

const InhouseLabel = styled.span`
  font-weight: 500;
`;

const CountryLabel = styled.span``;

const biddingStatusIconMap = {
  PLACED: [],
  GOING_ONCE: ['a'],
  GOING_TWICE: ['a', 'b'],
  SOLD: ['a', 'b', 'c'],
};

const BidsListItem: React.FC<CustomProps> = ({
  bid,
  index = -1,
  userId = '',
  lotId,
  size = 'medium',
  role = 'CUSTOMER',
  cancelable,
  onError,
}: CustomProps) => {
  const { t } = useTranslation(['bid']);
  const { codeToCountryLabel, countryToFlag } = useCountries();

  const [cancelBidHttpState, cancelBidRequest] = useHttp();
  const [restoreBidHttpState, restoreBidRequest] = useHttp();

  const isHighlighted = useCallback(
    (bidTime: Date) => {
      if (index > 0 || !bidTime) {
        return false;
      }
      const bidDate = new Date(bidTime);
      const now = new Date();
      const secondsSinceBid = differenceInSeconds(now, bidDate);

      return secondsSinceBid < 10;
    },
    [index]
  );

  const cancelBidClickHandler = useCallback(
    (bidId) => {
      cancelBidRequest(() => BidAPI.cancelBid(lotId, bidId)).catch((error) => onError?.(error));
    },
    [cancelBidRequest, onError, lotId]
  );

  const restoreBidClickHandler = useCallback(
    (bidId) => {
      restoreBidRequest(() => BidAPI.restoreBid(lotId, bidId)).catch((error) => onError?.(error));
    },
    [restoreBidRequest, onError, lotId]
  );

  return (
    <StyledListItem
      key={bid?._id}
      highlighted={isHighlighted(bid?.createdAt)}
      canceled={bid?.canceled}
      callIndex={biddingStatusIconMap?.[bid.biddingStatus]?.length || 0}
      cancelable={cancelable}
      role={role}
    >
      {cancelable && (
        <StyledListItemCancel
          primary={
            <>
              {bid?.canceled === false && (
                <ConfirmationIconButton
                  size='large'
                  color='primary'
                  icon={<MdClose />}
                  title={t<string>('moderate:auctionPage.cancelBidConfirmationDialog.title', {
                    price: bid?.price,
                    currency: bid?.currency,
                  })}
                  description={t<string>(
                    'moderate:auctionPage.cancelBidConfirmationDialog.description',
                    {
                      price: bid?.price,
                      currency: bid?.currency,
                      email: bid?.inhouse ? 'In-house' : bid?.userEmail,
                    }
                  )}
                  onConfirm={() => cancelBidClickHandler(bid?._id)}
                  onCancel={() => undefined}
                />
              )}
              {bid?.canceled === true && (
                <ConfirmationIconButton
                  size='large'
                  color='primary'
                  icon={<MdRestore />}
                  title={t<string>('moderate:auctionPage.restoreBidConfirmationDialog.title', {
                    price: bid?.price,
                    currency: bid?.currency,
                  })}
                  description={t<string>(
                    'moderate:auctionPage.restoreBidConfirmationDialog.description',
                    {
                      price: bid?.price,
                      currency: bid?.currency,
                      email: bid?.inhouse ? 'In-house' : bid?.userEmail,
                    }
                  )}
                  onConfirm={() => restoreBidClickHandler(bid?._id)}
                  onCancel={() => undefined}
                />
              )}
            </>
          }
        />
      )}
      <StyledListItemAmount
        role={role}
        primary={
          <AmountContainer>
            {index === 0 && (
              <BidIconsWrapper>
                {biddingStatusIconMap?.[bid.biddingStatus]?.map((iconKey) => (
                  <RiAuctionFill key={iconKey} color='#9a4c71' />
                ))}
              </BidIconsWrapper>
            )}

            <NumberFormat
              value={bid.price}
              displayType='text'
              thousandSeparator=' '
              prefix={`${bid.currency} `}
            />
          </AmountContainer>
        }
      />
      <StyledListItemCountry
        primary={
          <BidderWrapper>
            {bid?.inhouse !== true && (
              <>
                <FlagWrapper>
                  {bid?.userCountry && (
                    <ReactCountryFlag
                      svg
                      countryCode={bid?.userCountry}
                      style={{
                        fontSize: '1.5rem',
                        lineHeight: '1rem',
                      }}
                      aria-label='country'
                    />
                  )}
                </FlagWrapper>
                {userId && userId === bid?.userId ? (
                  <YouLabel>{t<string>('components:bidsList.youLabel')}</YouLabel>
                ) : (
                  <CountryLabel>{codeToCountryLabel(bid.userCountry)}</CountryLabel>
                )}
              </>
            )}
            {bid?.inhouse === true && (
              <InhouseLabel>{t<string>('components:bidsList.inhouseLabel')}</InhouseLabel>
            )}
          </BidderWrapper>
        }
      />
      {(role === 'MODERATOR' || role === 'AUCTIONEER') && bid?.inhouse !== true && (
        <StyledListItemEmail
          role={role}
          secondary={
            <BidderWrapper>
              <CountryLabel>{bid.userEmail}</CountryLabel>
            </BidderWrapper>
          }
        />
      )}
      <StyledListItemTime
        role={role}
        secondary={format(new Date(bid.createdAt), 'dd-MMM-yy HH:mm:ss')}
      />
    </StyledListItem>
  );
};

export default BidsListItem;
