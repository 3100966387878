import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AppButton, AppTypography, AlertCollapse } from '@horse-auction/common/components';
import BidDto from '@horse-auction/common/types/bid.dto';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Backdrop, CircularProgress, List, Chip } from '@mui/material';
import styled from 'styled-components/macro';

import BidsListItem from './BidsListItem';

interface CustomProps {
  bids: BidDto[];
  bidsWithCanceled: BidDto[];
  userId?: string;
  numberOfBids: number;
  numberOfBidders: number;
  auctionType?: string;
  highestBidStatus: string;
  lot: LotDto;
  isLoading: boolean;
  size?: string;
  role?: string;
  cancelable?: boolean;
  onError?: (error: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const StyledBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 10;
    background-color: unset;
    display: flex;
    position: initial;
  }

  .MuiCircularProgress-root {
    margin-top: 2rem;
  }
`;

const StyledList = styled(List)`
  padding-bottom: 0;
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    padding: 0 0.5rem;
    background-color: #9a4c71;
  }
  .MuiChip-label {
    margin-left: 0.5rem;
    font-weight: 500;
  }
`;

const NoBidsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  background-color: ${({ theme }) => {
    if (theme.name === 'dark') {
      return 'rgba(0, 0, 0, 0.4)';
    }
    return 'rgba(0, 0, 0, 0.1)';
  }};
`;

const BiddingStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: uppercase;
  margin-top: 0.5rem;
`;

const COLLAPSED_LIST_LENGTH = 10;

const BidsList: React.FC<CustomProps> = ({
  bids,
  bidsWithCanceled,
  userId,
  numberOfBids = 0,
  numberOfBidders = 0,
  auctionType = 'ONLINE',
  highestBidStatus = 'PLACED',
  lot,
  isLoading,
  size = 'medium',
  role = 'CUSTOMER',
  cancelable,
  onError,
}: CustomProps) => {
  const { t } = useTranslation();
  const [showFullList, setShowFullList] = useState(false);
  const [listItemsLength, setListItemsLength] = useState(COLLAPSED_LIST_LENGTH);

  const showMoreHandler = useCallback(() => {
    setShowFullList(true);
    setListItemsLength(bidsWithCanceled?.length);
  }, [bidsWithCanceled?.length]);

  const showLessHandler = useCallback(() => {
    setShowFullList(false);
    setListItemsLength(COLLAPSED_LIST_LENGTH);
  }, []);

  return (
    <Container>
      {isLoading === true && (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color='primary' />
        </StyledBackdrop>
      )}
      {!isLoading && bidsWithCanceled?.length === 0 && (
        <NoBidsWrapper>
          <AppTypography variant='h5' fontSize='14px'>
            {t<string>('components:bidsList.noBidsRegistered')}
          </AppTypography>
        </NoBidsWrapper>
      )}
      {!isLoading && bidsWithCanceled?.length > 0 && (
        <>
          {role !== 'MODERATOR' && role !== 'AUCTIONEER' && (
            <>
              <AlertCollapse
                severity='warning'
                rendered={lot?.status === 'IN_PROGRESS' && highestBidStatus === 'GOING_ONCE'}
                message='Going once'
              />
              <AlertCollapse
                severity='error'
                rendered={lot?.status === 'IN_PROGRESS' && highestBidStatus === 'GOING_TWICE'}
                message='Going twice'
              />
            </>
          )}
          <BiddingStats>
            {auctionType === 'ONLINE' && (
              <StyledChip
                size='medium'
                color='primary'
                label={`${t<string>('components:bidsList.biddersNumber')}: ${numberOfBidders}`}
              />
            )}
            <StyledChip
              size='medium'
              color='primary'
              label={`${t<string>('components:bidsList.bidsNumber')}: ${numberOfBids}`}
            />
          </BiddingStats>
          <StyledList dense>
            {bidsWithCanceled?.slice(0, listItemsLength).map((bid, index) => (
              <BidsListItem
                key={bid?._id}
                lotId={lot?._id}
                bid={bid}
                index={index}
                size={size}
                role={role}
                userId={userId}
                cancelable={cancelable || false}
                onError={onError}
              />
            ))}
          </StyledList>

          {bidsWithCanceled?.length > COLLAPSED_LIST_LENGTH && !showFullList && (
            <AppButton onClick={showMoreHandler} size='small' color='primary' variant='text'>
              {t<string>('components:bidsList.showMore')}
            </AppButton>
          )}
          {bidsWithCanceled?.length > COLLAPSED_LIST_LENGTH && showFullList && (
            <AppButton onClick={showLessHandler} size='small' color='primary' variant='text'>
              {t<string>('components:bidsList.showLess')}
            </AppButton>
          )}
        </>
      )}
    </Container>
  );
};

export default BidsList;
