import { Link, LinkProps } from 'react-router-dom';

import styled from 'styled-components/macro';

interface CustomProps {
  noUnderline?: boolean;
  active?: boolean;
  to?: string;
}

interface Props extends CustomProps, Omit<LinkProps, 'to'> {}

const LinkContainer = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['noUnderline', 'active'].includes(prop) && defaultValidatorFn(prop),
})<Props>`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  text-decoration: ${({ noUnderline }) => (noUnderline ? 'bold' : 'underline')};
`;

const StyledLink: React.FC<Props> = ({
  noUnderline = false,
  active = false,
  to = '/',
  ...rest
}: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <LinkContainer noUnderline={noUnderline} active={active} to={to} {...rest} />
);
export default StyledLink;
