import { useCallback, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AlertCollapse,
  AppButton,
  AppTypography,
  LotHeader,
  BidsList,
} from '@horse-auction/common/components';
import LotOutcomeRow from '@horse-auction/common/components/LotOutcomeRow/LotOutcomeRow';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import useBids from '@horse-auction/common/hooks/useBids';
import { device } from '@horse-auction/common/themes/device';
import LotDto from '@horse-auction/common/types/lot.dto';
import BidAPI from 'src/api/BidAPI';
import { AuctionContext } from 'src/context/AuctionContext';
import styled from 'styled-components/macro';

import BidForm from './BidForm';

interface CustomProps {
  lot?: LotDto;
  readOnly?: boolean;
  noForm?: boolean;
  noHeader?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    margin-top: 1rem;
  }

  h5.MuiTypography-h5 {
    line-height: 36px;
  }

  @media ${device.laptop} {
    h6.MuiTypography-h6 {
      font-size: 14px;
    }
  }

  @media ${device.tablet} {
    h5.MuiTypography-h5 {
      font-size: 16px;
      line-height: 24px;
    }
    h6.MuiTypography-h6 {
      font-size: 14px;
    }
  }

  @media ${device.mobile} {
    h6.MuiTypography-h6 {
      font-size: 12px;
    }
  }
`;
const LotOutcomeRowWrapper = styled.div`
  > div {
    margin: 0;
    padding: 0;
  }
`;

const RedirectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;

  button {
    margin-top: 0.5rem;
  }
`;

const AlertCollapseWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const HighestBidderInfo = styled.div`
  display: flex;
  background-color: #8dee75;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  margin-top: 0.5rem;
  color: #000;
`;

const LoginRedirect = () => {
  const { t } = useTranslation(['bid']);
  const history = useHistory();
  const location = useLocation();

  const loginButtonClickHandler = () => {
    history.push({
      pathname: '/login',
      state: { sourcePath: `${location?.pathname}${location?.search}` },
    });
  };

  return (
    <RedirectWrapper>
      <AppTypography variant='body1'>{`${t<string>(
        'bid:biddingConsole.pleaseLogin'
      )}:`}</AppTypography>
      <AppButton onClick={loginButtonClickHandler}>
        {`${t<string>('bid:biddingConsole.loginButton')}`}
      </AppButton>
    </RedirectWrapper>
  );
};

const BiddingConsole = ({
  lot,
  readOnly = false,
  noForm = false,
  noHeader = false,
}: CustomProps) => {
  const { t } = useTranslation(['bid', 'validation']);
  const { user } = useContext(AuthContext);
  const { auction, customerEntranceDepositRequired, userAuction } = useContext(AuctionContext);

  const {
    createBid,
    createBidHttpState,
    bids,
    bidsWithCanceled,
    isHighestBidder,
    highestBidStatus,
    numberOfBids,
    numberOfBidders,
    bidsHttpState,
  } = useBids(BidAPI, lot?._id, 'CUSTOMER', readOnly);
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    if (isHighestBidder) {
      setResponseError(null);
    }
  }, [isHighestBidder]);

  useEffect(() => {
    setResponseError(null);
  }, [lot]);

  const consoleErrorHandler = useCallback((error) => {
    setResponseError(error);
  }, []);

  if (!lot || !auction) {
    return (
      <AppTypography variant='body1'>
        {t<string>('bid:biddingConsole.noLotForBiddingAvailable')}
      </AppTypography>
    );
  }

  if (readOnly || noForm || auction?.status !== 'IN_PROGRESS') {
    return (
      <>
        {!noHeader && <LotHeader lot={lot} lotsQuantity={auction?.lotsQuantity} />}
        <LotOutcomeRow auctionStatus={auction?.status} lot={lot} auctionType={auction?.type} />
        <BidsList
          userId={user?._id}
          bids={bids}
          lot={lot}
          bidsWithCanceled={bidsWithCanceled}
          numberOfBids={numberOfBids}
          highestBidStatus={highestBidStatus}
          numberOfBidders={numberOfBidders}
          isLoading={bidsHttpState?.isLoading}
          role='CUSTOMER'
          auctionType={auction?.type}
        />
      </>
    );
  }

  return (
    <Container>
      <LotHeader lot={lot} lotsQuantity={auction?.lotsQuantity} />
      <LotOutcomeRowWrapper>
        <LotOutcomeRow
          auctionStatus={auction?.status}
          lot={lot}
          auctionType={auction?.type}
          showTimers
        />
      </LotOutcomeRowWrapper>

      <AlertCollapseWrapper>
        <AlertCollapse error={responseError} rendered={!!responseError} />
      </AlertCollapseWrapper>
      {auction && (
        <BidForm
          auction={auction}
          userAuction={userAuction}
          customerEntranceDepositRequired={customerEntranceDepositRequired}
          lot={lot}
          createBid={createBid}
          createBidHttpState={createBidHttpState}
          bids={bids}
          isHighestBidder={isHighestBidder}
          onError={consoleErrorHandler}
        />
      )}
      {user?.role === 'CUSTOMER' &&
        isHighestBidder &&
        ['IN_PROGRESS', 'LOT_SOLD', 'ENDED'].includes(lot?.status) && (
          <HighestBidderInfo>{`${t<string>(
            'bid:biddingConsole.youAreHighestBidder'
          )}`}</HighestBidderInfo>
        )}
      {!user && <LoginRedirect />}
      {['IN_PROGRESS', 'UPCOMING', 'LOT_SOLD', 'ENDED'].includes(lot?.status) && (
        <BidsList
          userId={user?._id}
          bids={bids}
          lot={lot}
          bidsWithCanceled={bidsWithCanceled}
          highestBidStatus={highestBidStatus}
          numberOfBids={numberOfBids}
          numberOfBidders={numberOfBidders}
          isLoading={bidsHttpState?.isLoading}
          role='CUSTOMER'
          auctionType={auction?.type}
        />
      )}
    </Container>
  );
};

export default BiddingConsole;
