const secondsToTimeObject = (timeInSeconds: number) => {
  const seconds = Math.floor(timeInSeconds) % 60;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const hours = Math.floor(timeInSeconds / 3600);

  return { seconds, minutes, hours };
};

const secondsToDayTimeObject = (timeInSeconds) => {
  const seconds = Math.floor(timeInSeconds) % 60;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const hours = Math.floor(timeInSeconds / 3600) % 24;
  const days = Math.floor(timeInSeconds / (3600 * 24));

  return { seconds, minutes, hours, days };
};

const secondsToHms = (timeInSeconds) => {
  secondsToTimeObject(timeInSeconds);

  const { seconds, minutes, hours } = secondsToTimeObject(timeInSeconds);

  const secondsStr = pad(seconds, 2);
  const minutesStr = pad(minutes, 2);
  const hoursStr = pad(hours, 2);
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

const pad = (num: number, size: number) => {
  let numStr = num.toString();
  while (numStr.length < size) numStr = `0${numStr}`;
  return numStr;
};

const hmsToSeconds = (hmsTimeStr) => {
  const hmsArray = hmsTimeStr.split(':');

  const seconds = +hmsArray[0] * 60 * 60 + +hmsArray[1] * 60 + +hmsArray[2];
  return +seconds;
};

export { secondsToTimeObject, secondsToDayTimeObject, secondsToHms, hmsToSeconds };
