import React, { Suspense, PropsWithChildren } from 'react';

import { Route, Switch } from 'react-router-dom';

import { ProtectedRoute, ConnectionLostAlert } from '@horse-auction/common/components/';
import useTvView from '@horse-auction/common/hooks/useTvView';
import { device } from '@horse-auction/common/themes/device';
import PaymentRequiredAlert from 'src/components/PaymentRequiredAlert/PaymentRequiredAlert';
import { AuctionProvider } from 'src/context/AuctionContext';
import { ModeratorAuctionProvider } from 'src/context/ModeratorAuctionContext';
import AuctionPage from 'src/pages/auction/AuctionPage';
import AuctionTermsPage from 'src/pages/auction-terms/AuctionTermsPage';
import AuctionsPage from 'src/pages/auctions/AuctionsPage';
import AuctionTvBidsPage from 'src/pages/bids/AuctionTvBidsPage';
import LotPage from 'src/pages/lot/LotPage';
import StartPage from 'src/pages/start/StartPage';
import AuctionTvStreamPage from 'src/pages/stream/AuctionTvStreamPage';
import styled from 'styled-components/macro';

const AccountPageLazy = React.lazy(() => import('src/pages/account/AccountPage'));
const PurchasesPageLazy = React.lazy(() => import('src/pages/purchases/PurchasesPage'));
const ModerateAuctionPageLazy = React.lazy(
  () => import('src/pages/moderate/auction/ModerateAuctionPage')
);
const ModerateBidsPageLazy = React.lazy(() => import('src/pages/moderate/bids/ModerateBidsPage'));
const ModeratePageLazy = React.lazy(() => import('src/pages/moderate/ModeratePage'));
const AuctioneerAuctionPageLazy = React.lazy(
  () => import('src/pages/auctioneer/auction/AuctioneerAuctionPage')
);
const AuctioneerPageLazy = React.lazy(() => import('src/pages/auctioneer/AuctioneerPage'));

type IPageContent = PropsWithChildren & {
  tvView: boolean;
};
type AlertWrapperProps = PropsWithChildren & {
  top?: string;
};

const PageContent = styled.div<IPageContent>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: ${({ tvView }) => (tvView ? '0' : '4rem')};
`;

const AlertWrapper = styled.div<AlertWrapperProps>`
  width: 100%;
  position: sticky;
  top: ${({ top }) => top || '4rem'};
  z-index: 10;

  @media ${device.mobile} {
    top: ${({ top }) => top || '3.5rem'};
  }
`;
const AuctionRouter = () => (
  <AuctionProvider>
    <Route path='/auctions/:auctionId' component={AuctionPage} exact />
    <Route path='/auctions/:auctionId/lots/:lotId' component={LotPage} />
    <Route path='/auctions/:auctionId/stream' component={AuctionTvStreamPage} />
    <Route path='/auctions/:auctionId/bids' component={AuctionTvBidsPage} />
  </AuctionProvider>
);

const ModeratorRouter = () => (
  <>
    <ModeratorAuctionProvider>
      <ProtectedRoute
        path='/moderate/auctions/:auctionId'
        component={ModerateAuctionPageLazy}
        roles={['MODERATOR']}
        exact
      />
      <ProtectedRoute
        path='/moderate/auctions/:auctionId/:lotId'
        component={ModerateBidsPageLazy}
        roles={['MODERATOR']}
      />
    </ModeratorAuctionProvider>
  </>
);

const AuctioneerRouter = () => (
  <>
    <ModeratorAuctionProvider>
      <ProtectedRoute
        path='/auctioneer'
        component={AuctioneerAuctionPageLazy}
        roles={['AUCTIONEER']}
      />
    </ModeratorAuctionProvider>
  </>
);

const UserLayout = () => {
  const { tvView } = useTvView();

  return (
    <PageContent tvView={tvView}>
      <AlertWrapper top={tvView ? '0' : ''}>
        <ConnectionLostAlert />
        {!tvView && <PaymentRequiredAlert />}
      </AlertWrapper>
      <Switch>
        <Route path='/' component={StartPage} exact />
        <Route path='/auctions' component={AuctionsPage} exact />
        <Route path='/auctions/:auctionId' component={AuctionRouter} />
        <Route path='/auction-terms/:auctionId' component={AuctionTermsPage} exact />
        <Suspense fallback={<p>loading</p>}>
          <ProtectedRoute
            path='/moderate'
            component={ModeratePageLazy}
            roles={['MODERATOR']}
            exact
          />
          <ProtectedRoute
            path='/auctioneer'
            component={AuctioneerPageLazy}
            roles={['AUCTIONEER']}
            exact
          />
          <Route path='/moderate/auctions/:auctionId' component={ModeratorRouter} />
          <Route path='/auctioneer/auctions/:auctionId' component={AuctioneerRouter} />
          <ProtectedRoute
            path='/account'
            component={AccountPageLazy}
            roles={['CUSTOMER', 'MODERATOR', 'AUCTIONEER']}
            exact
          />
          <ProtectedRoute
            path='/purchases'
            component={PurchasesPageLazy}
            roles={['CUSTOMER']}
            exact
          />
        </Suspense>
      </Switch>
    </PageContent>
  );
};

export default UserLayout;
