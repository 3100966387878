import { useCallback, useState } from 'react';

import { ConfirmationDialog } from '@horse-auction/common/components';
import { IconButton, IconButtonProps } from '@mui/material';
import styled from 'styled-components/macro';

import { CustomProps as ConfirmationDialogCustomProps } from '../ConfirmationDialog/ConfirmationDialog';

interface CustomProps {
  buttonSize?: 'small' | 'medium' | 'large';
  icon: React.ReactNode;
}

interface Props extends CustomProps, IconButtonProps, ConfirmationDialogCustomProps {}

const ConfirmationIconButton: React.FC<Props> = ({
  title,
  icon,
  description,
  color = 'primary',
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  buttonSize = 'large',
  isLoading,
  disabled,
  onConfirm,
  onCancel,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const buttonClickHandler = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const confirmHandler = useCallback(() => {
    onConfirm();
    setDialogOpen(false);
  }, [onConfirm]);

  const cancelHandler = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton onClick={buttonClickHandler} size={buttonSize} color={color} disabled={disabled}>
        {icon}
      </IconButton>
      <ConfirmationDialog
        open={dialogOpen}
        title={title}
        description={description}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        isLoading={isLoading}
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
      />
    </>
  );
};

export default ConfirmationIconButton;
