import { useContext } from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthContext } from '@horse-auction/common/context/AuthContext';

interface CustomProps {
  roles: string[];
  component: React.ElementType;
}

interface Props extends CustomProps, Omit<RouteProps, 'component'> {}

const ProtectedRoute = ({ roles, component: Component, ...rest }: Props) => {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (user && (!roles || roles.some((role) => role === user.role))) {
          return <Component />;
        }
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }}
    />
  );
};

export default ProtectedRoute;
