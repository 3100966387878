import { useEffect, useState } from 'react';

import ReactPlayer from 'react-player';

import FileDto from '@horse-auction/common/types/file.dto';
import { MdPlayCircleOutline } from 'react-icons/md';
import styled from 'styled-components/macro';
import SwiperCore, { Navigation, Thumbs } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';

SwiperCore.use([Navigation, Thumbs]);

const { REACT_APP_SERVER_URL } = process.env;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 1000px;
  align-self: flex-start;

  .swiper-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .swiper-slide {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }

  .swiper-wrapper {
    width: 200px;
    display: flex;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .main-swiper {
    position: absolute;
  }

  .thumbnail-swiper {
    height: 100px;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .thumbnail-swiper .swiper-slide {
    width: 150px !important;
    height: 100%;
    opacity: 0.4;
  }

  .thumbnail-swiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    margin: 0;
  }
`;

const SlideshowWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  margin-bottom: 0.25rem;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  left: 0;
  width: 100%;
`;

interface CustomProps {
  images: FileDto[];
  videoUrls: string[];
}

const Slideshow = ({ images = [], videoUrls = [] }: CustomProps) => {
  const imagesLength = images?.length || 0;
  const videoUrlsLength = videoUrls?.length || 0;
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    swiper?.slideTo(0);
    thumbsSwiper?.slideTo(0);
  }, [images, videoUrls, swiper, thumbsSwiper]);

  return (
    <>
      {' '}
      <Container>
        <SlideshowWrapper>
          <Swiper
            style={
              {
                // '--swiper-navigation-color': '#fff',
                // '--swiper-pagination-color': '#fff',
              }
            }
            spaceBetween={10}
            navigation
            resizeObserver
            onSwiper={setSwiper}
            thumbs={{ swiper: thumbsSwiper }}
            className='main-swiper'
          >
            {imagesLength > 0 &&
              images.map((image) => (
                <SwiperSlide key={image?.key}>
                  <StyledImage
                    src={`${REACT_APP_SERVER_URL}/api/v1/files/image/${image?.key}`}
                    alt={image?.description}
                  />
                </SwiperSlide>
              ))}
            {videoUrlsLength > 0 &&
              videoUrls.map((videoUrl, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`${videoUrl}${index}`}>
                  <StyledReactPlayer url={videoUrl} controls width='100%' height='100%' />
                </SwiperSlide>
              ))}
          </Swiper>
        </SlideshowWrapper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          className='thumbnail-swiper'
        >
          {imagesLength > 0 &&
            images.map((image) => (
              <SwiperSlide key={image?.key}>
                <StyledImage
                  src={`${REACT_APP_SERVER_URL}/api/v1/files/image-thumb/${image?.key}`}
                  alt={image?.description}
                />
              </SwiperSlide>
            ))}
          {videoUrlsLength > 0 &&
            videoUrls.map((videoUrl, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={`${videoUrl}${index}`}>
                <MdPlayCircleOutline size='3rem' color='white' />{' '}
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </>
  );
};

export default Slideshow;
