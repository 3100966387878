import { useContext } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import { AlertContext } from '@horse-auction/common/context/AlertContext';
import { Link } from '@mui/material';
import { MdContentCopy } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  link: string;
  alert: string;
  onCopy?: () => void;
}

const UrlWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-column-gap: 1rem;
  justify-content: center;
  align-items: center;

  .MuiButton-root {
    margin-left: 1rem;
  }
`;

const CopyToClipboardLink: React.FC<CustomProps> = ({ link, alert, onCopy }: CustomProps) => {
  const { t } = useTranslation(['users']);

  const { createAlert } = useContext(AlertContext);

  const copyLinkHandler = () => {
    createAlert(t('alert:linkCopied'));
    if (alert) {
      createAlert(alert);
    }
    onCopy?.();
  };

  return (
    <UrlWrapper>
      <Link href={link} target='_blank'>
        {link}
      </Link>
      <CopyToClipboard text={link} onCopy={copyLinkHandler}>
        <AppButton variant='outlined' color='primary' startIcon={<MdContentCopy />}>
          {t<string>('users:detail.copyLink')}
        </AppButton>
      </CopyToClipboard>
    </UrlWrapper>
  );
};

export default CopyToClipboardLink;
