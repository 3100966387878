import {
  Alert,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  DialogProps,
  DialogTitleProps,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  errorMessage?: string;
  onCancel: () => void;
}

type Props = CustomProps & DialogProps & DialogTitleProps;

const FormAlert = styled(Alert)`
  margin: 1rem 0;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
    padding-bottom: 1rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const AppFormDialog: React.FC<Props> = ({
  title,
  open,
  errorMessage,
  fullWidth,
  maxWidth,
  children,
  onCancel,
}: Props) => (
  <StyledDialog open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
    <StyledDialogTitle>{title}</StyledDialogTitle>
    {onCancel ? (
      <StyledIconButton aria-label='close' onClick={onCancel}>
        <MdClear />
      </StyledIconButton>
    ) : null}
    <DialogContent>
      <Collapse in={!!errorMessage}>
        <FormAlert severity='error'>{errorMessage}</FormAlert>
      </Collapse>
      {children}
    </DialogContent>
  </StyledDialog>
);

export default AppFormDialog;
