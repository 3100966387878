import React from 'react';

import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { StyledLink } from '@horse-auction/common/components';
import { Paper } from '@mui/material';

type Props = {
  auctionId: string;
};

const FooterPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
`;

const AuctionFooter = ({ auctionId }: Props) => (
  <FooterPaper>
    <Trans i18nKey='auction:auctionFooter.goToAuctionTerms'>
      <StyledLink target='_blank' to={`/auction-terms/${auctionId}`} />
    </Trans>
  </FooterPaper>
);

export default AuctionFooter;
