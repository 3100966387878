import { format, utcToZonedTime } from 'date-fns-tz';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import { device } from '@horse-auction/common/themes/device';
import { Box } from '@mui/material';
import enGB from 'date-fns/locale/en-GB';
import { MdEventNote, MdSchedule } from 'react-icons/md';
import styled from 'styled-components/macro';

interface CustomProps {
  eventDate?: Date;
  label?: string;
}
const StyledAppTypography = styled(AppTypography)`
  &.MuiTypography-root {
    margin-left: 0.5rem;
  }
`;

const EventTimeRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const EventTimeRow: React.FC<CustomProps> = ({ eventDate = new Date(), label }: CustomProps) => {
  if (!eventDate) {
    return null;
  }

  return (
    <EventTimeRowWrapper>
      {label && <AppTypography variant='h6'>{label}</AppTypography>}
      <Box ml='1rem' display='flex' justifyContent='center' alignItems='center'>
        <MdEventNote />
        <StyledAppTypography variant='subtitle1'>
          {format(utcToZonedTime(eventDate, 'Europe/Warsaw'), 'dd MMMM yyyy')}
        </StyledAppTypography>
      </Box>
      <Box ml='1rem' display='flex' justifyContent='center' alignItems='center'>
        <MdSchedule />
        <StyledAppTypography variant='subtitle1'>
          {format(utcToZonedTime(eventDate, 'Europe/Warsaw'), 'HH:mm zzz', {
            timeZone: 'Europe/Warsaw',
            locale: enGB,
          })}
        </StyledAppTypography>
      </Box>
    </EventTimeRowWrapper>
  );
};

export default EventTimeRow;
