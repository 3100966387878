import { Logo } from '@horse-auction/common/components';
import styled from 'styled-components/macro';

interface Props {
  imageKey?: string;
}

const { REACT_APP_API_V1_URL, REACT_APP_PRODUCT_IMAGE_PATH } = process.env;

const imagesPath = `${REACT_APP_API_V1_URL}${REACT_APP_PRODUCT_IMAGE_PATH}`;

const ImageContainer = styled.div`
  div {
    margin-top: 15vh;
  }
`;

const BackgroundImage = styled.img<{ alt: string; src: string }>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TvAuctionImage = ({ imageKey }: Props) => (
  <ImageContainer>
    {imageKey ? (
      <BackgroundImage alt='auction' src={`${imagesPath}/${imageKey}`} />
    ) : (
      <Logo positioning='vertical' variant='fullscreen' />
    )}
  </ImageContainer>
);

export default TvAuctionImage;
