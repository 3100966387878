import { useContext, useState, useCallback, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import logoHorizontalLight from '@horse-auction/common/assets/images/logo-horizontal-light.png';
import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import ThemeToggle from '@horse-auction/common/components/Navbar/components/ThemeToggle/ThemeToggle';
import UserMenu from '@horse-auction/common/components/Navbar/components/UserMenu/UserMenu';
import { AlertContext } from '@horse-auction/common/context/AlertContext';
import { AuthContext } from '@horse-auction/common/context/AuthContext';
import { device } from '@horse-auction/common/themes/device';
import { IconButton, AppBar, Toolbar, SwipeableDrawer } from '@mui/material';
import { MdMenu } from 'react-icons/md';
import { PurchasesContext } from 'src/context/PurchasesContext';
import styled from 'styled-components/macro';

import DrawerContent from './DrawerContent';

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    background-color: white;
    color: black;
    z-index: 10;
    height: 4rem;
  }

  .MuiButton-root {
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .MuiButton-outlined {
    border-color: rgba(0, 0, 0, 0.23);
  }
  .MuiButton-root {
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .MuiButton-textSecondary {
    color: ${({ theme }) => theme?.palette?.secondary?.main};
  }

  @media ${device.mobile} {
    &.MuiAppBar-root {
      height: 3.5rem;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  .MuiToolbar-root {
    min-height: 4rem;
  }

  .MuiButton-root {
    margin-left: 1rem;
  }
`;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    min-width: 15rem;
  }
`;

const LogoWrapper = styled.div<PropsWithChildren & { onClick: () => void }>`
  margin-right: 1rem;
  width: 150px;

  img {
    height: 3rem;
    object-fit: contain;
    margin-right: 1rem;
  }
`;

const Filler = styled.div`
  flex-grow: 1;
`;

const SectionDesktop = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    display: none;
  }
`;

const SectionMobile = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
`;

const Navbar = () => {
  const { t } = useTranslation(['navbar']);
  const { createAlert } = useContext(AlertContext);

  const { user, logout } = useContext(AuthContext);

  const { clearPurchases } = useContext(PurchasesContext);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const logoutClickHandler = useCallback(() => {
    logout().then(() => {
      clearPurchases();
      createAlert(t('alert:successfullLogout'));
    });
  }, [logout, clearPurchases, createAlert, t]);

  const loginButtonClickHandler = () => {
    history.push('/login');
  };

  const signUpButtonClickHandler = () => {
    history.push('/signup');
  };

  const toggleDrawerHandler = () => {
    setDrawerOpen((isDrawerOpen) => !isDrawerOpen);
  };

  const logoClickHandler = useCallback(() => {
    if (location?.pathname === '/') {
      return scroll.scrollToTop();
    }
    return history.push('/');
  }, [history, location]);

  return (
    <StyledAppBar position='fixed'>
      <StyledToolbar>
        <LogoWrapper onClick={logoClickHandler}>
          <img alt='logo' src={logoHorizontalLight} />
        </LogoWrapper>
        <SectionDesktop>
          <AppButton to='/' variant='text' color='primary'>
            {t<string>('navbar:home')}
          </AppButton>
          <AppButton to='/auctions' variant='text' color='primary'>
            {t<string>('navbar:auctions')}
          </AppButton>
          <AppButton to='/how-to-bid' variant='text' color='primary'>
            {t<string>('navbar:howToBid')}
          </AppButton>
          <AppButton to='/contact' variant='text' color='primary'>
            {t<string>('navbar:contact')}
          </AppButton>
          {user?.role === 'MODERATOR' && (
            <AppButton to='/moderate' variant='text' color='secondary'>
              {t<string>('navbar:moderate')}
            </AppButton>
          )}
          {user?.role === 'AUCTIONEER' && (
            <AppButton to='/auctioneer' variant='text' color='secondary'>
              {t<string>('navbar:auctioneer')}
            </AppButton>
          )}
          {(!user || user?.role === 'CUSTOMER') && <></>}
        </SectionDesktop>
        <Filler />
        <ThemeToggle />
        <SectionDesktop>
          {user && <UserMenu user={user} onLogout={logoutClickHandler} />}
          {!user && (
            <>
              <AppButton onClick={signUpButtonClickHandler} variant='outlined' color='primary'>
                {t<string>('navbar:register')}
              </AppButton>
              <AppButton onClick={loginButtonClickHandler} variant='text' color='primary'>
                {t<string>('navbar:login')}
              </AppButton>
            </>
          )}
        </SectionDesktop>
        <SectionMobile>
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawerHandler}>
            <MdMenu />
          </IconButton>
        </SectionMobile>
        <StyledSwipeableDrawer
          anchor='right'
          open={drawerOpen}
          onClose={toggleDrawerHandler}
          onOpen={toggleDrawerHandler}
        >
          <DrawerContent
            user={user}
            onLogout={logoutClickHandler}
            onSelection={toggleDrawerHandler}
          />
        </StyledSwipeableDrawer>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
