import { httpPost, httpGet, httpPut } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getCustomerBids = (lotId: string) => {
  const payload = { lotId };

  return CancellablePromise(httpGet('/bids/customer', payload));
};

const getModeratorBids = (lotId: string) => {
  const payload = { lotId };

  return CancellablePromise(httpGet('/bids/moderator', payload));
};

const getBiddingAuctionUsers = (auctionId: string) => {
  const payload = {
    auctionId,
  };

  return CancellablePromise(httpGet('/bids/moderator/users', payload));
};

const createBid = (lotId: string, price: string) => {
  const payload = {
    lotId,
    price,
  };

  return CancellablePromise(httpPost('/bids', payload));
};

const cancelBid = (lotId: string, bidId: string) => {
  const payload = {
    lotId,
    bidId,
  };

  return CancellablePromise(httpPut('/bids/cancel', payload));
};

const restoreBid = (lotId: string, bidId: string) => {
  const payload = {
    lotId,
    bidId,
  };

  return CancellablePromise(httpPut('/bids/restore', payload));
};

const BidAPI = {
  getCustomerBids,
  getModeratorBids,
  getBiddingAuctionUsers,
  createBid,
  cancelBid,
  restoreBid,
};

export default BidAPI;
