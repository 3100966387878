export type TCancellablePromise = {
  promise: Promise<unknown>;
  cancel(): void;
};

const CancellablePromise = (promise): TCancellablePromise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        // eslint-disable-next-line prefer-promise-reject-errors
        (response) => (isCanceled ? reject({ isCanceled }) : resolve(response)),
        // eslint-disable-next-line prefer-promise-reject-errors
        (error) => (isCanceled ? reject({ isCanceled }) : reject(error))
      )
      .catch((err) => {
        if (err) {
          if (err.message === 'Response cancelled') {
            isCanceled = true;
          }
        }
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};

export default CancellablePromise;
