import { useTranslation } from 'react-i18next';

import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import LabeledData from '@horse-auction/common/components/LabeledData/LabeledData';
import { device } from '@horse-auction/common/themes/device';
import { FaStripe } from 'react-icons/fa';
import styled from 'styled-components/macro';

export interface PaymentLabels {
  title: string;
  description: string;
  productName: string;
  totalPriceLabel: string;
  priceLabel: string;
}

interface CustomProps {
  labels: PaymentLabels;
  purchaseType: string | undefined;
  isLoading: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Description = styled(AppTypography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DetailsWrapper = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-rows: 2.25rem;
  margin-top: 1rem;

  @media ${device.mobile} {
    grid-template-rows: 1fr;
  }
`;

const PaymentContent = ({ labels, purchaseType, isLoading }: CustomProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Description variant='body1'>{labels.description}</Description>
      <DetailsWrapper>
        {purchaseType === 'ENTRANCE_DEPOSIT' && (
          <>
            <LabeledData
              label={t<string>('components:paymentContent.entranceDeposit.auction')}
              value={labels.productName}
            />
            <LabeledData
              label={t<string>('components:paymentContent.entranceDeposit.entranceDeposit')}
              value={labels.totalPriceLabel}
            />
          </>
        )}
        {purchaseType === 'LOT' && (
          <>
            <LabeledData
              label={t<string>('components:paymentContent.confirmationAmount.product')}
              value={labels.productName}
            />
            <LabeledData
              label={t<string>('components:paymentContent.confirmationAmount.paymentAmount')}
              value={labels.priceLabel}
            />
          </>
        )}
        <LabeledData label={t<string>('components:paymentContent.paymentMethod')}>
          <FaStripe size={48} />
        </LabeledData>
      </DetailsWrapper>
    </Container>
  );
};

export default PaymentContent;
