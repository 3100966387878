import { httpGet, httpPost, httpPut } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getUserAuction = (auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpGet('/user-auctions', payload));
};

const getAuctionCustomers = (auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpGet('/user-auctions/users', payload));
};

const acceptAuctionTerms = (auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpPost('/user-auctions/terms', payload));
};

const blockAuctionCustomer = (blockedUserId: string, auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpPut(`/user-auctions/block/${blockedUserId}`, payload));
};

const unblockAuctionCustomer = (blockedUserId: string, auctionId: string) => {
  const payload = { auctionId };

  return CancellablePromise(httpPut(`/user-auctions/unblock/${blockedUserId}`, payload));
};

const AuctionTermsAPI = {
  getUserAuction,
  getAuctionCustomers,
  acceptAuctionTerms,
  blockAuctionCustomer,
  unblockAuctionCustomer,
};

export default AuctionTermsAPI;
