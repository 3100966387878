import { useEffect, useState, useCallback, createContext, useContext } from 'react';

import { SocketContext } from '@horse-auction/common/context/SocketContext';
import PurchaseDto from '@horse-auction/common/types/purchase.dto';
import PaymentDialog from 'src/components/PaymentDialog/PaymentDialog';
import PurchasesWonDialog from 'src/components/PurchasesWonDialog/PurchasesWonDialog';
import useCustomerPurchases from 'src/hooks/useCustomerPurchases';

interface ContextProps {
  children: React.ReactNode;
}

type IPurchasesContext = {
  purchases: PurchaseDto[] | null;
  purchasesHttpState: any;
  getEntranceDepositPaid: (auctionId: string) => boolean;
  paymentObligations: PurchaseDto[] | null;
  deadlineObligations: PurchaseDto[] | null;
  openPaymentDialog: (purchase: PurchaseDto) => void;
  openPurchasesWonDialog: () => void;
  clearPurchases: () => void;
};

const initialPurchasesContext = {
  purchases: null,
  purchasesHttpState: null,
  getEntranceDepositPaid: () => false,
  paymentObligations: null,
  deadlineObligations: null,
  openPaymentDialog: () => undefined,
  openPurchasesWonDialog: () => undefined,
  clearPurchases: () => undefined,
};

const PurchasesContext = createContext<IPurchasesContext>(initialPurchasesContext);

const obligationStatuses = ['FULL_PAYMENT_REQUIRED', 'CONFIRMATION_REQUIRED', 'CONFIRMATION_PAID'];
// updates state for events such as auction start, lot start, lot ended

const PurchasesProvider = ({ children }: ContextProps) => {
  const { socket } = useContext(SocketContext);

  const [selectedPurchase, setSelectedPurchase] = useState<PurchaseDto | null>();
  const [paymentObligations, setPaymentObligations] = useState<PurchaseDto[]>([]);
  const [deadlineObligations, setDeadlineObligations] = useState<PurchaseDto[]>([]);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [purchasesWonDialogOpen, setPurchasesWonDialogOpen] = useState(false);

  const { purchasesHttpState, purchases, clearCustomerPurchases } = useCustomerPurchases();

  const clearPurchases = useCallback(() => {
    setSelectedPurchase(null);
    setPaymentObligations([]);
    setDeadlineObligations([]);
    setPaymentDialogOpen(false);
    setPurchasesWonDialogOpen(false);
    clearCustomerPurchases();
  }, [clearCustomerPurchases]);

  const updateObligations = useCallback((purchasesArray: PurchaseDto[] | null) => {
    if (purchasesArray && purchasesArray?.length > 0) {
      const paymentObligationsArray = purchasesArray.filter((purchase) =>
        obligationStatuses.some((status) => status === purchase.status)
      );
      const deadlinePurchasesArray = paymentObligationsArray
        .filter(
          (purchase) =>
            purchase.confirmationDeadline &&
            purchase.status === 'CONFIRMATION_REQUIRED' &&
            new Date(purchase.confirmationDeadline) > new Date()
        )
        .sort(
          (a: PurchaseDto, b: PurchaseDto) =>
            new Date(a.confirmationDeadline).getTime() - new Date(b.confirmationDeadline).getTime()
        );
      setPaymentObligations(paymentObligationsArray);
      return setDeadlineObligations(deadlinePurchasesArray);
    }
    setPaymentObligations([]);
    return setDeadlineObligations([]);
  }, []);

  useEffect(() => {
    updateObligations(purchases);
  }, [purchases, updateObligations]);

  useEffect(() => {
    socket?.on('logout', () => {
      clearPurchases();
    });
  }, [clearPurchases, socket]);

  const getEntranceDepositPaid = useCallback(
    (auctionId) => {
      const entranceDepositPaid =
        purchases === null ||
        purchases?.some(
          (purchase) =>
            purchase?.auction?._id === auctionId &&
            purchase?.type === 'ENTRANCE_DEPOSIT' &&
            purchase?.status === 'FINALIZED'
        );
      return entranceDepositPaid;
    },
    [purchases]
  );

  const closePaymentDialogHandler = useCallback(() => {
    setPaymentDialogOpen(false);
  }, []);

  const openPaymentDialog = useCallback((purchase) => {
    setSelectedPurchase(purchase);
    setPaymentDialogOpen(true);
  }, []);

  const openPurchasesWonDialog = useCallback(() => {
    setPurchasesWonDialogOpen(true);
  }, []);

  const closePurchasesWonDialog = useCallback(() => {
    setPurchasesWonDialogOpen(false);
  }, []);

  return (
    <>
      <PurchasesContext.Provider
        value={{
          purchases,
          purchasesHttpState,
          getEntranceDepositPaid,
          paymentObligations,
          deadlineObligations,
          openPaymentDialog,
          openPurchasesWonDialog,
          clearPurchases,
        }}
      >
        <PurchasesWonDialog
          open={purchasesWonDialogOpen}
          purchaseObligations={deadlineObligations}
          isLoading={purchasesHttpState.isLoading}
          onCancel={closePurchasesWonDialog}
        />
        <PaymentDialog
          open={paymentDialogOpen}
          purchaseType='LOT'
          stripeSecret={selectedPurchase?.stripePaymentIntentClientSecret}
          purchase={selectedPurchase}
          auction={selectedPurchase?.auction}
          onCancel={closePaymentDialogHandler}
        />
        {children}
      </PurchasesContext.Provider>
    </>
  );
};

export { PurchasesContext, PurchasesProvider };
