import { useTranslation } from 'react-i18next';

import AppButton from '@horse-auction/common/components/AppButton/AppButton';
import AppTypography from '@horse-auction/common/components/AppTypography/AppTypography';
import { device } from '@horse-auction/common/themes/device';
import styled from 'styled-components/macro';

interface Props {
  onBackToAuction: () => void;
  onCancel: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Description = styled(AppTypography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonRow = styled.div`
  display: grid;
  padding-top: 1rem;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`;

const PaymentSuccessContent = ({ onBackToAuction, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Description variant='body1'>
        {t<string>('payments:paymentOutcomeDialog.success.paymentSuccessDescription')}
      </Description>
      <ButtonRow>
        <AppButton fullWidth color='primary' variant='outlined' onClick={onCancel}>
          {t<string>('payments:paymentOutcomeDialog.success.ok')}
        </AppButton>
      </ButtonRow>
    </Container>
  );
};

export default PaymentSuccessContent;
