import { useLocation } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const useUrlQuery = (queryKey) => {
  const query = useQuery();
  const value = query.get(queryKey) || '';

  return value;
};

export default useUrlQuery;
