import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { AppTypography, AlertCollapse } from '@horse-auction/common/components';
import useCountries from '@horse-auction/common/hooks/useCountries';
import BidDto from '@horse-auction/common/types/bid.dto';
import LotDto from '@horse-auction/common/types/lot.dto';
import { Backdrop, CircularProgress, List, ListItem, ListItemProps } from '@mui/material';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdOutlineHourglassEmpty, MdCancel } from 'react-icons/md';
import styled from 'styled-components/macro';

import BidsListItemLarge from './BidsListItemLarge';

interface CustomProps {
  bids: BidDto[];
  bidsWithCanceled: BidDto[];
  lot: LotDto;
  isLoading: boolean;
  userId: string;
  highestBidStatus: string;
  auctionType: string;
  numberOfBids: number;
  numberOfBidders: number;
  size?: string;
}

interface ListItemCustomProps {
  size: string;
}

interface DotsListItemProps extends ListItemCustomProps, ListItemProps {}

interface StyledDivWithSizeProps extends PropsWithChildren {
  size: string;
}

const Container = styled.div<StyledDivWithSizeProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  .MuiAlert-root,
  .MuiAlert-icon {
    justify-content: center;
    align-items: center;
    font-size: ${({ size }) => (size === 'large' ? '2rem' : '1.25rem')};
  }
`;

const StyledBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 10;
    background-color: unset;
    display: flex;
    position: initial;
  }

  .MuiCircularProgress-root {
    margin-top: 2rem;
  }
`;

const StyledList = styled(List)``;

const DotsListItem = styled(ListItem)<DotsListItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${({ size }) => (size === 'large' ? '72px' : '56px')};
  height: 2rem;
`;

const BiddingStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: uppercase;
  margin: 0;
`;

const AwaitingBidsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

const NoBidsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;

  background-color: ${({ theme }) => {
    if (theme.name === 'dark') {
      return 'rgba(0, 0, 0, 0.4)';
    }
    return 'rgba(0, 0, 0, 0.1)';
  }};
`;

const BidsListLarge = ({
  bids = [],
  bidsWithCanceled = [],
  userId,
  numberOfBids = 0,
  numberOfBidders = 0,
  auctionType = 'PLACED',
  highestBidStatus = 'ONLINE',
  isLoading,
  lot,
  size = 'large',
}: CustomProps) => {
  const { t } = useTranslation(['bid']);
  const { codeToCountryLabel } = useCountries();

  return (
    <Container size={size}>
      {isLoading === true && (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color='primary' />
        </StyledBackdrop>
      )}
      {!isLoading && ['UPCOMING', 'IN_PROGRESS', 'ENDED'].includes(lot?.status) && (
        <>
          <AlertCollapse
            fontSize={size === 'large' ? '48px' : '36px'}
            severity='warning'
            rendered={highestBidStatus === 'GOING_ONCE'}
            message='Going once'
          />
          <AlertCollapse
            fontSize={size === 'large' ? '48px' : '36px'}
            severity='error'
            rendered={highestBidStatus === 'GOING_TWICE'}
            message='Going twice'
          />
          <AlertCollapse
            fontSize={size === 'large' ? '48px' : '36px'}
            severity={lot?.sold ? 'success' : 'info'}
            rendered={lot?.status === 'ENDED'}
            message={
              lot?.sold
                ? t('bid:biddingConsole.soldForBy', {
                    amount: lot?.finalPrice,
                    currency: lot?.auctionCurrency,
                    buyerLabel: lot?.buyerInhouse
                      ? 'In-house'
                      : codeToCountryLabel(lot?.buyerCountry),
                  })
                : t('bid:biddingConsole.biddingEndedNotSold')
            }
          />
          <BiddingStats>
            <AppTypography
              fontSize={size === 'large' ? '64px' : '48px'}
              variant='h5'
              color='#9a4c71'
              margin='0 0 1rem 0'
            >
              {`${t<string>('bid:biddingConsole.bidsNumber')}: ${numberOfBids}`}
            </AppTypography>
          </BiddingStats>
        </>
      )}
      {!isLoading && bidsWithCanceled?.length === 0 && (
        <AwaitingBidsWrapper>
          {lot?.status === 'SCHEDULED' && (
            <AwaitingBidsWrapper>
              <MdOutlineHourglassEmpty size='120' />
              <AppTypography variant='h5' margin='2rem 0' fontSize='36px'>
                {t<string>('bid:biddingConsole.awaitingLotStart')}
                ...
              </AppTypography>
            </AwaitingBidsWrapper>
          )}
          {['UPCOMING', 'IN_PROGRESS', 'ENDED'].includes(lot?.status) && (
            <NoBidsWrapper>
              <AppTypography variant='h5' margin='2rem 0' fontSize='36px'>
                {t<string>('bid:biddingConsole.noBidsRegistered')}
              </AppTypography>
            </NoBidsWrapper>
          )}
          {['CANCELED'].includes(lot?.status) && (
            <NoBidsWrapper>
              <MdCancel size='48px' />
              <AppTypography variant='h5' margin='2rem 0' fontSize='36px'>
                {t<string>('bid:biddingConsole.noBidsRegistered')}
              </AppTypography>
            </NoBidsWrapper>
          )}
        </AwaitingBidsWrapper>
      )}
      {!isLoading && bidsWithCanceled?.length > 0 && (
        <StyledList dense>
          {bidsWithCanceled?.slice(0, 6).map((bid, index) => (
            <BidsListItemLarge key={bid?._id} bid={bid} index={index} size={size} />
          ))}
          {bidsWithCanceled?.length > 6 && (
            <>
              <DotsListItem size={size}>
                <HiDotsHorizontal size={64} />
              </DotsListItem>
              <BidsListItemLarge
                bid={bidsWithCanceled?.[bidsWithCanceled?.length - 1]}
                index={bidsWithCanceled?.length - 1}
                size={size}
              />
            </>
          )}
        </StyledList>
      )}
    </Container>
  );
};

export default BidsListLarge;
