import { httpGet } from '@horse-auction/common/services/RequestService';
import CancellablePromise from '@horse-auction/common/utils/CancelablePromise';

const getPaymentIntent = (auctionId: string) => {
  const payload = {
    auctionId,
  };

  return CancellablePromise(httpGet('/payments/entrance', payload));
};

const PaymentAPI = { getPaymentIntent };

export default PaymentAPI;
