import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components/macro';

interface CustomProps {
  component?: React.ElementType;
}

interface Props extends CustomProps, TypographyProps {}

const StyledTypography = styled(Typography)`
  &.MuiTypography-h4 {
    font-size: 36px;
    font-family: Playfair;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-transform: none;
  }

  &.MuiTypography-h5 {
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &.MuiTypography-h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &.MuiTypography-subtitle2 {
  }

  &.MuiTypography-overline {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: upperase;
  }

  &.MuiTypography-root {
    ${({ margin }) => margin && `margin: ${margin}`};
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
    ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  }
`;

const AppTypography: React.FC<Props> = ({
  children,
  fontSize,
  margin,
  lineHeight,
  textAlign,
  ...rest
}: Props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <StyledTypography
    margin={margin}
    fontSize={fontSize}
    lineHeight={lineHeight}
    textAlign={textAlign}
    {...rest}
  >
    {children}
  </StyledTypography>
);

export default AppTypography;
