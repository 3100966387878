import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import styled from 'styled-components/macro';

interface Props {
  blockadeType: string;
}

const StyledAlert = styled(Alert)`
  margin: 1rem 0;

  &.MuiAlert-root {
    align-items: center;

    .MuiAlert-message {
      width: 100%;
    }
  }
`;

const AcceptTermsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiButton-root {
    max-width: 200px;
    min-width: 80px;
  }
`;

const TermsLabel = styled.div`
  margin-right: 1rem;
`;

const UserBlockedAlert = ({ blockadeType = 'AUCTION' }: Props) => {
  const { t } = useTranslation(['bid']);

  return (
    <StyledAlert severity='error'>
      <AcceptTermsWrapper>
        <TermsLabel>
          <>
            {blockadeType === 'AUCTION' && t('auction:auctionAlerts.youAreAuctionBlocked')}
            {blockadeType === 'ORGANIZATION' &&
              t('auction:auctionAlerts.youAreOrganizationBlocked')}
          </>
        </TermsLabel>
      </AcceptTermsWrapper>
    </StyledAlert>
  );
};

export default UserBlockedAlert;
