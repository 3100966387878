import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AppBackdrop,
  AppTypography,
  StyledLink,
  AuctionCard,
  ResponsivePage,
} from '@horse-auction/common/components';
import useAuctions from '@horse-auction/common/hooks/useAuctions';
import { variants } from '@horse-auction/common/themes/customTheme';
import { device } from '@horse-auction/common/themes/device';
import AuctionDto from '@horse-auction/common/types/auction.dto';
import { Breadcrumbs } from '@mui/material';
import AuctionAPI from 'src/api/AuctionAPI';
import styled from 'styled-components/macro';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AuctionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  width: 100%;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div<PropsWithChildren>``;

const TypographyStyled = styled(AppTypography)`
  text-align: center;
  padding-top: 1rem;
  color: ${variants.primary.main};
`;

const AuctionsPage = () => {
  const { auctions, auctionsHttpState } = useAuctions(AuctionAPI);
  const { t } = useTranslation(['auctions']);

  return (
    <ResponsivePage>
      <AppBackdrop isLoading={auctionsHttpState?.isLoading}>
        {' '}
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <StyledLink to='/'>Home</StyledLink>
          <StyledLink active>{t<string>('auctions:title')}</StyledLink>
        </Breadcrumbs>
        <HeaderWrapper>
          <TypographyStyled variant='h5' gutterBottom>
            {t<string>('auctions:title')}
          </TypographyStyled>
        </HeaderWrapper>
        <AuctionsGrid>
          {auctions?.map((auction: AuctionDto) => (
            <CardWrapper key={auction._id}>
              <AuctionCard auction={auction} isLoading={auctionsHttpState?.isLoading} />
            </CardWrapper>
          ))}
        </AuctionsGrid>
      </AppBackdrop>
    </ResponsivePage>
  );
};

export default AuctionsPage;
