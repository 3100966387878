import { AppBreadcrumbs, AppTypography, ResponsivePage } from '@horse-auction/common/components';
import { Breadcrumb } from '@horse-auction/common/types/Breadcrumb';
import styled from 'styled-components/macro';

interface Props {
  children: React.ReactNode;
  breadcrumbs: Breadcrumb[];
  title: string;
}

const defaultBreadcrumbs: Breadcrumb[] = [
  {
    key: 'home',
    label: 'Home',
    link: '/',
  },
];

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-grow: 1;
`;

const TypographyStyled = styled(AppTypography)`
  text-align: center;
  padding-top: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageContainer = ({ children, breadcrumbs = defaultBreadcrumbs, title = 'Page' }: Props) => (
  <Container>
    <ResponsivePage>
      <AppBreadcrumbs breadcrumbs={breadcrumbs} />

      <HeaderWrapper>
        <TypographyStyled variant='h5' gutterBottom>
          {title}
        </TypographyStyled>
      </HeaderWrapper>
      {children}
    </ResponsivePage>
  </Container>
);

export default PageContainer;
