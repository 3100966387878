import { useState, useEffect, useCallback, useRef } from 'react';

import { secondsToDayTimeObject } from '@horse-auction/common/utils/time';

const useTimer = (deadlineDate: Date, interval: number) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [ended, setEnded] = useState(true);

  const prevDeadlineDateRef: { current: Date | null } = useRef(null);
  const timerIntervalRef: { current: number | null } = useRef(null);

  const deadlineDateToHmsObject = useCallback(() => {
    const futureDate = new Date(deadlineDate);
    const now = new Date();
    const secondsTillDeadline = (futureDate.getTime() - now.getTime()) / 1000;
    const timerObj = secondsToDayTimeObject(secondsTillDeadline);

    return timerObj;
  }, [deadlineDate]);

  const startTimerInterval = useCallback(
    () =>
      window.setInterval(() => {
        if (new Date(deadlineDate) <= new Date()) {
          setHours(0);
          setMinutes(0);
          setSeconds(0);
          setDays(0);
          setEnded(true);
          if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
          }
        }
        if (new Date(deadlineDate) > new Date()) {
          if (seconds > 0) {
            setSeconds((sec) => sec - 1);
            setEnded(false);
          }
          if (seconds === 0 && minutes > 0) {
            setMinutes((min) => min - 1);
            setSeconds(59);
            setEnded(false);
          }
          if (seconds === 0 && minutes === 0 && hours > 0) {
            setHours((h) => h - 1);
            setMinutes(59);
            setSeconds(59);
            setEnded(false);
          }
          if (seconds === 0 && minutes === 0 && hours === 0 && days > 0) {
            setDays((d) => d - 1);
            setHours(23);
            setMinutes(59);
            setSeconds(59);
            setEnded(false);
          }
        }
      }, interval),
    [hours, minutes, seconds, days, deadlineDate, interval]
  );

  useEffect(() => {
    if (deadlineDate && new Date(deadlineDate) > new Date()) {
      const {
        seconds: initialSeconds,
        minutes: initialMinutes,
        hours: initialHours,
        days: initialDays,
      } = deadlineDateToHmsObject();
      setHours(initialHours);
      setMinutes(initialMinutes);
      setSeconds(initialSeconds);
      setDays(initialDays);
      setEnded(false);
    }
  }, [deadlineDate, deadlineDateToHmsObject, startTimerInterval]);

  useEffect(() => {
    if (
      (hours || minutes || seconds || days) &&
      (!timerIntervalRef.current || prevDeadlineDateRef.current !== deadlineDate)
    ) {
      prevDeadlineDateRef.current = deadlineDate;
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      timerIntervalRef.current = startTimerInterval();
    }
  }, [days, hours, minutes, seconds, startTimerInterval, deadlineDate]);

  useEffect(
    () => () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    },
    []
  );

  return { days, hours, minutes, seconds, ended };
};

export default useTimer;
